import React, { createContext, useState, useContext } from "react";

const ManagePlanContext = createContext();

export function useManagePlan() {
  return useContext(ManagePlanContext);
}

export const ManagePlanProvider = ({ children }) => {
  const [managePlan, setManagePlan] = useState(false);
  const [changeDeliveryTime, setChangeDeliveryTime] = useState(false);
  const [changeExpectedOrders, setChangeExpectedOrders] = useState(false);
  const [changeSubsidy, setChangeSubsidy] = useState(false);
  const [removeUser, setRemoveUser] = useState(false);
  const [assignTeam, setAssignTeam] = useState(false);
  const [addUsersToMeal, setAddUsersToMeal] = useState(false);
  const [changeContact, setChangeContact] = useState(false);
  const [changeDeliveryInstructions, setChangeDeliveryInstructions] =
    useState(false);
  const [confirmTeamRemove, setConfirmTeamRemove] = useState(false);
  const [changeMealPlanName, setChangeMealPlanName] = useState(false);
  const [changeEnabledDays, setChangeEnabledDays] = useState(false);
  const [changeAddress, setChangeAddress] = useState(false);
  const [changeMealDate, setChangeMealDate] = useState(false);

  return (
    <ManagePlanContext.Provider
      value={{
        managePlan,
        setManagePlan,
        changeDeliveryTime,
        setChangeDeliveryTime,
        changeExpectedOrders,
        setChangeExpectedOrders,
        changeSubsidy,
        setChangeSubsidy,
        removeUser,
        setRemoveUser,
        assignTeam,
        setAssignTeam,
        addUsersToMeal,
        setAddUsersToMeal,
        changeContact,
        setChangeContact,
        changeDeliveryInstructions,
        setChangeDeliveryInstructions,
        confirmTeamRemove,
        setConfirmTeamRemove,
        changeMealPlanName,
        setChangeMealPlanName,
        changeEnabledDays,
        setChangeEnabledDays,
        changeAddress,
        setChangeAddress,
        changeMealDate,
        setChangeMealDate,
      }}
    >
      {children}
    </ManagePlanContext.Provider>
  );
};
