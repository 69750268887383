import { Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useState,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { InfoButtonGray } from "assets/img";
import { MobXProviderContext } from "mobx-react";
import RemoveCard from "./removeCard";
import { toJS } from "mobx";

function EditBillingInfo({
  visibleModel,
  setBillingModal,
  billingAccount,
  setShowEditNotification,
}) {
  const initialState = {
    billingAddress: "",
    billingName: "",
    poNumber: "",
    emailIndex: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const [removeCard, setRemoveCard] = useState(false);
  const [inputList, setInputList] = useState([]);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const { locationID } = userStore;
  const { emailIndex } = provider;

  useEffect(() => {
    dispatch({
      field: "billingAddress",
      value: billingAccount?.account_billing_address,
    });
    dispatch({
      field: "billingName",
      value: billingAccount?.account_billing_name,
    });
    dispatch({
      field: "poNumber",
      value: billingAccount?.invoice_billing_reference,
    });

    setInputList(billingAccount?.account_billing_emails);
  }, [billingAccount]);

  // handle click event of the Remove button
  const handleRemoveClick = () => {
    setBillingModal(true);
    const list = [...inputList];
    list.splice(emailIndex, 1);
    setInputList(list);
    setRemoveCard(false);
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    e.preventDefault();
    setInputList([...inputList, ""]);
  };

  const handleInputChange = (option, index) => {
    const list = [...inputList];
    list[index] = option;
    setInputList(list);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      location_billing_account: {
        account_billing_name: provider.billingName,
        account_billing_address: provider.billingAddress,
        account_billing_emails: inputList?.map((email) => email),
        invoice_billing_reference: provider.poNumber,
      },
    };

    store?.paymentInfoStore
      .updateBillingAccount(locationID, payload)
      .then(() => {
        dispatch({ type: "reset", payload: initialState });
        setInputList([]);
        setShowEditNotification(true);
        setBillingModal(false);
      });
  };

  return (
    <>
      <Transition appear show={visibleModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[90]"
          onClose={() => {
            setBillingModal(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="lg:w-1/2 md:w-4/5 w-4/5 d-col items-center justify-center rounded-lg transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                  <div className="w-full inline-flex flex-col items-start gap-[24px] py-[40px] relative bg-white rounded-[24px]">
                    <div className="flex flex-col w-full gap-[72px] relative flex-[0_0_auto]">
                      <div className="flex inline-flex items-center justify-between relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-inter-semibold text-heading text-[32px] tracking-[0] text-[32px] whitespace-nowrap">
                          Edit Billing Info
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            setBillingModal(false);
                          }}
                          className="btn-style"
                        >
                          <FontAwesomeIcon
                            style={{ width: 16, height: 26 }}
                            icon={faXmark}
                            size="2x"
                            color="#BBC0C5"
                          />
                        </button>
                      </div>
                    </div>

                    <form onSubmit={handleSubmit} className="w-full">
                      <div className="flex flex-col items-start gap-[24px] relative self-stretch w-full flex-[0_0_auto]">
                        <div className="flex flex-col items-start gap-[4px] relative self-stretch w-full flex-[0_0_auto]">
                          <div className="relative w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                            Company billing address*
                          </div>
                          <div className="flex h-[48px] items-center gap-[10px] px-[19px] py-[9px] relative self-stretch w-full bg-background rounded-[12px] border border-solid border-background">
                            <input
                              className="relative mt-[-1.00px] font-inter-medium bg-background focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                              onChange={(e) =>
                                dispatch({
                                  field: "billingAddress",
                                  value: e.target.value,
                                })
                              }
                              required
                              value={provider.billingAddress}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col items-start gap-[4px] relative self-stretch w-full flex-[0_0_auto]">
                          <div className="relative w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                            Company billing name*
                          </div>
                          <div className="flex h-[48px] items-center gap-[10px] px-[19px] py-[9px] relative self-stretch w-full bg-background rounded-[12px] border border-solid border-background">
                            <input
                              className="relative mt-[-1.00px] font-inter-medium bg-background focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                              onChange={(e) =>
                                dispatch({
                                  field: "billingName",
                                  value: e.target.value,
                                })
                              }
                              required
                              value={provider.billingName}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col items-start gap-[4px] relative self-stretch w-full flex-[0_0_auto]">
                          <div className="relative w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                            PO number
                          </div>
                          <div className="flex h-[48px] items-center gap-[10px] px-[19px] py-[9px] relative self-stretch w-full bg-background rounded-[12px] border border-solid border-background">
                            <div className="inline-flex items-center gap-[367px] relative flex-[0_0_auto]">
                              <input
                                className="relative mt-[-1.00px] font-inter-medium bg-background focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                                onChange={(e) =>
                                  dispatch({
                                    field: "poNumber",
                                    value: e.target.value,
                                  })
                                }
                                value={provider.poNumber}
                                required
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-fit mt-[-1.00px] font-text-subtitle font-[number:var(--text-subtitle-font-weight)] text-plain text-[length:var(--text-subtitle-font-size)] tracking-[var(--text-subtitle-letter-spacing)] leading-[var(--text-subtitle-line-height)] whitespace-nowrap [font-style:var(--text-subtitle-font-style)]">
                          Accounting billing email
                        </div>

                        {inputList?.map((user, i) => {
                          return (
                            <div className="w-full d-col items-right justify-right content-right">
                              <div className="d-row animate-fade-in-up w-full">
                                <div className="flex h-[48px] items-center px-[19px] relative self-stretch w-full bg-background rounded-[12px] border border-solid border-background">
                                  <input
                                    className="relative font-inter-medium bg-background focus:outline-none text-black text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                                    onChange={(e) =>
                                      handleInputChange(e.target?.value, i)
                                    }
                                    type="email"
                                    required
                                    value={user}
                                  />

                                  <button
                                    type="button"
                                    className="ml-2 p-1 rounded-md"
                                    onClick={() => {
                                      dispatch({
                                        field: "emailIndex",
                                        value: i,
                                      });
                                      setRemoveCard(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faXmark}
                                      color={"#BBC0C5"}
                                      size="md"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {inputList?.length < 5 && (
                          <button
                            type="button"
                            onClick={handleAddClick}
                            className="btn-outline lg:w-1/3 md:w-1/3 w-2/3 self-start mt-[12px] text-dark-gray border-none bg-light-gray h-[48px]"
                          >
                            + add additional email
                          </button>
                        )}
                      </div>

                      <div className="flex items-start gap-[10px] px-[10px] py-[13px] relative self-stretch w-full flex-[0_0_auto] rounded-[12px]">
                        <img
                          layout="fixed"
                          className="object-contain mt-1"
                          width={16}
                          height={16}
                          alt="info-button-checkou"
                          src={InfoButtonGray}
                        />
                        <p className="text-secondary relative flex-1 mt-[-1.00px] font-text-subtitle-medium font-[number:var(--text-subtitle-medium-font-weight)] text-plain text-[length:var(--text-subtitle-medium-font-size)] tracking-[var(--text-subtitle-medium-letter-spacing)] leading-[var(--text-subtitle-medium-line-height)] [font-style:var(--text-subtitle-medium-font-style)]">
                          A copy of the invoice will be sent to emails above
                        </p>
                      </div>

                      <div className="flex items-center justify-center gap-[19px] relative self-stretch w-full flex-[0_0_auto] lg:mt-4 md:mt-16">
                        <button
                          className="btn-light btn-style py-3 px-5 lg:w-[98px] md:w-[197.50px]"
                          onClick={() => {
                            setBillingModal(false);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="submit"
                          className="btn-purple btn-style py-3 px-6 lg:w-[98px] md:w-[197.50px]"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <RemoveCard
        visible={removeCard}
        setModal={() => setRemoveCard(false)}
        title="Remove email"
        message="Are you sure you want to delete this email?"
        leftButtonText="Cancel"
        rightButtonText="Delete"
        leftClick={() => setRemoveCard(false)}
        index={emailIndex}
        rightClick={() => handleRemoveClick(emailIndex)}
      />
    </>
  );
}

export default EditBillingInfo;
