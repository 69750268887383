import React, { useState, useEffect, useContext } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import classNames from "classnames";
import { MobXProviderContext } from "mobx-react";
import { ChevronLeftIcon } from "assets/img";
import { observer } from "mobx-react";

const Index = () => {
  const store = useContext(MobXProviderContext);
  const userStore = store.userStore;
  const onboardingStore = store.onboardingStore;

  let { defaultLocation } = userStore;
  let { newAddress, isNewAddress } = onboardingStore;

  const [currentStep, setCurrentStep] = useState(1);
  const [disableButton, setDisableButton] = useState(true);
  const [showSkipButton, setShowSkipButton] = useState(false);

  const nextStep = () => {
    if (currentStep == 5) {
      handleCreateMealData()
        .then(() => {
          setCurrentStep((prevStep) => prevStep + 1);
        })
        .catch(() => {
          store.userStore.setErrorAlert(true, {
            title: "Something went wrong",
          });
        });
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
    setDisableButton(true);
  };

  const enableButton = (value) => {
    setDisableButton(value);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 disableButton={enableButton} />;
      case 2:
        return <Step2 disableButton={enableButton} />;
      case 3:
        return <Step3 disableButton={enableButton} />;
      case 4:
        return <Step4 disableButton={enableButton} />;
      case 5:
        return <Step5 disableButton={enableButton} />;
      case 6:
        return <Step6 />;
      default:
        return null;
    }
  };

  useEffect(() => {
    store.onboardingStore.fetchCuisineTypesData({
      location_id: defaultLocation?.organizationId,
    });
  }, []);

  useEffect(() => {
    setShowSkipButton(currentStep === 4);
  }, [currentStep]);

  const handleBackClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleCreateMealData = () => {
    return new Promise((resolve, reject) => {
      if (isNewAddress) {
        const payload = {
          address: {
            suite: newAddress?.suite,
            street_number: newAddress?.street_number,
            street_name: newAddress?.street_name,
            city: newAddress?.city,
            province: newAddress?.province,
            postal_code: newAddress?.postal_code,
            nickname: newAddress?.nickname,
            delivery_instructions: newAddress?.delivery_instructions,
            delivery_verification: "photo",
          },
          copy_point_of_contact: true,
        };

        store.deliveryInfoStore
          .createAddress(
            { location_id: defaultLocation?.organizationId },
            payload
          )
          .then((response) => {
            if (response?.data?.id) {
              const addressId = response?.data.id;
              store.deliveryInfoStore
                .getLocationAddresses({
                  location_id: defaultLocation?.organizationId,
                })
                .then(() => {
                  store.onboardingStore
                    .createMealPlan(
                      { location_id: defaultLocation?.organizationId },
                      addressId
                    )
                    .then(() => {
                      resolve();
                    })
                    .catch(() => {
                      reject();
                    });
                });
            }
          })
          .catch(() => {
            reject();
          });
      } else {
        store.onboardingStore
          .createMealPlan({ location_id: defaultLocation?.organizationId })
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject();
          });
      }
    });
  };

  return (
    <div className="flex items-center justify-center w-full">
      {currentStep !== 7 && (
        <>
          <div className="relative flex flex-col">
            <div
              className={classNames("h-11 flex", {
                "mt-24": currentStep !== 6,
              })}
            >
              {currentStep !== 1 && currentStep !== 6 && (
                <button
                  className="h-11 flex items-center gap-2 inline-flex"
                  onClick={handleBackClick}
                >
                  <img
                    className="w-5 h-5"
                    src={ChevronLeftIcon}
                    alt="BackButton"
                  />
                  <span className="text-indigo-900 font-inter-normal text-base leading-tight">
                    Back
                  </span>
                </button>
              )}
            </div>

            {renderStep()}

            {currentStep !== 6 && (
              <div className="mt-8">
                <hr className="screen-lg:hidden screen-md:hidden mb-4 h-[0px] border border-zinc-200" />

                <div className="px-2.5 d-row mt-5">
                  <button
                    disabled={disableButton}
                    onClick={nextStep}
                    className={classNames(
                      "screen-lg:w-[232px] screen-md:w-[232px] w-full screen-lg:h-16 screen-md:h-16 h-[46px] rounded-md text-white text-sm screen-lg:left-11 screen-lg:bottom-12 screen-md:left-11 screen-md:bottom-12 font-inter-medium",
                      {
                        "bg-gray-200": disableButton,
                        "bg-indigo-900": !disableButton,
                      }
                    )}
                  >
                    Continue
                  </button>

                  {currentStep === 4 && showSkipButton && (
                    <button
                      className="w-full screen-lg:w-6/12 text-indigo-900 font-inter-semibold screen-lg:h-16 screen-md:h-16 h-[46px]"
                      onClick={nextStep}
                    >
                      Skip for now
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Index);
