import React, { useContext, useReducer, useEffect, useState } from "react";
import { SearchBar } from "components";
import ConfirmationModal from "components/ConfirmationModal";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import {
  ChangeSubsidy,
  AssignTeam,
  AddUsersToMeal,
  TeamRemove,
  BulkUserRemove,
} from "alerts";
import { useManagePlan } from "contexts/ManagePlanContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { MobXProviderContext } from "mobx-react";
import { useParams } from "react-router-dom";
import { toJS } from "mobx";
import { priceFormatter } from "utils/helpers/validation";
import { formattedAddres } from "utils/helpers/string";
import { NotFound } from "components";
import { isPresent } from "utils/helpers/array";

function ManageUsers() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.state?.referrer || -1;
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const homeStore = toJS(store?.homeStore);
  const { locationID, defaultLocation } = userStore;
  const { mealId } = useParams();
  const { teams, locationEmployees } = homeStore;
  const [bulkUsers, setBulkUsers] = useState([]);
  const [subsidySelect, setSubsidySelect] = useState(null);

  const {
    changeSubsidy,
    setChangeSubsidy,
    removeUser,
    setRemoveUser,
    assignTeam,
    setAssignTeam,
    addUsersToMeal,
    setAddUsersToMeal,
    confirmTeamRemove,
    setConfirmTeamRemove,
  } = useManagePlan();

  const initialState = {
    meal: null,
    usersWithOrderInfo: [],
    userTeamFilter: null,
    userOrderSearchText: "",
    totalUsersUnderMeal: 0,
    usersUnderMeal: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["groupOrder", locationID, mealId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.homeStore.fetchMealDetail(mealId, locationID);
    },
  });

  let { meal, totalUsersUnderMeal, usersUnderMeal } = provider;

  useEffect(() => {
    dispatch({ field: "meal", value: data });

    dispatch({
      field: "totalUsersUnderMeal",
      value: data?.attributes?.location_employees?.length,
    });

    dispatch({
      field: "usersUnderMeal",
      value: data?.attributes?.employee_meals,
    });

    store?.homeStore?.fetchTeams(locationID);
    store?.homeStore?.fetchEmployees(locationID);
  }, [data]);

  useEffect(() => {
    refetch();
  }, [
    assignTeam,
    confirmTeamRemove,
    removeUser,
    addUsersToMeal,
    changeSubsidy,
  ]);

  useEffect(() => {
    setBulkUsers([]);

    if (subsidySelect?.value === "default") {
      let locEmployeesCopy = meal?.attributes?.employee_meals;

      locEmployeesCopy = locEmployeesCopy?.filter(
        (user) => user?.subsidy_type == null
      );

      dispatch({
        field: "usersUnderMeal",
        value: locEmployeesCopy,
      });
    } else if (subsidySelect?.value === "custom") {
      let locEmployeesCopy = meal?.attributes?.employee_meals;
      locEmployeesCopy = locEmployeesCopy?.filter(
        (user) => user?.subsidy_type != null
      );
      dispatch({
        field: "usersUnderMeal",
        value: locEmployeesCopy,
      });
    } else {
      dispatch({
        field: "usersUnderMeal",
        value: meal?.attributes?.employee_meals,
      });
    }
  }, [subsidySelect, meal]);

  const onEmployeeChecked = (e, person) => {
    if (e.target.checked) {
      let locationEmployees = meal?.attributes?.location_employees;

      let found = locationEmployees?.find(
        (employee) => person.location_employee_id == employee?.id
      );

      let isPresent = bulkUsers?.find((user) => {
        return user?.id == person?.location_employee_id;
      });

      if (isPresent) {
        let filtered = bulkUsers?.filter((user) => {
          return user?.id != person?.location_employee_id;
        });

        setBulkUsers(filtered);
      } else {
        setBulkUsers([
          ...bulkUsers,
          { id: found?.id, employee_id: person?.id },
        ]);
      }
    } else {
      let filtered = bulkUsers?.filter((user) => {
        return user?.id != person?.location_employee_id;
      });

      setBulkUsers(filtered);
    }
  };

  return (
    <div className="d-col p-6 w-full relative">
      <button
        className="flex items-center cursor-pointer mb-[24px]"
        onClick={() => {
          navigate(-1);
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ width: 14, height: 14 }} />
        <span className="text-md text-left font-inter-medium text-black-light ml-2">
          Back
        </span>
      </button>

      <span className="font-inter-medium text-dark-gray text-[14px] mt-[18px] self-center">
        {meal?.attributes?.meal_date} Delivery Address:{" "}
        {formattedAddres(meal?.attributes?.address?.attributes)}
      </span>

      <h2 className="font-inter-semibold text-dark-gray text-[24px] mt-[5px] mb-[45px] self-center">
        Changes to {meal?.attributes?.name}
      </h2>

      <div>
        <div className="d-row mt-[24px] items-center justify-between">
          <strong className="text-dark-gray text-[20px] font-inter-semibold">
            Teams in {meal?.attributes?.name}
          </strong>
          <button
            className="btn-blue-accent"
            onClick={() => {
              setAssignTeam(true);
            }}
          >
            Assign teams
          </button>
        </div>

        <p className="font-inter-regular max-w-[543px] text-[14px] text-dark-gray">
          Users added or removed from the following teams will be automatically
          added or removed from this meal
        </p>

        <div className="flex items-center space-x-2 mt-[24px]">
          {meal?.attributes?.teams?.map((team) => (
            <div className="bg-light-gray px-4 py-[12px] rounded-full flex items-center">
              <span className="text-sm text-gray-700">{team?.name}</span>
              <button
                className="ml-2 text-gray-700 text-xs"
                onClick={() => {
                  setConfirmTeamRemove(team?.id);
                }}
              >
                ✕
              </button>
            </div>
          ))}
        </div>
        <NotFound data={meal?.attributes?.teams} message="No teams found" />
      </div>

      <strong className="text-dark-gray text-[20px] font-inter-semibold mt-[24px]">
        Users in this meal
      </strong>

      <span className="font-inter-regular max-w-[543px] text-[14px] text-dark-gray">
        Users number: {totalUsersUnderMeal}
      </span>

      <div className="d-row items-center justify-between mt-[24px]">
        <div className="d-row items-center">
          <SearchBar placeholder="Search" />
          <small className="fonte-inter-regular tex-sm mx-[10px]">
            Filter by:
          </small>

          <Select
            styles={customStylesSubsidy}
            aria-labelledby="sort-by"
            aria-label="sort-by"
            placeholder="Subsidy type"
            closeMenuOnSelect
            isClearable
            onChange={(option) => setSubsidySelect(option)}
            options={[
              { label: "Custom", value: "custom" },
              { label: "Default", value: "default" },
            ]}
            className="w-[195px]"
          />
        </div>

        <button
          className="btn-blue-accent"
          onClick={() => {
            setAddUsersToMeal(true);
          }}
        >
          Add users to this meal
        </button>
      </div>

      <div className="mt-[13px] flow-root">
        <div class="flex h-96 border-separate flex-col overflow-clip border-solid">
          <table class="w-full table-fixed">
            <thead class="sticky top-0 bg-light-gray">
              <tr>
                <th class="p-2"></th>
                <th class="p-2 font-inter-medium text-[14px] text-left">
                  First name
                </th>
                <th class="p-2 font-inter-medium text-[14px] text-left">
                  Last name
                </th>
                <th class="p-2 font-inter-medium text-[14px] text-left">
                  Email
                </th>
                <th class="p-2 font-inter-medium text-[14px] text-left">
                  Team
                </th>
                <th class="p-2 font-inter-medium text-[14px] text-left">
                  Subsidy
                </th>
                <th class="p-2 font-inter-medium text-[14px] text-left">
                  Subsidy Value
                </th>
              </tr>
            </thead>
          </table>

          <div class="flex-1 overflow-y-auto">
            <table class="w-full table-fixed">
              <tbody>
                {usersUnderMeal?.map((person) => (
                  <tr
                    key={person.email}
                    className="even:bg-light-gray space-x-2"
                  >
                    <td className="py-3.5 px-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          key={meal}
                          className="form-checkbox text-blue-600 h-4 w-4"
                          onChange={(e) => {
                            onEmployeeChecked(e, person);
                          }}
                        />
                      </label>
                    </td>

                    <td className="py-3.5 px-2 text-left text-sm font-inter-regular text-dark-gray sm:pl-3">
                      {person?.first_name}
                    </td>

                    <td className="py-3.5 px-2 text-left text-sm font-inter-regular text-dark-gray sm:pl-3">
                      {person?.last_name}
                    </td>

                    <td className="whitespace-normal break-words py-3.5 px-2 text-left text-sm font-inter-regular text-dark-gray sm:pl-3">
                      {person?.email}
                    </td>

                    <td className="py-3.5 px-2 text-left text-sm font-inter-regular text-dark-gray sm:pl-3">
                      {person?.teams?.map((team, ind, arr) => {
                        return (
                          team?.name + (ind == arr?.length - 1 ? "" : ", ")
                        );
                      })}
                    </td>

                    <td className="py-3.5 px-2 text-left text-sm font-inter-regular text-dark-gray sm:pl-3">
                      {person?.subsidy_type == null ? "Default" : "Custom"}
                    </td>

                    <td className="whitespace-normal break-words py-3.5 px-2">
                      {person?.subsidy_type == null ? (
                        <>
                          {meal?.attributes?.subsidy_type == "weekly" ? (
                            <small className="font-inter-medium text-[14px] text-dark-gray">
                              {priceFormatter(meal?.attributes?.subsidy)} weekly
                            </small>
                          ) : (
                            <div className="grid grid-cols-3">
                              {meal?.attributes?.daily_subsidy &&
                                Object.keys(
                                  meal?.attributes?.daily_subsidy
                                )?.map((day) => (
                                  <div className="d-col items-center">
                                    <small className="mr-2 font-inter-medium text-dark-gray capitalize text-[7px]">
                                      {day?.substring(0, 3)}
                                    </small>
                                    <div className="bg-white items-center justify-center capitalize px-[7px] py-[3px] d-row rounded">
                                      <small className="font-inter-medium text-dark-gray text-[7px]">
                                        {priceFormatter(
                                          meal?.attributes?.daily_subsidy[day]
                                        )}
                                      </small>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {person?.subsidy_type == "weekly" ? (
                            <small className="font-inter-medium text-[14px] text-dark-gray">
                              {priceFormatter(person?.subsidy)} weekly
                            </small>
                          ) : (
                            <div className="grid grid-cols-3">
                              {person?.daily_subsidy &&
                                Object.keys(person?.daily_subsidy)?.map(
                                  (day) => (
                                    <div className="d-col items-center">
                                      <small className="mr-2 font-inter-medium text-dark-gray capitalize text-[7px]">
                                        {day?.substring(0, 3)}
                                      </small>
                                      <div className="bg-white items-center justify-center capitalize px-[7px] py-[3px] d-row rounded">
                                        <small className="font-inter-medium text-dark-gray text-[7px]">
                                          {priceFormatter(
                                            person?.daily_subsidy[day]
                                          )}
                                        </small>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <NotFound data={usersUnderMeal} message="No users found" />
          </div>
        </div>
      </div>

      {isPresent(bulkUsers) && (
        <div class="absolute bottom-0 right-0 d-row items-center justify-center left-0 ">
          <div className="btn-blue-accent rounded-3xl px-[32px] py-[16px] d-row items-center hover:bg-catering h-[48px] ">
            <small className="font-inter-medium text-[16px]">
              {bulkUsers?.length} user selected
            </small>
            <button
              className="font-inter-medium text-white px-[12px] underline text-[16px] py-0"
              onClick={() => setChangeSubsidy(true)}
            >
              Update subsidy
            </button>
            <button
              className="font-inter-medium text-white underline text-[16px] py-0"
              onClick={() => setRemoveUser(true)}
            >
              Remove user from this meal
            </button>
          </div>
        </div>
      )}

      {changeSubsidy && (
        <ChangeSubsidy
          visible={changeSubsidy}
          setModal={setChangeSubsidy}
          meal={meal}
          bulkUsers={bulkUsers}
          bulkUserUpdate={true}
          updatingUserSubsidiesForMeal={true}
          onComplete={() => {
            setBulkUsers([]);
          }}
        />
      )}

      {assignTeam && (
        <AssignTeam
          fromMeal={true}
          meal={meal}
          teams={teams?.filter(
            (team) =>
              !meal?.attributes?.teams?.some((item) => item?.id == team?.id)
          )}
          visible={assignTeam}
          setModal={setAssignTeam}
        />
      )}

      {addUsersToMeal && (
        <AddUsersToMeal
          visible={addUsersToMeal}
          setModal={setAddUsersToMeal}
          users={locationEmployees?.filter(
            (user) =>
              !meal?.attributes?.location_employees?.some(
                (item) => item?.id == user?.id
              )
          )}
          meal={meal}
          fromMeal={true}
        />
      )}

      {confirmTeamRemove && (
        <TeamRemove
          visible={confirmTeamRemove}
          setModal={setConfirmTeamRemove}
          fromMeal={true}
          meal={meal}
        />
      )}

      {removeUser && (
        <BulkUserRemove
          visible={removeUser}
          setModal={setRemoveUser}
          fromMeal={true}
          meal={meal}
          bulkUsers={bulkUsers}
          onComplete={() => {
            setBulkUsers([]);
          }}
        />
      )}
    </div>
  );
}

export default ManageUsers;
