import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useReducer, useEffect } from "react";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { MobXProviderContext } from "mobx-react";
import { errorHandler } from "utils/middlewares/errorHandler";
import MultiSelect from "components/MultiSelect";

function AddUserToTeam({
  visible,
  setModal,
  isEdit,
  editUser,
  teams,
  handleAddUser,
}) {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    role: null,
    team: null,
    reminder1: null,
    reminder2: null,
    reminder3: null,
    password: "",
    showPasswordField: false,
    selectedTeams: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const store = useContext(MobXProviderContext);
  const { userStore } = useContext(MobXProviderContext);
  const { defaultLocation } = userStore;

  const userRoles = [
    { label: "Account Admin", value: "Account Admin" },
    { label: "Team Admin", value: "Team Admin" },
    { label: "User", value: "User" },
  ];

  const reminderOptions = [
    { label: "No Reminder", value: "no reminder" },
    { label: "15 Minutes", value: "15" },
    { label: "30 Minutes", value: "30" },
    { label: "45 Minutes", value: "45" },
    { label: "60 Minutes", value: "60" },
    { label: "90 Minutes", value: "90" },
    { label: "120 Minutes", value: "120" },
    { label: "180 Minutes", value: "180" },
  ];

  const getReminderLabel = (value) => {
    const reminder = reminderOptions.find((option) => option.value == value);
    return reminder ? reminder.label : "No Reminder";
  };

  useEffect(() => {
    if (editUser) {
      dispatch({ field: "showPasswordField", value: false });
      dispatch({ field: "firstName", value: editUser?.attributes?.first_name });
      dispatch({ field: "lastName", value: editUser?.attributes?.last_name });
      dispatch({ field: "email", value: editUser?.attributes?.email });
      dispatch({
        field: "role",
        value: {
          label: editUser?.attributes?.role,
          value: editUser?.attributes?.role,
        },
      });

      const selectedTeams = editUser?.attributes?.employee_teams?.map(
        (team) => ({
          label: team.team_name,
          value: team.team_id,
        })
      );

      const teams = editUser?.attributes?.employee_teams?.map((team) => ({
        label: team.team_name,
        value: team.team_id,
        id: team.id,
        destroy: false,
      }));

      dispatch({
        field: "selectedTeams",
        value: selectedTeams,
      });

      dispatch({
        field: "team",
        value: teams,
      });

      dispatch({
        field: "reminder1",
        value: {
          label: getReminderLabel(editUser?.attributes?.first_reminder),
          value: editUser?.attributes?.first_reminder,
        },
      });
      dispatch({
        field: "reminder2",
        value: {
          label: getReminderLabel(editUser?.attributes?.second_reminder),
          value: editUser?.attributes?.second_reminder,
        },
      });
      dispatch({
        field: "reminder3",
        value: {
          label: getReminderLabel(editUser?.attributes?.third_reminder),
          value: editUser?.attributes?.third_reminder,
        },
      });
    } else {
      dispatch({ type: "reset" });
    }
  }, [editUser?.attributes, visible]);

  const applyPressed = () => {
    let payload = {
      user: {
        email: provider.email,
        first_name: provider.firstName,
        last_name: provider.lastName,
        first_reminder: provider.reminder1?.value ?? "",
        second_reminder: provider.reminder2?.value ?? "",
        third_reminder: provider.reminder3?.value ?? "",
      },
      location_employee: {
        role: provider.role?.label == "Account Admin" ? "admin" : "full_time",
        employee_teams_attributes: provider.selectedTeams?.map((team) => {
          return { team_id: Number(team.value) };
        }),
      },
      send_email: true,
      team_admin: provider.role?.label == "Team Admin" ? true : false,
    };

    store.userStore.setLoader(true);
    store.teamStore
      .createTeamUser({ location_id: defaultLocation?.organizationId }, payload)
      .then((response) => {
        if (response?.data?.attributes) {
          handleAddUser();
          errorHandler({
            title: "User added successfully.",
          });
        }
        setModal(false);
        store.userStore.setLoader(false);
      });
  };

  const handleUpdate = () => {
    let payload = {
      user: {
        email: provider.email,
        first_name: provider.firstName,
        last_name: provider.lastName,
        first_reminder: provider.reminder1?.value ?? "",
        second_reminder: provider.reminder2?.value ?? "",
        third_reminder: provider.reminder3?.value ?? "",
        password: provider.password ?? "",
      },
      location_employee: {
        role: provider.role?.label == "Account Admin" ? "admin" : "full_time",
        employee_teams_attributes: provider.team?.map((team) => {
          return {
            team_id: Number(team.value),
            id: team.id ?? "",
            _destroy: team.destroy,
          };
        }),
      },
      team_admin: provider.role?.label == "Team Admin" ? true : false,
    };

    store.userStore.setLoader(true);
    store.teamStore
      .updateTeamUser(
        editUser?.attributes?.user_id,
        {
          location_id: defaultLocation?.organizationId,
          location_employee_id: editUser?.attributes?.location_employee_id,
        },
        payload
      )
      .then((response) => {
        if (response?.data?.attributes) {
          handleAddUser();
          errorHandler({
            title: "User updated successfully.",
          });
        }
        setModal(false);
        store.userStore.setLoader(false);
      });
  };

  const hanldePasswordResetLink = () => {
    store.userStore.setLoader(true);
    store.teamStore
      .resetPassword({
        location_id: defaultLocation?.organizationId,
        email: provider.email,
      })
      .then((response) => {
        store.userStore.setLoader(false);
        if (response?.data?.message)
          errorHandler({
            title:
              "An email was just sent to you with password reset instructions.",
          });
        setModal(false);
      });
  };

  const handleDeleteUser = () => {
    store.userStore.setLoader(true);
    store.teamStore
      .deleteTeamUser(
        { location_id: defaultLocation?.organizationId },
        editUser?.id
      )
      .then((response) => {
        store.userStore.setLoader(false);
        if (!response?.response?.data?.errors) {
          errorHandler({
            title: "User successfully deleted",
          });
        }

        handleAddUser();
        setModal(false);
      });
  };

  const handleTeamUpdate = (updatedSelectedTeams) => {
    dispatch({
      field: "selectedTeams",
      value: updatedSelectedTeams,
    });

    const updatedTeams = provider.team?.map((team) => {
      const updatedTeam = updatedSelectedTeams?.find(
        (updated) => updated.value == team.value
      );

      if (updatedTeam) {
        return {
          ...team,
          destroy: false,
        };
      } else {
        return {
          ...team,
          destroy: true,
        };
      }
    });

    const newTeams = updatedSelectedTeams
      ?.filter(
        (updated) => !provider.team?.some((team) => team.value == updated.value)
      )
      ?.map((newTeam) => ({
        label: newTeam.label,
        value: newTeam.value,
        id: "",
        destroy: false,
      }));

    const finalTeams = [...updatedTeams, ...newTeams];

    dispatch({
      field: "team",
      value: finalTeams,
    });
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] max-h-[640px] d-col justify-between rounded-xl transform overflow-y-auto sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-col">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl text-[#3a3a3a] font-inter-semibold">
                      {isEdit ? "Edit User" : "Add User"}
                    </h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                      Name*
                    </span>

                    <div className="d-row w-full gap-4">
                      <input
                        required
                        className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-sm w-full !text-md bg-background pl-4"
                        placeholder="First name"
                        value={provider.firstName}
                        onChange={(e) =>
                          dispatch({
                            field: "firstName",
                            value: e.target.value,
                          })
                        }
                      />

                      <input
                        required
                        className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-sm w-full !text-md bg-background pl-4"
                        placeholder="Last name"
                        value={provider.lastName}
                        onChange={(e) =>
                          dispatch({
                            field: "lastName",
                            value: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                      Email*
                    </span>

                    <input
                      required
                      className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-sm w-full !text-md bg-background pl-4"
                      placeholder="email"
                      type="email"
                      value={provider.email}
                      onChange={(e) =>
                        dispatch({
                          field: "email",
                          value: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                      Role*
                    </span>

                    <Select
                      required
                      styles={customStylesSubsidy({ preferences: true })}
                      aria-labelledby="sort-by"
                      aria-label="sort-by"
                      placeholder="User"
                      closeMenuOnSelect
                      value={provider.role}
                      onChange={(option) => {
                        dispatch({
                          field: "role",
                          value: option,
                        });
                      }}
                      options={userRoles}
                      className="w-full rounded-xl"
                    />
                  </div>

                  <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                      Attach to teams*
                    </span>

                    <MultiSelect
                      options={teams}
                      selectedOptions={provider.selectedTeams}
                      handleChange={handleTeamUpdate}
                      placeholderValue="Choose teams"
                      handleRemoveOption={handleTeamUpdate}
                    />
                  </div>

                  <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                      Reminders
                    </span>

                    <Select
                      menuPosition="relative"
                      menuPlacement="auto"
                      styles={customStylesSubsidy({ preferences: true })}
                      aria-labelledby="sort-by"
                      aria-label="sort-by"
                      placeholder="Reminder 1"
                      closeMenuOnSelect
                      value={provider.reminder1}
                      onChange={(option) => {
                        dispatch({
                          field: "reminder1",
                          value: option,
                        });
                      }}
                      options={reminderOptions}
                      className="w-full rounded-xl"
                      menuShouldScrollIntoView={true}
                      maxMenuHeight={140}
                    />

                    <Select
                      menuPosition="relative"
                      menuPlacement="auto"
                      styles={customStylesSubsidy({ preferences: true })}
                      aria-labelledby="sort-by"
                      aria-label="sort-by"
                      placeholder="Reminder 2"
                      closeMenuOnSelect
                      value={provider.reminder2}
                      onChange={(option) => {
                        dispatch({
                          field: "reminder2",
                          value: option,
                        });
                      }}
                      options={reminderOptions}
                      className="w-full rounded-xl mt-4"
                      menuShouldScrollIntoView={true}
                      maxMenuHeight={140}
                    />

                    <Select
                      menuPosition="relative"
                      menuPlacement="auto"
                      styles={customStylesSubsidy({ preferences: true })}
                      aria-labelledby="sort-by"
                      aria-label="sort-by"
                      placeholder="Reminder 3"
                      closeMenuOnSelect
                      value={provider.reminder3}
                      onChange={(option) => {
                        dispatch({
                          field: "reminder3",
                          value: option,
                        });
                      }}
                      options={reminderOptions}
                      className="w-full rounded-xl mt-4"
                      menuShouldScrollIntoView={true}
                      maxMenuHeight={140}
                    />
                  </div>

                  {editUser && (
                    <>
                      <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                        {!provider.showPasswordField && (
                          <button
                            className="text-white font-inter-medium text-center bg-[#575be8] w-full h-12 py-2 rounded-xl"
                            onClick={() =>
                              dispatch({
                                field: "showPasswordField",
                                value: true,
                              })
                            }
                          >
                            Password reset
                          </button>
                        )}

                        {provider.showPasswordField && (
                          <>
                            <span className="ml-1 font-inter-semibold text-[18px] mb-[8px] text-sm w-40 text-[#2d3958]">
                              Password Reset
                            </span>

                            <input
                              required
                              className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-sm w-full !text-md bg-background pl-4"
                              placeholder="New password"
                              value={provider.password}
                              onChange={(e) =>
                                dispatch({
                                  field: "password",
                                  value: e.target.value,
                                })
                              }
                            />
                          </>
                        )}
                      </div>

                      <div className="d-row w-full gap-4 py-1.5">
                        <div className="w-1/2 bg-background rounded-xl flex items-center justify-center">
                          <button
                            className="h-12 px-[19px] py-[9px] bg-[#f7f9ff] rounded-xl text-[#2d3958] text-base font-inter-medium"
                            onClick={hanldePasswordResetLink}
                          >
                            Send a reset link
                          </button>
                        </div>

                        <div className="w-1/2 bg-background rounded-xl flex items-center justify-center">
                          <button
                            className="h-12 px-[19px] py-[9px] bg-[#f7f9ff] rounded-xl text-[#d56c76] text-base font-inter-medium"
                            onClick={handleDeleteUser}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={() => {
                      if (editUser) handleUpdate();
                      else applyPressed();
                    }}
                  >
                    Apply
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddUserToTeam;
