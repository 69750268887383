import { observer } from "mobx-react";
import { MobXProviderContext } from "mobx-react";
import { useContext } from "react";
import { GroupUsersIcon } from "assets/img";
import { useNavigate } from "react-router-dom";

function StartGroupOrderBadge({ address, menu }) {
  const store = useContext(MobXProviderContext);
  const navigate = useNavigate();

  const onClickStart = () => {
    store.mealManagementStore.setCurrentGroupOrder(null);
    navigate("/group-order/new", { state: { menu: menu } });
  };

  return (
    <div className="flex flex-col w-full sm:flex-row p-6 sm:mt-4 bg-background rounded-xl sm:items-center sm:justify-between">
      <div className="flex flex-col w-full sm:w-[70%]">
        <div className="flex gap-2">
          <img src={GroupUsersIcon} alt="group-user-icon" className="icon" />
          <span className="text-md font-inter-semibold">Group order</span>
        </div>

        <span className="text-sm mt-3">
          Easily share order with friends and colleagues so each person can add
          items
        </span>
      </div>

      <div>
        <button
          onClick={onClickStart}
          className="w-[86px] text-white btn-style py-[10px] px-6 text-md mt-4 bg-hh-accent-light hover:bg-hh-accent-medium rounded-[32px]"
        >
          Start
        </button>
      </div>
    </div>
  );
}

export default observer(StartGroupOrderBadge);
