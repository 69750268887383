import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState, useCallback } from "react";
import { MobXProviderContext } from "mobx-react";
import userStore from "mobXstore/userStore";

function ChangeMealPlanName({ visible, setModal, placeholder }) {
  const { mealManagementStore } = useContext(MobXProviderContext);
  const [mealPlanName, setMealPlanName] = useState("");
  const mealPlan = mealManagementStore.mealPlan;
  const locationID = userStore.locationID;

  const applyChanges = useCallback(() => {
    if (mealPlanName === "") return;

    mealManagementStore
      .updateMealPlan(locationID, mealPlan?.id, {
        meal_series: {
          name: mealPlanName,
        },
      })
      .then(() => setModal(false));
  }, [mealPlanName]);
  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[667px] h-[276px] d-col justify-between rounded-xl transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-row justify-between items-center">
                  <h2 className="text-2xl font-inter-bold">
                    Edit meal plan name
                  </h2>
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <span className="font-inter-regular text-dark-gray text-[14px]">
                  Name will be visible to staff and restaurants
                </span>

                <input
                  required
                  className="flex w-full bg-background text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]"
                  placeholder={placeholder}
                  onChange={(e) => {
                    setMealPlanName(e?.target?.value);
                  }}
                />
                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={applyChanges}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ChangeMealPlanName;
