import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import { observer } from "mobx-react";
import { useMenuItemSelection } from "utils/hooks/useMenuItemSelection";
import CloseButton from "components/CloseButton";
import { TIP_VALUES_DESKTOP, TIP_VALUES_MOBILE } from "utils/constants/common";
import TipButton from "components/TipButton";
import { preventMinus, preventPasteNegative } from "utils/helpers/validation";

function EditTipModal({
  visible,
  setModal,
  selectedItem,
  openedItems,
  edit,
  editItem,
  attendeeToUpdate,
  setModalKey,
  modalKey,
  data,
  setEditTipModal,
  getOrder,
}) {
  const screenType = Screen.ScreenType();
  const [attachLabelAlert, setAttachLabelAlert] = useState(null);
  const [isMultipleItemArray, setIsMultipleItemArray] = useState(
    editItem && editItem.length > 1
  );

  const calculateInitialPrice = () => {
    if (edit) {
      if (isMultipleItemArray) {
        return editItem?.map((item) => ({
          [item.attributes.menu_item.id]: item.attributes.total_price,
        }));
      } else {
        const { menu_item, total_price } = editItem?.attributes;
        return { [menu_item?.id]: total_price };
      }
    } else return openedItems?.[selectedItem]?.attributes.price;
  };

  const [getItemTotalPrice, setItemTotalPrice] = useState(
    calculateInitialPrice()
  );

  const store = useContext(MobXProviderContext);

  const menuItem = edit
    ? editItem?.attributes?.menu_item
    : openedItems?.[selectedItem]?.attributes;

  const [foodTotalPrice, setFoodTotalPrice] = useState(
    attendeeToUpdate?.attributes?.subtotal
  );

  function createInitialProvider() {
    return {
      tip: "",
      quantity: 1,
      orderItemOptionId: "",
      otherTip: "",
    };
  }

  let initialProvider = isMultipleItemArray
    ? editItem.map(() => createInitialProvider())
    : createInitialProvider();

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialProvider;
      default:
        if (isMultipleItemArray) {
          return state?.map((provider, index) =>
            index == action.index
              ? { ...provider, [action.field]: action.value }
              : provider
          );
        } else {
          return {
            ...state,
            [action.field]: action.value,
          };
        }
    }
  }

  const [provider, dispatch] = useReducer(reducer, initialProvider);

  useEffect(() => {
    if (edit) setModalKey((prev) => prev + 1);
  }, [foodTotalPrice, getItemTotalPrice, menuItem, provider]);

  const modifyTip = () => {
    store.userStore.setLoader(true);

    let payload = {
      meal_series: {
        meals_attributes: [
          {
            tip: provider?.tip === "Other" ? provider?.otherTip : provider?.tip,
            id: data?.attributes?.meals[0]?.attributes?.id,
          },
        ],
      },
    };

    let params = {
      location_id: data?.attributes?.location_id,
    };

    store?.mealManagementStore
      ?.updateGroupOrder(data?.id, payload, params)
      .then(() => {
        store.userStore.setLoader(false);
        setEditTipModal(false);
        getOrder();
      });
  };

  const children = (
    <>
      <Transition.Child
        // as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>
      <div className="fixed top-0 right-0 left-0 sm:inset-0">
        <div className="flex min-h-screen max-h-screen md:min-h-full md:items-center md:justify-center md:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="flex flex-col max-h-screen overflow-auto flex-1 pb-44 md:pb-10 md:flex-initial w-full md:w-5/12 transform md:rounded-2xl bg-white py-10 px-8 text-left align-middle shadow-xl transition-all">
              <div className="hidden sm:flex sticky justify-between items-start mb-3">
                <div />

                <h1 className="text-[32px] font-inter-semibold text-[#3A3A3A]">
                  Modify tip amount
                </h1>

                <CloseButton setModal={setModal} />
              </div>

              <span className="text-md">
                Tip is calculated based on your order total: [Food total].
              </span>

              <div className="d-col items-center w-full">
                <div className="flex items-center">
                  {TIP_VALUES_DESKTOP?.map((tipValue) => (
                    <TipButton
                      key={`${tipValue}-tip`}
                      onClick={() =>
                        dispatch({
                          field: "tip",
                          value: tipValue,
                        })
                      }
                      tipValue={tipValue}
                      tip={provider?.tip}
                    />
                  ))}
                </div>

                {provider.tip == "Other" && (
                  <div className="flex flex-col w-full mt-5">
                    <small>Enter custom amount</small>
                    <div className="flex items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12 border border-border-gray">
                      <span className="mr-1">$</span>

                      <input
                        className="text-sm bg-background h-[46px] w-full focus:outline-none"
                        value={provider.otherTip}
                        type="number"
                        min="0"
                        step=".01"
                        onKeyDown={preventMinus}
                        onPaste={preventPasteNegative}
                        onChange={(e) =>
                          dispatch({
                            field: "otherTip",
                            value:
                              e.target.value !== ""
                                ? Math.abs(e.target.value)
                                : e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="d-row justify-center mt-[16px] items-center">
                <button
                  className="w-[98px] h-12 mr-2 p-2.5 rounded-md border border-zinc-200 text-slate-700 font-inter-medium capitalize"
                  onClick={() => setModal(false)}
                >
                  Cancel
                </button>

                <button
                  className="w-[98px] h-12 p-2.5 bg-indigo-900 rounded-md border border-indigo-900 text-white fornt-inter-medium capitalize"
                  onClick={() => modifyTip()}
                >
                  Save
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      {screenType?.isTabletOrMobile ? (
        <Dialog
          as="div"
          open={visible}
          className="relative z-[90]"
          onClose={() => {}}
        >
          {children}
        </Dialog>
      ) : (
        <Dialog
          as="div"
          className="relative z-[90]"
          onClose={() => {
            screenType?.isTabletOrDesktop && setModal(false);
          }}
        >
          {children}
        </Dialog>
      )}
    </Transition>
  );
}

export default observer(EditTipModal);
