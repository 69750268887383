import React, { useState, useContext, useEffect, useReducer } from "react";
import { MobXProviderContext, observer } from "mobx-react";
import Input from "components/input";
import CuisineGrid from "containers/MealManagement/cuisineGrid";
import { toJS } from "mobx";
import Select from "react-select";
import { customStylesSubsidy } from "utils/helpers/styles";
import { NotificationBanner } from "components";
import { errorHandler } from "utils/middlewares/errorHandler";
import classNames from "classnames";

const TeamPreferences = () => {
  const initialState = {
    mealPlans: [],
    mealPlan: null,
    selectedMealPlan: null,
    cuisineTypes: [],
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [disableEdit, setDisableEdit] = useState(false);
  const [preferenceAlert, setPreferenceAlert] = useState(false);
  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  const mealManagementStore = toJS(store?.mealManagementStore);
  const { defaultLocation } = userStore;
  const { dietaryPreferences } = mealManagementStore;

  const [provider, dispatch] = useReducer(reducer, initialState);
  const { mealPlans, selectedMealPlan, cuisineTypes } = provider;

  useEffect(() => {
    let cuisines = [];
    dispatch({ field: "mealPlans", value: [] });
    store.mealManagementStore
      .fetchCuisineTypesData(defaultLocation?.organizationId)
      .then((response) => {
        cuisines = response;
        dispatch({ field: "cuisineTypes", value: response });
      });

    store.mealManagementStore
      .fetchMealPlans(defaultLocation?.organizationId)
      .then((response) => {
        const mealPlans = response?.data?.map((data) => ({
          label: data.attributes.name,
          value: data.id,
        }));

        dispatch({ field: "mealPlans", value: mealPlans });
        dispatch({ field: "mealPlan", value: mealPlans[0] });
        getMealPlan(mealPlans[0]?.value, cuisines);
      })
      .catch(() => store.userStore.setLoader(false));
  }, [defaultLocation?.organizationId]);

  const getMealPlan = (id, cuisineType = cuisineTypes) => {
    setPreferenceAlert(false);

    store.mealManagementStore
      .fetchMealPlan(defaultLocation?.organizationId, id)
      .then((response) => {
        dispatch({
          field: "selectedMealPlan",
          value: {
            dietaryPreferences: null,
            cuisines: null,
            additionalNote: null,
            id: "",
          },
        });

        const dietaryPreferences =
          response?.data?.attributes?.meal_plan_preference?.dietary_preferences?.split(
            ", "
          ) ?? [];

        const responseCuisines =
          response?.data?.attributes?.meal_plan_preference?.cuisines ?? [];

        const updatedCuisines = cuisineType?.map((cuisine) => ({
          name: cuisine.name,
          review: responseCuisines[cuisine.name] || "neutral",
        }));

        dispatch({
          field: "selectedMealPlan",
          value: {
            dietaryPreferences: dietaryPreferences,
            cuisines: updatedCuisines,
            additionalNote:
              response?.data?.attributes?.meal_plan_preference
                ?.additional_notes ?? "",
            id: response?.data?.id ?? "",
          },
        });
      })
      .catch(() => store.userStore.setLoader(false));
  };

  const createCuisinePayload = (cuisines) => {
    return cuisines?.reduce((acc, cuisine) => {
      acc[cuisine.name] = cuisine.review;
      return acc;
    }, {});
  };

  const updateMealPlan = (updatedMealPlan = selectedMealPlan) => {
    const cuisines = createCuisinePayload(updatedMealPlan?.cuisines);

    const payload = {
      meal_series: {
        meal_plan_preference_attributes: {
          cuisines: cuisines,
          dietary_preferences: updatedMealPlan?.dietaryPreferences?.join(", "),
          additional_notes: updatedMealPlan?.additionalNote,
        },
      },
    };
    store.mealManagementStore
      .updateMealPlan(
        defaultLocation?.organizationId,
        updatedMealPlan?.id,
        payload
      )
      .then((response) => {
        const meal = {
          label: response?.data?.attributes?.name,
          value: response?.data?.id,
        };
        dispatch({ field: "mealPlan", value: meal });
      })
      .catch(() => store.userStore.setLoader(false));
  };

  const handleAdditionalPreference = (value) => {
    checkSelectedMeal();
    dispatch({
      field: "selectedMealPlan",
      value: {
        ...selectedMealPlan,
        additionalNote: value,
      },
    });
  };

  const handleDietaryPreferenceClick = (value) => {
    if (!provider.mealPlan || !selectedMealPlan) {
      errorHandler({
        title: "Please select the meal plan.",
      });
      return;
    }
    let updatedPreferences;

    const dietaryPreferences = selectedMealPlan?.dietaryPreferences || [];
    if (dietaryPreferences?.includes(value)) {
      updatedPreferences = selectedMealPlan?.dietaryPreferences?.filter(
        (preference) => preference !== value
      );

      dispatch({
        field: "selectedMealPlan",
        value: {
          ...selectedMealPlan,
          dietaryPreferences: updatedPreferences,
        },
      });
    } else {
      updatedPreferences = [...dietaryPreferences, value];

      dispatch({
        field: "selectedMealPlan",
        value: {
          ...selectedMealPlan,
          dietaryPreferences: updatedPreferences,
        },
      });
    }

    updateMealPlan({
      ...selectedMealPlan,
      dietaryPreferences: updatedPreferences,
    });
  };

  const handleCuisineTypeClick = (name, review) => {
    checkSelectedMeal();
    let cuisines = selectedMealPlan?.cuisines?.map((cuisine) =>
      cuisine.name == name ? { ...cuisine, review } : cuisine
    );

    dispatch({
      field: "selectedMealPlan",
      value: {
        ...selectedMealPlan,
        cuisines: cuisines,
      },
    });

    updateMealPlan({ ...selectedMealPlan, cuisines: cuisines });
  };

  const checkSelectedMeal = () => {
    if (!provider.mealPlan || !selectedMealPlan) {
      errorHandler({ title: "Please select the meal plan." });
      return false;
    }
    return true;
  };

  const isSelected = (diet) =>
    selectedMealPlan?.dietaryPreferences?.some(
      (preference) => preference == diet
    );

  return (
    <div className="d-col p-6 mt-[24px] w-full relative bottom-6">
      <NotificationBanner
        message="Additional preferences successfully updated."
        messageType="success"
        textColor="success"
        visible={preferenceAlert}
        setVisible={setPreferenceAlert}
      />

      <h1 className="font-inter-bold text-heading text-[32px]">
        Restaurant & dietary preferences
      </h1>

      <small className="text-heading text-[16px] font-inter-normal">
        Your input helps shape your Uncatering meal plan
      </small>

      <Select
        styles={customStylesSubsidy({ preferences: true })}
        aria-labelledby="sort-by"
        aria-label="sort-by"
        placeholder="Select Meal"
        closeMenuOnSelect
        onChange={(option) => {
          dispatch({ field: "mealPlan", value: option });
          getMealPlan(option.value);
        }}
        options={mealPlans}
        className="w-[295px] mt-[24px] capitalize"
        value={provider.mealPlan}
      />

      <h2 className="font-inter-semibold mt-[24px] text-heading text-[20px]">
        Dietary preferences
      </h2>

      <small className="text-heading text-[16px] font-inter-normal">
        Highlight if members of your team have any of the following dietary
        preferences
      </small>

      <div className="mb-0.5 w-full xs:w-full-215 mt-[24px]">
        <div className="lg:inline-flex md:inline-flex">
          {dietaryPreferences?.map((value, index) => (
            <div
              key={index}
              className={classNames({
                "lg:mr-3 md:mr-1 mt-4 lg:mt-0 md:mt-0 px-4 py-3 hover:cursor-pointer text-slate-700 font-inter-medium text-sm leading-[14px]": true,
                [isSelected(value)
                  ? "bg-indigo-500 text-white"
                  : "bg-background hover:bg-indigo-500 hover:text-white"]: true,
                "rounded-3xl justify-start lg:justify-center md:justify-center gap-2 flex": true,
              })}
              onClick={() => handleDietaryPreferenceClick(value)}
            >
              {value}
            </div>
          ))}
        </div>
      </div>

      <div className="w-full mt-[24px]">
        <Input
          text="Any additional preferences or dietary restrictions?"
          placeholder={"ex. Halal Food"}
          otherMealName={handleAdditionalPreference}
          mealName={selectedMealPlan?.additionalNote}
          isCompulsary={false}
          backgroundColor="bg-background"
          editIcon={disableEdit}
          handleEditClick={() => {
            if (checkSelectedMeal()) setDisableEdit(true);
          }}
          handleSave={() => {
            setDisableEdit(false);
            updateMealPlan();
            setPreferenceAlert(true);
          }}
        />
      </div>

      <h2 className="font-inter-semibold mt-[24px] text-heading text-[20px]">
        Cuisine preferences
      </h2>
      <small className="text-heading text-[16px] font-inter-normal mb-[33px]">
        How does your team feel about the following cuisines?
      </small>

      <CuisineGrid
        dietaryPreferences={cuisineTypes}
        handleReviewButton={handleCuisineTypeClick}
        selectedCuisines={selectedMealPlan?.cuisines}
      />
    </div>
  );
};

export default observer(TeamPreferences);
