import { API_URL } from "../utils/constants/api";
import LocalStorage from "utils/helpers/localStorage";
import axios from "axios";

const makeHeaders = (isFileUpload = false) => {
  let access_token = LocalStorage.getAccessToken();

  if (isFileUpload && access_token !== null) {
    return {
      "Content-Type": "multipart/form-data",
      "ACCESS-TOKEN": access_token,
    };
  } else if (access_token !== null)
    return {
      "Content-Type": "application/json",
      "ACCESS-TOKEN": access_token,
      Accept: "application/json",
    };
  else
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
};

const client = (isFileUpload = false) => {
  return axios.create({
    baseURL: API_URL,
    headers: makeHeaders(isFileUpload),
  });
};

export { client };
