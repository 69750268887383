import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "react-google-autocomplete";
import { faEdit, faCog } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

function AddressInput({
  provider,
  dispatch,
  label,
  mannualEntry = false,
  isDisabled = true,
  setMannualAddress,
  isOnboarding,
  fromDeliveryInfo,
}) {
  const [showLabel, setShowLabel] = useState(false);

  return (
    <div className="w-full">
      <>
        <small className="text-dark-gray font-inter-regular text-xs">
          {isOnboarding
            ? `${showLabel ? "Delivery address*" : ""} `
            : `${label}`}
        </small>

        <Autocomplete
          className={classNames(
            "relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-[#566884] text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full",
            {
              "bg-background rounded-xl": isOnboarding,
            }
          )}
          onFocus={() => setShowLabel(true)}
          onBlur={() => setShowLabel(false)}
          key={process.env.REACT_APP_GOOGLE_MAP_KEY}
          defaultValue={provider ? provider.autoCompleteAddress : ""}
          placeholder={
            isOnboarding
              ? `${showLabel ? "" : "Delivery Address*"}`
              : "Start typing address here"
          }
          options={{
            componentRestrictions: { country: "CA" },
            fields: ["address_components", "geometry", "formatted_address"],
            types: ["address"],
          }}
          apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
          onPlaceSelected={(place, inputRef, autocomplete) => {
            let provinceName;
            const _address = place?.address_components?.reduce(
              (seed, { long_name, short_name, types }) => {
                types.forEach((t) => {
                  if (t == "administrative_area_level_1") {
                    provinceName = short_name;
                    seed[t] = long_name;
                  } else {
                    seed[t] = long_name;
                  }
                });
                return seed;
              },
              {}
            );

            setMannualAddress(true);

            dispatch({
              field: "autoCompleteAddress",
              value: place?.formatted_address,
            });
            if (fromDeliveryInfo)
              dispatch({
                field: "province",
                value: {
                  label: _address?.administrative_area_level_1,
                  value: provinceName,
                },
              });
            else
              dispatch({
                field: "province",
                value: _address?.administrative_area_level_1,
              });
            dispatch({ field: "streetName", value: _address?.route });
            dispatch({ field: "city", value: _address?.locality });
            dispatch({ field: "streetNumber", value: _address?.street_number });
            dispatch({ field: "postalCode", value: _address?.postal_code });
            dispatch({ field: "unit", value: _address?.subpremise });
          }}
        />
      </>
    </div>
  );
}
export default AddressInput;
