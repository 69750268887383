import { useContext, useState, Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import dateTime from "utils/helpers/dateTime";
import userStore from "mobXstore/userStore";
import { toJS } from "mobx";
import { isPresent } from "utils/helpers/array";

function ShowMeals({
  visible,
  setModal,
  mealsToShow,
  setUncateringMealPending,
  setAddMeal,
}) {
  const locationID = userStore.locationID;
  const navigate = useNavigate();

  const naigateToMealDetail = (event) => {
    if (event?.service == "uncatering" && event?.under_review) {
      setModal(false);
      setUncateringMealPending(true);
    } else if (
      event?.service == "uncatering" &&
      !event?.under_review &&
      event?.published &&
      !event?.all_orders_cancelled
    ) {
      setModal(false);
      navigate(`/home/uncatering-meal/${event?.id}`);
    } else if (
      (event?.service == "odc" || event?.service == "catering") &&
      event?.published &&
      !event?.all_orders_cancelled &&
      event?.shipments?.length == 1
    ) {
      setModal(false);
      navigate(
        `/meal-management/catering-order/${event?.orders[0]?.attributes?.slug}/${event?.orders[0]?.attributes?.location_id}`
      );
    } else if (
      event?.service == "grouped" &&
      event?.published &&
      !event?.all_orders_cancelled
    ) {
      setModal(false);
      navigate(
        `/group-order/admin/orders/${event?.meal_series_id}/${locationID}`
      );
    }
  };

  const addMeal = () => {
    setModal(false);
    setAddMeal(true);
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[596px] max-h-1/2 d-col justify-between rounded-xl transform overflow-scroll sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-row justify-end items-center">
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <div className="mb-[16px]">
                  <small className="font-inter-regular text-[13px] text-secondary">
                    View Meals on Date
                  </small>
                  <div className="d-row justify-between">
                    <small className="font-inter-semibold text-[24px] text-dark-gray">
                      {isPresent(mealsToShow)
                        ? dateTime.formatDate(
                            mealsToShow[0]?.meal_date,
                            "ddd, MMMM D, yyyy"
                          )
                        : ""}
                    </small>

                    <button
                      onClick={addMeal}
                      className="text-catering font-inter-regular text-[16px] hover:text-dark-gray"
                    >
                      + Add Meal
                    </button>
                  </div>
                </div>

                {mealsToShow?.map((event) => (
                  <div key={event?.id}>
                    <div
                      type="button"
                      className={classNames({
                        "flex w-full min-h-[82px] flex-row justify-between px-[8px] border-l-[6px] mb-2 text-left px-1 py-1 w-[144px] min-h-[25px] rounded-sm mx-auto": true,
                        "border-under-review border-l-[6px] bg-[#F4F4F4]":
                          event?.service == "uncatering" && event?.under_review,
                        "border-catering border-l-[6px]":
                          event?.service == "odc" &&
                          event?.published &&
                          !event?.all_orders_cancelled,
                        "border-uncatering border-l-[6px]":
                          event?.service == "uncatering" &&
                          event?.published &&
                          !event?.all_orders_cancelled,
                        "border-group border-l-[6px]":
                          event?.service == "grouped" &&
                          event?.published &&
                          !event?.all_orders_cancelled,
                        "bg-[#F4F4F4] border-[#F4F4F4]":
                          !event?.under_review &&
                          (!event?.published || event?.all_orders_cancelled),
                        "bg-light-gray":
                          event?.published && !event?.all_orders_cancelled,
                      })}
                    >
                      <div className="d-col justify-center items-start">
                        <span
                          className={classNames({
                            "font-inter-semibold text-[12px] text-left": true,
                            "text-catering": event?.service == "odc",
                            "text-uncatering": event?.service == "uncatering",
                            "text-grouped": event?.service == "grouped",
                          })}
                        >
                          {event?.service == "uncatering"
                            ? "Uncatering Meal"
                            : event?.service == "grouped"
                            ? "Group Order"
                            : "Catering"}
                        </span>

                        <span className="font-inter-semibold text-[16px] text-heading text-left">
                          {event?.name}
                        </span>

                        <span className="font-inter-medium text-xs text-[#566985] text-left">
                          {dateTime.formatTime(event?.cutoff, event?.tzinfo)}
                        </span>

                        {(event?.service == "odc" ||
                          event?.service == "catering") &&
                          event?.shipments?.length > 1 &&
                          event?.orders?.map((order) => (
                            <button
                              onClick={() => {
                                navigate(
                                  `/meal-management/catering-order/${order?.attributes?.slug}/${order?.attributes?.location_id}`
                                );
                              }}
                              className="text-catering capitalize font-inter-medium text-[10px] hover:text-dark-gray"
                            >
                              {order?.attributes?.restaurant_name}
                            </button>
                          ))}

                        {event.under_review &&
                          event?.service == "uncatering" && (
                            <small className="font-inter-medium text-under-review text-[12px] self-start">
                              Under review
                            </small>
                          )}
                      </div>

                      <button
                        onClick={() => {
                          naigateToMealDetail(event);
                        }}
                        className="text-catering font-inter-regular text-[16px] hover:text-dark-gray"
                      >
                        View Meal
                      </button>
                    </div>
                  </div>
                ))}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ShowMeals;
