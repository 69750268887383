import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import userStore from "mobXstore/userStore";

function AddUsersToMeal({
  visible,
  setModal,
  users,
  mealPlanId,
  fromMeal = false,
  meal,
}) {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const { mealManagementStore } = useContext(MobXProviderContext);
  const { homeStore } = useContext(MobXProviderContext);
  const { userStore } = useContext(MobXProviderContext);
  const { locationID } = userStore;

  let labels = users?.map((user) => {
    return {
      label: user?.attributes?.first_name + " " + user?.attributes?.last_name,
      value: user?.id,
    };
  });

  const applyPressed = () => {
    if (fromMeal) {
      homeStore
        ?.updateMeal(
          meal?.id,
          {
            meal: {
              employee_meals_attributes: selectedUsers?.map((user) => {
                return { location_employee_id: user?.value };
              }),
            },
          },
          locationID
        )
        .then(() => setModal(false));
    } else
      mealManagementStore
        .updateMealPlan(locationID, mealPlanId, {
          meal_series: {
            employee_meal_plans_attributes: selectedUsers?.map((user) => {
              return { location_employee_id: user?.value };
            }),
          },
        })
        .then(() => setModal(false));
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] h-[481px] d-col justify-between rounded-xl md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-col">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-inter-bold">Add users</h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <Select
                    styles={customStylesSubsidy}
                    aria-labelledby="sort-by"
                    aria-label="sort-by"
                    placeholder={
                      fromMeal
                        ? "Choose users to attach to you meal"
                        : "Choose users to attach to you meal plan"
                    }
                    closeMenuOnSelect
                    onChange={(values) => setSelectedUsers(values)}
                    options={labels}
                    className="w-full mt-[24px]"
                    isMulti
                  />
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={applyPressed}
                  >
                    Apply
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AddUsersToMeal;
