import {
  LogoutIcon,
  hhLogo,
  UserIcon,
  SettingsIcon,
  BusinessIcon,
  arrowDown,
} from "assets/img";
import { MobXProviderContext } from "mobx-react";
import { useContext, Fragment, useState, useCallback } from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Disclosure, Dialog, Transition } from "@headlessui/react";
import ConfirmationModal from "components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { getUncateringLocation } from "utils/helpers/array";
import Cookies from "js-cookie";
import LocalStorage from "utils/helpers/localStorage";
import ErrorAlert from "components/Alerts/ErrorAlert";

function GroupOrderLayout({ mainContent }) {
  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  const [logoutAlert, setLogoutAlert] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();
  let { defaultLocation, currentUser, errorMessage, error } = userStore;
  const isUncateringEnabled = getUncateringLocation(currentUser);

  const logout = () => {
    const source = Cookies.get("source");
    const domains =
      process.env.NODE_ENV == "development"
        ? ["localhost"]
        : [".hungerhub.com", "localhost"];

    domains.forEach((domain) => {
      ["employee-id", "email", "user-token", "source"].forEach((cookie) => {
        Cookies.remove(cookie, { path: "/", domain });
      });
    });

    LocalStorage.removeKey("auth_token");
    store.userStore.clearStoredData();

    window.location.href =
      source == "uncatering"
        ? process.env.REACT_APP_UNCATERING_APP_LINK
        : process.env.REACT_APP_CATERING_APP_LINK;
  };

  const onUserNavigationClick = useCallback((item) => {
    if (item == "uncatering-portal") {
      window.location.href = process.env.REACT_APP_UNCATERING_APP_LINK;
    }
  });

  const afterCloseCallBack = () => {
    store.userStore.setErrorAlert(false, "");
  };

  return (
    <>
      <Disclosure as="nav" className="mx-auto w-full">
        <div className="flex min-h-screen">
          {/* Main content */}
          <div className="flex-1 flex flex-col">
            <header className="sticky top-0 z-50">
              <div className="sticky top-0 z-40 flex h-[92px] bg-white shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 d-row justify-between">
                <div class="grid grid-cols-3 w-full">
                  <div className="d-row items-center"></div>

                  <div className="d-col justify-center items-center">
                    <img
                      className="h-[56px] w-[152px] m-[16px]"
                      src={hhLogo}
                      alt="hungerhub Company"
                    />
                  </div>

                  <div className="d-col justify-center items-end">
                    <div
                      className="d-row cursor-pointer"
                      onClick={() => setShowDropdown(true)}
                    >
                      <img
                        src={BusinessIcon}
                        alt="business-icon"
                        className="w-[15px] h-[15px] mt-0.5 mr-2"
                      />

                      <span className="text-slate-700 font-inter-medium text-base leading-tight capitalize">
                        {currentUser?.attributes?.first_name}{" "}
                        {currentUser?.attributes?.last_name}
                      </span>

                      <span className="text-slate-700 font-inter-medium text-base leading-tight ml-1">
                        (admin)
                      </span>

                      <span className="text-slate-700 font-inter-medium text-base leading-tight">
                        <img
                          src={arrowDown}
                          alt="arrow-down"
                          className="w-[13px] h-[13px] mt-1 ml-4"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            {/* Page content */}
            {mainContent}
          </div>
        </div>

        <Transition appear show={showDropdown} as={Fragment}>
          <Dialog
            as="div"
            unmount={false}
            onClose={() => {
              setShowDropdown(false);
            }}
            className="relative z-[90]"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-out duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                onClick={() => setShowDropdown(false)}
                className="fixed inset-0 bg-black bg-opacity-25"
              />
            </Transition.Child>

            <div className="fixed sm:top-[65px] sm:right-[65px]">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-out-in duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="flex w-full">
                  <div
                    className={`z-[60] flex flex-col bg-white rounded-xl w-full shadow-xl`}
                  >
                    <Dialog.Panel className="overflow-scroll w-full">
                      <div className="flex flex-col justify-between bg-white rounded-xl">
                        <div className="flex flex-col items-start">
                          <div
                            className="px-8 pt-4 pb-2 hover:bg-slate-50 hover:rounded-t-xl text-md btn-style w-full cursor-pointer inline-flex"
                            onClick={() => navigate("/home")}
                          >
                            <img src={SettingsIcon} alt="settings-icon" />
                            <span className="ml-4">Go to admin panel</span>
                          </div>

                          {isUncateringEnabled &&
                            defaultLocation?.uncatering && (
                              <div
                                className="px-8 pt-4 pb-2 hover:bg-slate-50 text-md btn-style w-full cursor-pointer inline-flex"
                                onClick={() =>
                                  onUserNavigationClick("uncatering-portal")
                                }
                              >
                                <img src={UserIcon} alt="user-icon" />
                                <span className="ml-4">
                                  Switch to user account
                                </span>
                              </div>
                            )}

                          <div
                            className="px-8 pt-2 pb-2 hover:bg-slate-50 hover:rounded-b-xl text-md btn-style w-full cursor-pointer inline-flex"
                            onClick={() => setLogoutAlert(true)}
                          >
                            <img
                              src={LogoutIcon}
                              alt="logout-icon"
                              className="w-[15px] h-[15px] mt-0.5 ml-0.5"
                            />
                            <span className="ml-4">Logout</span>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </Disclosure>

      <ErrorAlert
        visible={error}
        errorMessage={errorMessage}
        dismissAlert={afterCloseCallBack}
      />

      <ConfirmationModal
        visible={logoutAlert}
        setModal={setLogoutAlert}
        title={"Are you sure you want to logout?"}
        body={""}
        rightButtonText={"Logout"}
        leftButtonText={"Cancel"}
        onClickLeftButton={() => setLogoutAlert(null)}
        onClickRightButton={logout}
      />
    </>
  );
}

export default observer(GroupOrderLayout);
