import React, {
  useState,
  useContext,
  useReducer,
  useCallback,
  useEffect,
} from "react";
import { SearchBar, DatePicker, RestaurantCard } from "components";
import { isPresent } from "utils/helpers/array";
import FilterOptions from "./FilterOptions";
import pluralize from "pluralize";
import SortBySelect from "./SortBySelect";
import { MobXProviderContext, observer } from "mobx-react";
import {
  CalendarImg,
  CartBucket,
  Healthy,
  MapIcon,
  Mexican,
  NoRestaurants,
  Pleasers,
  SandWich,
  Sushi,
  Thai,
} from "assets/img";
import moment from "moment-timezone";
import TimePickerDropdown from "components/TimePickerDropdown";
import DeliveryPickupTabs from "components/DeliveryPickupTabs";
import classNames from "classnames";
import Ilustration from "components/Ilustration";
import "components/Ilustration/Ilustration.css";
import {
  cuisineFilters,
  dietaryFilters,
  featuredFilters,
  packagingFilters,
} from "utils/constants/filters";
import FiltersModal from "components/FiltersModal";
import { useNavigate } from "react-router-dom";
import { toJS } from "mobx";

function AddCateringMeal({ isGroup }) {
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const paymentInfoStore = toJS(store?.paymentInfoStore);
  const { cart } = cartStore;
  const { defaultPaymentMethodId } = paymentInfoStore;

  let {
    selectedAddress,
    selectedDate,
    selectedTime,
    allMenus,
    allMenusPagy,
    sortBy,
    allFilters,
    defaultLocation,
  } = userStore;

  const initialState = {
    date: selectedDate,
    time: selectedTime,
    availableTimes: null,
    availableDates: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [searchText, setSearchText] = useState("");
  const [isExpandedRadius, setExpandedRadius] = useState(false);
  const [filtersModal, setFiltersModal] = useState(false);
  const [featuredFilter, setFeaturedFilter] = useState([]);
  const [cuisineFilter, setCuisineFilter] = useState([]);
  const [dietaryFilter, setDietaryFilter] = useState([]);
  const [packagingFilter, setPackagingFilter] = useState([]);
  const navigate = useNavigate();

  const choosenTags = () => {
    let cuisineChosenTags = Array.isArray(cuisineFilter) ? cuisineFilter : [];
    let dietaryChosenTags = Array.isArray(dietaryFilter) ? dietaryFilter : [];
    let packagingChosenTags = Array.isArray(packagingFilter)
      ? packagingFilter
      : [];

    if (
      dietaryChosenTags?.length ||
      cuisineChosenTags?.length ||
      packagingChosenTags?.length
    ) {
      const allFilterOptionsChildren = {
        ...allFilters?.cuisines,
        ...allFilters?.dietary_preferences,
        ...allFilters?.packagings,
      };

      const newDietaryList = dietaryChosenTags.reduce((acc, curr) => {
        if (allFilterOptionsChildren[curr])
          acc = [...acc, ...allFilterOptionsChildren[curr]];
        return acc;
      }, []);

      const newCuisineList = cuisineChosenTags.reduce((acc, curr) => {
        if (allFilterOptionsChildren[curr])
          acc = [...acc, ...allFilterOptionsChildren[curr]];
        return acc;
      }, []);

      const newPackagingList = packagingChosenTags.reduce((acc, curr) => {
        if (allFilterOptionsChildren[curr])
          acc = [...acc, ...allFilterOptionsChildren[curr]];
        return acc;
      }, []);

      return newDietaryList.concat(newCuisineList).concat(newPackagingList);
    }

    return [];
  };

  const featuredBadges = () => {
    let featuredChosenTags = Array.isArray(featuredFilter)
      ? featuredFilter
      : featuredFilter
      ? [featuredFilter]
      : [];

    return featuredChosenTags;
  };

  const chosenFeaturedTags = () => {
    if (featuredFilter.length) {
      const allFilterOptionsChildren = {
        ...allFilters?.featured_badges,
      };

      const newFeaturedList = featuredFilter.reduce((acc, curr) => {
        if (allFilterOptionsChildren[curr])
          acc = [...acc, ...allFilterOptionsChildren[curr]];
        return acc;
      }, []);

      return newFeaturedList;
    }
    return [];
  };

  const clearAll = () => {
    setFeaturedFilter([]);
    setCuisineFilter([]);
    setDietaryFilter([]);
    setPackagingFilter([]);
  };

  const updateChosenRestaurantFilters = (filterType, value) => {
    switch (filterType) {
      case "featured":
        setFeaturedFilter((prev) =>
          prev.includes(value)
            ? prev.filter((tag) => tag !== value)
            : [...prev, value]
        );
        break;
      case "cuisine":
        setCuisineFilter((prev) =>
          prev.includes(value)
            ? prev.filter((tag) => tag !== value)
            : [...prev, value]
        );
        break;
      case "dietary":
        setDietaryFilter((prev) =>
          prev.includes(value)
            ? prev.filter((tag) => tag !== value)
            : [...prev, value]
        );
        break;
      case "packaging":
        setPackagingFilter((prev) =>
          prev.includes(value)
            ? prev.filter((tag) => tag !== value)
            : [...prev, value]
        );
        break;
      default:
        break;
    }
  };

  const isLoading = false;

  const cartLength = cart?.items?.length;

  const getAvailableDates = (date = provider.date) => {
    store.userStore
      .getAvailableDates({
        address: selectedAddress?.label,
        month: date ? moment(date).month() + 1 : moment().month() + 1,
        year: date ? moment(date).year() : moment().year(),
        current_date_time: moment().format("YYYY-MM-DD HH:mm"),
      })
      .then((response) => {
        dispatch({ field: "availableDates", value: response?.dates });
      });
  };

  const getAvailableTimes = (date = provider.date) => {
    store?.userStore
      ?.getAvailableTimes({
        address: selectedAddress?.label,
        selected_date: moment(date).format("YYYY-MM-DD"),
        current_date_time: moment().format("YYYY-MM-DD HH:mm"),
      })
      .then((response) => {
        dispatch({ field: "availableTimes", value: response?.times });
      });
  };

  const moveToRestaurantDetails = () => {};

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });

    store.userStore?.getSettings();
    getMenus(1, 30, null);
  }, []);

  useEffect(() => {
    getMenus(1, 30, null);
  }, [
    searchText,
    // isExpandedRadius,
    choosenTags()?.length,
    featuredBadges()?.length,
    provider.date,
    provider.time,
  ]);

  useEffect(() => {
    store?.userStore?.setLoader(true);
    store.paymentInfoStore
      .getLocationCreditCards({ location_id: defaultLocation?.organizationId })
      .then(() => store?.userStore?.setLoader(false));
  }, []);

  const isFutureDate = () => {
    return (
      provider.date &&
      provider.time &&
      moment(
        `${moment(provider.date).format("YYYY-MM-DD")} ${provider.time}`,
        "YYYY-MM-DD h:mm a"
      ).isSameOrAfter(moment())
    );
  };

  const getMenus = (page = 1, radius = 30, signal, sortVal) => {
    let sortValue = sortVal ?? sortBy?.value;

    if (!isFutureDate()) {
      return;
    }

    setTimeout(() => {
      store.userStore.setLoader(true);
    }, 200);

    let payload = {
      radius: isExpandedRadius ? 50 : radius,
      page,
      per_page: 21,
      address: selectedAddress.label,
      search_q: searchText ? searchText : "",
      sort_by: sortValue,
      current_date_time: moment().format("YYYY-MM-DD HH:mm"),
      tags: choosenTags(),
      featured_badges: chosenFeaturedTags(),
    };

    const date = provider.date;

    payload.date = date
      ? moment(date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    payload.time = provider.time;

    return store.userStore
      ?.getLocationMenus(payload, signal)
      .then((response) => {
        store?.userStore.setLoader(false);

        const hasErrorMessage =
          response?.message &&
          !response?.data &&
          response?.message !== "canceled";

        if (hasErrorMessage)
          store.userStore.setErrorAlert(true, { title: response?.message });
      });
  };

  const loadMore = () => {
    getMenus(allMenusPagy?.next);
  };

  const onChangeSortBy = useCallback((value) => {
    store?.userStore.setSort(value);

    getMenus(1, 30, null, value?.value);
  });

  const expandRadius = useCallback(() => {
    setExpandedRadius(true);
  });

  return (
    <div className="w-full px-[44px] pt-[53px]">
      {isGroup && (
        <div className="d-col gap-2 items-center justify-center flex mb-[24px]">
          <div className="text-[#575be8] font-inter-semibold text-base">
            Step 1 of {defaultPaymentMethodId ? 3 : 4}
          </div>
          <div className="text-[#3a3a3a] text-2xl font-inter-semibold">
            Select a restaurant for your group order
          </div>
        </div>
      )}

      <div className="d-row gap-4">
        <div className="h-12 bg-light-gray rounded d-row w-[370px]">
          <img
            src={MapIcon}
            alt="map-icon"
            className="pl-2"
            width={25}
            height={25}
          />

          <input
            type="text"
            value={selectedAddress?.label}
            className="pr-4 pl-2 py-2 border-none bg-light-gray outline-none bg-light-gray rounded-lg w-full"
            disabled
          />
        </div>

        <div className="flex h-12 bg-background rounded">
          <img
            src={CalendarImg}
            alt="calendar-icon"
            className="pl-2 small-icon"
            width={25}
            height={25}
          />

          <DatePicker
            selectedDate={provider.date}
            setSelectedDate={(date) => {
              dispatch({ field: "date", value: date });
              store.userStore.setDate(date);
            }}
            minDate={new Date()}
            format="MMMM d, yyyy"
            placeholderText="Enter delivery date"
            className="flex h-12 bg-light-gray justify-between rounded items-center"
            pickerClassName="font-inter-regular"
            showIcon={false}
            availableDates={provider.availableDates}
            fromModal={true}
            getAvailableDates={getAvailableDates}
            getAvailableTimes={getAvailableTimes}
            iconPadding={false}
          />
        </div>

        <div
          className="flex w-[235px] md:mr-2 h-12 bg-background rounded"
          onClick={getAvailableTimes}
        >
          <TimePickerDropdown
            fromModal
            labelName="address-time-modal"
            isCart={false}
            selectedDate={provider.date}
            selectedTime={provider.time}
            grayBackground={false}
            allTimes={provider.availableTimes}
            className="flex justify-between border-0 w-full"
            backgroundColor={true}
            setSelectedTime={(time) => {
              dispatch({ field: "time", value: time });
              store.userStore.setTime(time);
            }}
          />
        </div>

        <div className="h-12">
          <DeliveryPickupTabs />
        </div>

        <div className="flex items-end justify-end flex-grow">
          <div
            onClick={() => {
              if (cartLength > 0) moveToRestaurantDetails();
            }}
            className={classNames(
              "hidden md:flex items-center justify-center bg-background rounded md:w-32 h-12",
              { "cursor-pointer": cartLength > 0 }
            )}
          >
            <img src={CartBucket} alt="cart-icon-filters" />

            <span className="text-sm text-slate-700 pl-2 font-inter-medium text-md">
              {cartLength} {pluralize("item", cartLength)}
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:p-4 pb-0 md:px-8 bg-white mt-4">
        <div className="flex flex-row w-full">
          <div className="md:flex md:w-2/12">
            <div className="sticky-side-section w-[170px]">
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <span className="text-[20px] font-inter-semibold text-dark-gray">
                    Featured
                  </span>

                  {(isPresent(choosenTags()) ||
                    isPresent(featuredBadges())) && (
                    <button
                      onClick={() => clearAll()}
                      className="flex btn-style font-inter-medium text-sm text-hh-accent-light hover:text-hh-accent-dark"
                    >
                      Clear all
                    </button>
                  )}
                </div>

                <div className="flex flex-col mt-2 mb-4">
                  <FilterOptions
                    filterType="featured"
                    filters={featuredFilters}
                    selectedFilters={featuredFilter}
                    updateChosenRestaurantFilters={
                      updateChosenRestaurantFilters
                    }
                    router={null}
                  />
                </div>

                <div className="flex items-center justify-between">
                  <span className="text-[20px] font-inter-semibold text-dark-gray">
                    Cuisine
                  </span>
                </div>

                <div className="flex flex-col">
                  <FilterOptions
                    filterType="cuisine"
                    filters={cuisineFilters}
                    selectedFilters={cuisineFilter}
                    updateChosenRestaurantFilters={
                      updateChosenRestaurantFilters
                    }
                    router={null}
                  />

                  <button
                    onClick={() => setFiltersModal(true)}
                    className="flex btn-style underline font-inter-medium text-primary-dark mt-2 hover:text-dark-gray"
                  >
                    Show All
                  </button>
                </div>

                <span className="text-[20px] mt-[32px] font-inter-semibold text-dark-gray">
                  Dietary
                </span>

                <div className="flex flex-col">
                  <FilterOptions
                    filterType="dietary"
                    filters={dietaryFilters}
                    selectedFilters={dietaryFilter}
                    updateChosenRestaurantFilters={
                      updateChosenRestaurantFilters
                    }
                    router={null}
                  />
                </div>

                <span className="text-[20px] mt-[32px] font-inter-semibold text-dark-gray">
                  Packaging
                </span>

                <div className="flex flex-col">
                  <FilterOptions
                    filterType="packaging"
                    filters={packagingFilters}
                    selectedFilters={packagingFilter}
                    updateChosenRestaurantFilters={
                      updateChosenRestaurantFilters
                    }
                    router={null}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col flex-wrap w-full">
            <div className="flex flex-col flex-wrap w-full">
              <div className="hidden md:flex flex-row justify-between">
                <Ilustration
                  width={80}
                  height={69}
                  bgImage={Healthy}
                  text={"Healthy"}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
                <Ilustration
                  width={80}
                  height={66}
                  bgImage={SandWich}
                  text={"Sandwiches"}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />

                <div
                  onClick={() =>
                    updateChosenRestaurantFilters(
                      "featured",
                      "Crowd Pleasers",
                      true
                    )
                  }
                  className="flex cursor-pointer cuisineCard justify-center relative  bg-background my-2 rounded-lg"
                >
                  <div className={`crowd`}>
                    <img src={Pleasers} alt="pleasers" />
                  </div>

                  <span
                    className={`text-sm captionText font-inter-medium flex-wrap break-words`}
                    style={{ position: "absolute", bottom: 8 }}
                  >
                    {"Crowd Pleasers"}
                  </span>
                </div>

                <Ilustration
                  width={81}
                  height={66}
                  bgImage={Thai}
                  text={"Thai"}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
                <Ilustration
                  width={80}
                  height={66}
                  bgImage={Mexican}
                  text={"Mexican"}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
                <Ilustration
                  width={80}
                  height={66}
                  bgImage={Sushi}
                  text={"Sushi"}
                  updateChosenRestaurantFilters={updateChosenRestaurantFilters}
                />
              </div>
            </div>

            {/* Desktop */}
            <div className="md:flex md:w-full items-center md:sticky mt-12 bg-white">
              <div className="flex w-full justify-between items-center ml-3">
                <div className="flex items-start">
                  <span className="text-[24px] font-inter-semibold mr-1">
                    {allMenusPagy?.count}{" "}
                    {pluralize("restaurant", allMenusPagy?.count)}
                  </span>
                </div>

                <div className="flex items-end gap-4">
                  <SearchBar
                    handleOnChange={(value) => setSearchText(value)}
                    handleOnBlur={(value) => setSearchText(value)}
                    value={searchText}
                    width="w-[422px]"
                    placeholderValue="Search by restaurant name"
                    onSearch={getMenus}
                  />

                  <SortBySelect sortBy={sortBy} onChange={onChangeSortBy} />
                </div>
              </div>
            </div>

            {allMenus?.length > 0 ? (
              <div className="cards-grid">
                {allMenus.map((menu, index) => (
                  <RestaurantCard
                    currentImageIndex={1}
                    setCurrentImageIndex={() => {}}
                    // loadTagsForMenu={loadTagsForMenu}
                    // setPreviewModal={setPreviewModal}
                    // setMenuDetails={setMenuDetails}
                    key={`card-${index}`}
                    index={index}
                    menu={menu}
                    isGroupMeal={isGroup}
                  />
                ))}
              </div>
            ) : (
              !isLoading && (
                <div className="flex flex-col justify-center items-center mt-20">
                  <img
                    src={NoRestaurants}
                    width={170}
                    height={170}
                    alt="no-restaurants"
                  />

                  <div className="flex flex-col justify-center">
                    <span className="text-sm text-center text-dark-gray mt-3">
                      No restaurants are available to deliver to this address.
                    </span>

                    <button
                      className="mt-3 btn-style"
                      onClick={() => navigate("/meal-management")}
                    >
                      <span className="text-primary-dark">
                        Go Back to Admin Panel
                      </span>
                    </button>
                  </div>
                </div>
              )
            )}

            {allMenusPagy?.next && (
              <div
                className="flex items-center justify-center my-2"
                onClick={loadMore}
              >
                <button className="btn-purple">Load more restaurants</button>
              </div>
            )}

            <FiltersModal
              visible={filtersModal}
              setModal={setFiltersModal}
              cuisineFilter={cuisineFilter}
              featuredFilter={featuredFilter}
              dietaryFilter={dietaryFilter}
              packagingFilter={packagingFilter}
              updateChosenRestaurantFilters={updateChosenRestaurantFilters}
              getMenus={getMenus}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(AddCateringMeal);
