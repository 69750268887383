import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
} from "react";
import { InfoButton } from "assets/img";
import { MobXProviderContext } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import { NotificationBanner } from "components";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { AddressInput } from "components";
import InputError from "components/InputError/InputError";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { validatePostalCodeLength } from "utils/helpers/validation";

function CreateNewAddress() {
  const [requestUpdate, setRequestUpdate] = useState(false);
  const [enablePhoneName, setEnablePhoneName] = useState(false);

  const [mannualAddress, setMannualAddress] = useState(false);

  const [enableSaveNewAddress, setEnableSaveNewAddress] = useState(false);
  const [addressCreated, setAddressCreated] = useState(false);
  const [newAddressAlert, showNewAddressAlert] = useState(false);

  const nameInputRef = useRef(null);
  const phoneInputRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  const deliveryInfoStore = store?.deliveryInfoStore;
  const [enableSaveChanges, setEnableSaveChanges] = useState(true);
  const [showAddressOutsideError, setShowAddressOutsideError] = useState(false);

  let { defaultLocation } = userStore;
  const { primaryAddress } = deliveryInfoStore;

  const initialState = {
    streetNumber: "",
    streetName: "",
    unit: "",
    city: "",
    province: "",
    postalCode: null,
    nickname: "",

    deliveryContactName: "",
    deliveryContactPhone: "",
    deliveryVerification: "",
    deliveryInstructions: "",
    autoCompleteAddress: "",
    addressError: false,
    nicknameError: false,
    streetNameError: false,
    streetNumberError: false,
    cityError: false,
    provinceError: false,
    postalCodeError: false,
    provinceOptions: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      field: "deliveryContactPhone",
      value: primaryAddress?.attributes.delivery_contact_phone,
    });
    dispatch({
      field: "deliveryContactName",
      value: primaryAddress?.attributes.delivery_contact_name,
    });
    dispatch({
      field: "deliveryVerification",
      value: primaryAddress?.attributes.delivery_verification,
    });
  }, []);

  useEffect(() => {
    scrollToUp(0);

    store.userStore.setLoader(true);
    store.deliveryInfoStore
      .getProvinces({ location_id: defaultLocation?.organizationId })
      .then((response) => {
        store.userStore.setLoader(false);

        const provinceOptions = response?.map((province) => {
          return {
            label: province.name,
            value: province.code,
          };
        });

        dispatch({
          field: "provinceOptions",
          value: provinceOptions,
        });
      });
  }, []);

  useEffect(() => {
    if (
      provider?.streetNumber !== "" &&
      provider?.streetName !== "" &&
      provider?.city !== "" &&
      provider?.province !== "" &&
      provider?.postalCode !== "" &&
      provider?.nickname !== ""
    ) {
      setEnableSaveNewAddress(true);
    }
  }, [
    provider?.streetNumber,
    provider?.streetName,
    provider?.city,
    provider?.province,
    provider?.postalCode,
    provider?.nickname,
  ]);

  const scrollToUp = (top) => {
    window.scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };

  const createNewDeliveryAddress = (e) => {
    e.preventDefault();

    if (provider.nickname == "") {
      dispatch({ field: "nicknameError", value: true });
      scrollToUp(100);
      return;
    }

    if (!mannualAddress && provider.autoCompleteAddress == "") {
      dispatch({ field: "addressError", value: true });
      scrollToUp(100);
      return;
    }

    if (
      !(provider.postalCode && validatePostalCodeLength(provider.postalCode))
    ) {
      dispatch({ field: "postalCodeError", value: true });
      scrollToUp(100);
      return;
    }

    if (provider.streetName == "") {
      dispatch({ field: "streetNameError", value: true });
      scrollToUp(100);
      return;
    }

    if (provider.streetNumber == "") {
      dispatch({ field: "streetNumberError", value: true });
      scrollToUp(100);
      return;
    }

    if (provider.city == "") {
      dispatch({ field: "cityError", value: true });
      scrollToUp(100);
      return;
    }

    if (provider.province == "") {
      dispatch({ field: "provinceError", value: true });
      scrollToUp(100);
      return;
    }

    const payload = {
      address: {
        suite: provider.suite,
        street_number: provider.streetNumber,
        street_name: provider.streetName,
        city: provider.city,
        province: provider.province.value,
        postal_code: provider.postalCode,
        nickname: provider.nickname,
        delivery_contact_phone: provider.deliveryContactPhone,
        delivery_contact_name: provider.deliveryContactName,
        delivery_instructions: provider.deliveryInstructions,
        delivery_verification: "photo",
      },
    };

    store.userStore.setLoader(true);
    store.deliveryInfoStore
      .createAddress({ location_id: defaultLocation?.organizationId }, payload)
      .then((response) => {
        if (response?.response?.data?.errors) {
          setShowAddressOutsideError(true);
          store.userStore.setLoader(false);
          scrollToUp(0);
        } else if (!response?.errors) {
          setAddressCreated(true);
          showNewAddressAlert(true);
          store.userStore.setLoader(false);
          scrollToUp(0);
        }
      })
      .catch(() => store.userStore.setLoader(false));
  };

  const handleBackClick = () => {
    if (location?.state?.from == "addMeal") navigate("/meal-management");
    else navigate("/delivery-info");
  };

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      <button
        className="flex items-center cursor-pointer mb-[24px]"
        onClick={handleBackClick}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ width: 14, height: 14 }} />
        <span className="text-md text-left font-inter-medium text-black-light ml-2">
          {location?.state?.from == "addMeal" ? "Back to add a meal" : "Back"}
        </span>
      </button>

      <NotificationBanner
        message="The new address has been successfully added to your account."
        messageType={
          location?.state?.from === "addMeal" ? "warning" : "success"
        }
        textColor={location?.state?.from !== "addMeal" ? "success" : undefined}
        visible={newAddressAlert}
        setVisible={
          location?.state?.from !== "addMeal" ? showNewAddressAlert : undefined
        }
        messageLink={
          location?.state?.from === "addMeal"
            ? "Go back to adding a meal"
            : undefined
        }
        handleLinkClick={
          location?.state?.from === "addMeal"
            ? () => navigate("/meal-management")
            : undefined
        }
      />

      <NotificationBanner
        message="The address could not be saved because it is currently outside our service area. Please try another address or"
        messageType="danger"
        underlineText
        abilityToDismiss={false}
        hideClose={true}
        visible={showAddressOutsideError}
        messageLink="contact us with any questions"
        handleLinkClick={() => {
          store.userStore.setFreshChatOpen(true);
        }}
      />

      <h1 className="text-dark-gray mt-[16px] text-[24px] font-inter-semibold">
        Add new delivery destination
      </h1>

      <form onSubmit={createNewDeliveryAddress}>
        <div className="grid lg:grid-cols-2 md:grid-cols-1 w-full mt-[32px] gap-[21px]">
          <div className="w-full">
            <div className="d-row justify-between">
              <div className="d-col">
                <h2 className="text-dark-gray text-[20px] font-inter-semibold">
                  Delivery address
                </h2>

                <small className="font-inter-regular text-dark-gray text-sm">
                  This is where the food you order will arrive
                </small>
              </div>

              <div
                className="d-col text-[#575be8] font-inter-semibold cursor-pointer flex justify-center"
                onClick={() => setMannualAddress((prev) => !prev)}
              >
                {mannualAddress
                  ? "Search for address"
                  : "Enter address manually"}
              </div>
            </div>

            <div className="bg-light-gray p-[24px]  rounded-xl mt-[24px]">
              <small className="text-dark-gray font-inter-regular text-xs">
                Address Nickname (give this address something easy for you to
                refer to)
              </small>

              <input
                className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                type="text"
                disabled={addressCreated}
                placeholder="eg. Downtown office or Ana’s birthday"
                onChange={(e) => {
                  dispatch({
                    field: "nickname",
                    value: e.target?.value,
                  });

                  dispatch({ field: "nicknameError", value: false });
                }}
                value={provider.nickname}
              />

              <div className="mb-[24px]">
                {provider.nicknameError && (
                  <InputError error="This is required field*" />
                )}
              </div>

              {!mannualAddress && (
                <AddressInput
                  label={"Address"}
                  dispatch={dispatch}
                  setMannualAddress={() => {
                    setMannualAddress(true);
                    dispatch({ field: "addressError", value: false });
                  }}
                  fromDeliveryInfo={true}
                  provider={provider}
                />
              )}

              {provider.addressError && (
                <InputError error="This is required field*" />
              )}

              {mannualAddress && (
                <>
                  <div className="d-row mt-[24px]">
                    <div className="flex flex-col items-start w-1/2 mr-[24px]">
                      <small className="text-dark-gray font-inter-regular text-xs">
                        Street Number
                      </small>

                      <input
                        className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                        placeholder="123"
                        type="number"
                        disabled={addressCreated}
                        onChange={(e) => {
                          dispatch({
                            field: "streetNumber",
                            value: e.target?.value,
                          });

                          dispatch({
                            field: "streetNumberError",
                            value: false,
                          });
                        }}
                        value={provider.streetNumber}
                      />

                      {provider.streetNumberError && (
                        <InputError error="This is required field*" />
                      )}
                    </div>

                    <div className="flex flex-col items-start w-1/2">
                      <small className="text-dark-gray font-inter-regular text-xs">
                        Unit/suite (optional)
                      </small>

                      <input
                        className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                        disabled={addressCreated}
                        onChange={(e) => {
                          dispatch({
                            field: "unit",
                            value: e.target?.value,
                          });
                        }}
                        value={provider.unit}
                      />
                    </div>
                  </div>

                  <div className="d-row mt-[24px]">
                    <div className="flex flex-col items-start w-1/2 mr-[24px]">
                      <small className="text-dark-gray font-inter-regular text-xs">
                        Street name
                      </small>

                      <input
                        className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                        placeholder="John Street"
                        type="text"
                        disabled={addressCreated}
                        onChange={(e) => {
                          dispatch({
                            field: "streetName",
                            value: e.target.value,
                          });

                          dispatch({ field: "streetNameError", value: false });
                        }}
                        value={provider.streetName}
                      />

                      {provider.streetNameError && (
                        <InputError error="This is required field*" />
                      )}
                    </div>

                    <div className="flex flex-col items-start w-1/2">
                      <small className="text-dark-gray font-inter-regular text-xs">
                        City
                      </small>

                      <input
                        className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                        placeholder="Toronto"
                        type="text"
                        disabled={addressCreated}
                        onChange={(e) => {
                          const value = e.target.value;
                          dispatch({
                            field: "city",
                            value: value,
                          });

                          dispatch({ field: "cityError", value: false });
                        }}
                        value={provider.city}
                      />

                      {provider.cityError && (
                        <InputError error="This is required field*" />
                      )}
                    </div>
                  </div>

                  <div className="d-row mt-[24px]">
                    <div className="flex flex-col items-start w-1/2 mr-[24px]">
                      <small className="text-dark-gray font-inter-regular text-xs">
                        Province
                      </small>

                      <Select
                        styles={customStylesSubsidy({ bgWhite: true })}
                        aria-labelledby="sort-by"
                        aria-label="sort-by"
                        placeholder="Select Province"
                        closeMenuOnSelect
                        value={provider.province}
                        onChange={(option) => {
                          dispatch({
                            field: "province",
                            value: option,
                          });

                          dispatch({ field: "provinceError", value: false });
                        }}
                        options={provider.provinceOptions}
                        className="w-full text-[#566985] font-inter-medium mt-[4px]"
                      />

                      {provider.provinceError && (
                        <InputError error="This is required field*" />
                      )}
                    </div>

                    <div className="flex flex-col items-start w-1/2">
                      <small className="text-dark-gray font-inter-regular text-xs">
                        Postal Code
                      </small>

                      <input
                        className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                        placeholder="M6K O90"
                        disabled={addressCreated}
                        onChange={(e) => {
                          dispatch({
                            field: "postalCode",
                            value: e.target?.value,
                          });

                          dispatch({
                            field: "postalCodeError",
                            value: false,
                          });
                        }}
                        value={provider.postalCode}
                      />

                      {provider.postalCodeError && (
                        <InputError error="Invalid postal code" />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="d-col mt-[24px]">
              <h2 className="text-dark-gray text-[20px] font-inter-semibold">
                Additional delivery instuctions (optional)
              </h2>

              <small className="font-inter-regular text-dark-gray text-sm">
                Please provide any specific instructions for our drivers, such
                as indicating loading docks, parking spots, or building
                entrances to ensure prompt delivery of your food{" "}
              </small>
            </div>

            <div className="bg-light-gray p-[24px]  rounded-xl mt-[24px]">
              <div className="mt-[4px]">
                <textarea
                  type="text"
                  disabled={addressCreated}
                  value={provider.deliveryInstructions}
                  className={classNames(
                    "flex w-full text-dark-gray focus:outline-none rounded-l-md text-md py-[9px] p-[19px]",
                    addressCreated ? "bg-gray" : "bg-white"
                  )}
                  placeholder="eg. Park in the back entrance, leave on kitchen table, call us when you arrive "
                  onChange={(e) =>
                    dispatch({
                      field: "deliveryInstructions",
                      value: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="d-col">
              <h2 className="text-dark-gray text-[20px] font-inter-semibold">
                Point of contact for address
              </h2>

              {!enablePhoneName && (
                <small className="font-inter-regular text-dark-gray text-sm">
                  By default, the primary point of contact will be attached to
                  all deliveries
                </small>
              )}
            </div>

            {enablePhoneName && (
              <div className="d-row items-start bg-background mt-[24px] py-[12px] px-[16px] rounded-lg">
                <img
                  layout="fixed"
                  className="object-contain mt-1 mr-[10px]"
                  width={16}
                  height={16}
                  alt="info-button-checkou"
                  src={InfoButton}
                />

                <span className="font-inter-regular text-dark-gray text-[16px]">
                  The primary point of contact for this account will exclusively
                  receive SMS tracking updates. The delivery contact for this
                  address will be contacted in regards to deliveries to this
                  address if needed.
                </span>
              </div>
            )}

            <div className="bg-background d-col p-[24px] mt-[24px] rounded-lg">
              {!addressCreated && (
                <button
                  onClick={() => {
                    setEnablePhoneName(true);
                    nameInputRef?.current?.focus();
                  }}
                  disabled={enablePhoneName || requestUpdate}
                  className={classNames({
                    "d-row items-center text-catering self-end": true,
                    "opacity-25": enablePhoneName,
                  })}
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    size="1x"
                    className="small-icon hover:text-gray-600 mr-2"
                  />
                  Edit
                </button>
              )}

              <div className="mt-[24px]">
                <label
                  htmlFor="name"
                  className="block text-sm font-inter-regular text-dark-gray"
                >
                  Name*
                </label>

                <input
                  type="text"
                  disabled={!enablePhoneName}
                  name="name"
                  ref={nameInputRef}
                  id="name"
                  value={provider.deliveryContactName}
                  onChange={(e) => {
                    dispatch({
                      field: "deliveryContactName",
                      value: e.target.value,
                    });

                    setEnableSaveChanges(false);
                  }}
                  className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                />
              </div>

              <div className="mt-[24px]">
                <label
                  htmlFor="phone"
                  className="block text-sm  font-inter-regular text-dark-gray leading-6"
                >
                  Phone number*
                </label>

                <div className="mt-[4px] bg-background d-row rounded-md">
                  <PhoneInput
                    required
                    id="phone"
                    defaultCountry="CA"
                    disabled={!enablePhoneName}
                    value={provider.deliveryContactPhone}
                    ref={phoneInputRef}
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    placeholder="Phone Number"
                    onChange={(phone) => {
                      isValidPhoneNumber(phone) &&
                        dispatch({
                          field: "deliveryContactPhone",
                          value: phone,
                        });

                      setEnableSaveChanges(false);
                    }}
                  />
                </div>
              </div>
            </div>

            {enablePhoneName && (
              <div className="d-row justify-end items-center mt-[24px]">
                <button
                  className="btn-outline rounded-xl mr-[16px] h-[48px]"
                  onClick={() => {
                    setEnableSaveChanges(true);
                    setEnablePhoneName(false);
                  }}
                >
                  Cancel
                </button>

                <button
                  className={classNames({
                    "btn-blue-accent rounded-xl": true,
                    "btn-disabled": enableSaveChanges,
                  })}
                  onClick={() => {
                    setEnableSaveNewAddress(true);
                    setEnablePhoneName(false);
                  }}
                  disabled={enableSaveChanges}
                >
                  Save Changes
                </button>
              </div>
            )}

            <h2 className="text-dark-gray text-[20px] mb-[24px] mt-[32px] font-inter-semibold">
              Delivery verification
            </h2>

            <div className="py-[13px] px-[24px] bg-light-gray rounded-lg">
              <div className="d-row items-start">
                <img
                  layout="fixed"
                  className="object-contain mt-1 mr-[10px]"
                  width={16}
                  height={16}
                  alt="info-button-checkou"
                  src={InfoButton}
                />

                <span className="font-inter-regular text-dark-gray text-[16px]">
                  <strong className="font-inter-semibold text-dark-gray text-[16px]">
                    Delivery is verified with photos submitted by drivers or
                    with the driver asking for your signature{" "}
                  </strong>
                  {<br />}
                  To switch verification method, please contact hungerhub
                </span>
              </div>

              <div className="bg-light-gray px-[26px] d-row rounded-lg my-[8px]">
                <div key="photo" className="flex items-center">
                  <input
                    name="notification-method"
                    type="radio"
                    disabled
                    // checked={provider?.deliveryVerification === "photo"}
                    checked={true}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="photo"
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                  >
                    Photo
                  </label>
                </div>

                <div key={"signature"} className="flex items-center pl-[24px]">
                  <input
                    name="notification-method"
                    type="radio"
                    disabled
                    checked={provider?.deliveryVerification === "signature"}
                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="signature"
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                  >
                    Signature
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!addressCreated && (
          <div className="mt-[68px] d-col">
            <button
              className={classNames({
                "btn-blue-accent rounded-xl d-row self-end": true,
                "btn-disabled": !enableSaveNewAddress,
              })}
              type="submit"
              disabled={!enableSaveNewAddress}
            >
              Save new address
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default observer(CreateNewAddress);
