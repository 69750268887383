import { toJS } from "mobx";
import { MobXProviderContext, observer } from "mobx-react";
import moment from "moment-timezone";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import AddPaymentCard from "components/AddPaymentCard";

import {
  CalendarIcon,
  FileInputIcon,
  GreyCalendarIcon,
  GreyClockIcon,
  MasterCardIcon,
  RadioActive,
  RadioInActive,
  VisaCardIcon,
  InfoButton,
} from "assets/img";
import { useParams } from "react-router-dom";

import {
  priceFormatter,
  validateEmail,
  preventMinus,
  preventPasteNegative,
  validatePostalCodeLength,
} from "utils/helpers/validation";
import Tooltip from "components/Tooltip";
import classNames from "classnames";
import ConfirmationModal from "components/ConfirmationModal";
import EmbedHTML from "components/shared/embedHTML";
import {
  timeFormat,
  getOperatingHoursOptionsForDropdown,
} from "utils/helpers/dateTime";

import InputError from "components/InputError/InputError";
import GroupOrderCheckoutItem from "components/GroupOrderCheckoutItem";
import TipButton from "components/TipButton";
import { TIP_VALUES_DESKTOP, TIP_VALUES_MOBILE } from "utils/constants/common";
import { getByPassLocationCheckText } from "utils/helpers/string";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { stripePromise } from "utils/helpers/stripe";
import { useNavigate } from "react-router-dom";

const notificationMethods = [
  { id: "no", title: "No, only use these instructions for this order" },
  {
    id: "yes",
    title: "Yes, change for this and all future orders with this address",
  },
];

const GroupOrderCheckout = () => {
  const initialState = {
    comment: "",
    firstName: "",
    lastName: "",
    billAs: "company",
    company: "",
    phone: "",
    email: "",
    tip: "10",
    otherTip: "",
    customTip: 0,
    isInvalidAddress: false,
    authModal: false,
    phoneError: "",
    taxAmount: 0,
    internalAddress: null,
    deliveryDate: null,
    deliveryTime: null,
    deliveryDateError: false,
    deliveryTimeError: false,
    deliveryFee: 0,
    currentGroupOrder: null,
    temporaryCard: null,
    showTemporaryCard: false,
    temporaryCardSelected: false,
    postalCode: "",
    deliveryInstructionsLevel: "no",
    paidByInvoice: false,
    defaultCard: "",
    confirmEmail: "",
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [showPostalCodeError, setShowPostalCodeError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);

  const [provider, dispatch] = useReducer(reducer, initialState);
  const [addressValidationAlert, setAddressValidationAlert] = useState(null);
  const [activeRestaurant, setActiveRestaurant] = useState(null);
  const [timePickerOptions, setTimePickerOptions] = useState([]);
  const [bypassPlaceOrder, setBypassPlaceOrder] = useState(null);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const paymentInfoStore = toJS(store?.paymentInfoStore);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const mealManagementStore = toJS(store?.mealManagementStore);
  const { locationID, defaultLocation } = userStore;
  const locationId = locationID;
  const isAdmin = true;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let invoiceExist = searchParams.get("pay_with_invoice");

  let {
    stripeCreditCards,
    defaultPaymentMethodId,
    defaultSelectedCard,
    cardElement,
    newCard,
  } = paymentInfoStore;

  const { groupOrder } = mealManagementStore;

  //   const router = useRouter();
  let { address, currentUser, addressTimezone } = userStore;
  const userLogin = true;
  //   let { cart } = cartStore;
  let cart = null;
  //   let { currentGroupOrder, cutlery } = groupOrderStore;

  let cutlery = null;

  let completeAddress = toJS(store.userStore.completeAddress);
  const { id } = useParams();

  let {
    tip,
    customTip,
    otherTip,
    firstName,
    lastName,
    phone,
    email,
    company,
    comment,
    billAs,
    isInvalidAddress,
    phoneError,
    taxAmount,
    internalAddress,
    deliveryDate,
    deliveryTime,
    deliveryDateError,
    deliveryTimeError,
    deliveryFee,
    currentGroupOrder,
    temporaryCard,
    showTemporaryCard,
    temporaryCardSelected,
    postalCode,
    deliveryInstructionsLevel,
    paidByInvoice,
    defaultCard,
    confirmEmail,
  } = provider;

  let tipAmount,
    grandTotalAmount = 0;

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["groupOrder", locationID, id],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.mealManagementStore.fetchGroupOrderOnly(id, locationID);
    },
  });

  useEffect(() => {
    dispatch({ field: "currentGroupOrder", value: data });

    if (currentGroupOrder?.attributes?.status == "placed") {
      navigate(
        `/group-order/admin/orders/${currentGroupOrder?.id}/${locationID}`
      );
    }
  }, [data]);

  useEffect(() => {
    setPostalCodeError(!(postalCode && validatePostalCodeLength(postalCode)));
  }, [postalCode]);

  useEffect(() => {
    dispatch({ field: "defaultCard", value: defaultPaymentMethodId });
  }, [defaultPaymentMethodId]);

  useEffect(() => {
    if (!store.userStore.isHydrated) return;

    if (currentGroupOrder?.attributes) {
      const restaurant = {
        attributes: {
          accept_order_hours_in_advance:
            currentGroupOrder?.attributes?.accept_order_hours_in_advance,
          special_hours: currentGroupOrder?.attributes?.special_hours,
          operating_hours: currentGroupOrder?.attributes?.operating_hours,
          tzinfo: currentGroupOrder?.attributes?.tzinfo,
        },
      };

      setActiveRestaurant(restaurant);

      setTimePickerOptions(
        getOperatingHoursOptionsForDropdown(
          restaurant,
          deliveryDate,
          addressTimezone
        )
      );
    }
  }, [store.userStore.isHydrated, deliveryDate]);

  useEffect(() => {
    updateTipAmount();
  }, [tip]);

  const updateTipAmount = () => {
    const tipValue =
      provider?.tip == "Other" ? provider?.otherTip : provider?.tip;
    let payload = {
      meal_series: {
        meals_attributes: [
          {
            tip: tipValue,
            id: currentGroupOrder?.attributes?.meals[0]?.attributes?.id,
          },
        ],
      },
    };

    let params = {
      location_id: currentGroupOrder?.attributes?.location_id,
    };

    if (currentGroupOrder && tipValue) {
      store.userStore.setLoader(true);
      store?.mealManagementStore
        ?.updateGroupOrder(currentGroupOrder?.id, payload, params)
        .then(() => {
          store.userStore.setLoader(false);
          refetch();
        });
    }
  };

  useEffect(() => {
    if (!store.userStore.isHydrated) return;

    if (currentGroupOrder?.attributes) {
      dispatch({
        field: "deliveryDate",
        value: currentGroupOrder?.attributes?.order_date,
      });

      dispatch({
        field: "deliveryTime",
        value: moment(
          currentGroupOrder?.attributes?.delivery_time,
          "hh:mm A"
        ).format(timeFormat),
      });
    }
  }, [store.userStore.isHydrated]);

  useEffect(() => {
    if (isAdmin && locationID) {
      store.paymentInfoStore.getLocationCreditCards({
        location_id: locationID,
      });
    }
  }, []);

  useEffect(() => {
    if (cart?.firstName && cart?.lastName && cart?.phone && cart?.email) {
      dispatch({ field: "firstName", value: cart?.firstName });
      dispatch({ field: "lastName", value: cart?.lastName });
      dispatch({ field: "phone", value: cart?.phone });
      dispatch({ field: "email", value: cart?.email });

      if (cart?.billAs == "company") {
        dispatch({ field: "billAs", value: cart?.billAs });
        dispatch({ field: "company", value: cart?.company });
      }

      if (cart?.billAs && cart?.company) {
        dispatch({ field: "billAs", value: cart?.billAs });
        dispatch({ field: "company", value: cart?.company });
      }
    }
  }, [cart?.firstName, cart?.lastName, cart?.phone, cart?.email]);

  useEffect(() => {
    dispatch({
      field: "internalAddress",
      value: {
        suite: cart?.suite,
        streetNumber: cart?.streetNumber,
        streetName: cart?.streetName,
        city: cart?.city,
        province: cart?.province,
        postalCode: cart?.postalCode,
      },
    });
  }, [
    cart?.suite,
    cart?.streetNumber,
    cart?.streetName,
    cart?.city,
    cart?.province,
    cart?.postalCode,
  ]);

  useEffect(() => {
    if (currentUser) {
      dispatch({
        field: "firstName",
        value: currentUser?.attributes?.first_name,
      });
      dispatch({
        field: "lastName",
        value: currentUser?.attributes?.last_name,
      });
      dispatch({ field: "phone", value: currentUser?.attributes?.phone });
      dispatch({ field: "email", value: currentUser?.attributes?.email });

      if (currentUser?.attributes?.billable_as == "company") {
        dispatch({ field: "billAs", value: "company" });
        dispatch({
          field: "company",
          value: currentUser?.attributes?.company_name,
        });
      }
    }
  }, [currentUser !== null]);

  useEffect(() => {
    dispatch({
      field: "comment",
      value:
        currentGroupOrder?.attributes?.address?.attributes
          ?.delivery_instructions,
    });
  }, [currentGroupOrder]);

  const getAddress = () => {
    return `${internalAddress?.suite ? `${internalAddress?.suite} - ` : ""}${
      internalAddress?.streetNumber ?? ""
    } ${internalAddress?.streetName ?? ""} ${internalAddress?.city ?? ""}, ${
      internalAddress?.province ?? ""
    }, ${internalAddress?.postalCode ?? ""}`;
  };

  useEffect(() => {
    if (!store?.userStore?.isHydrated) return;

    let addressPayload = address ? address : getAddress();

    if (
      addressPayload.replace(/[ ,]/g, "") &&
      currentGroupOrder?.attributes?.restaurant_id
    ) {
      store.userStore?.setLoader(true);
      store.cartStore
        ?.getDeliveryFee(currentGroupOrder?.attributes?.restaurant_id, {
          order_amount: currentGroupOrder?.attributes?.grand_total,
          order_subtotal: currentGroupOrder?.attributes?.subtotal,
          order_address: addressPayload,
          order_id: currentGroupOrder?.id,
          order_type: "group_order",
        })
        .then((response) => {
          if (!response?.valid) {
            dispatch({
              field: "isInvalidAddress",
              value: true,
            });
            if (
              response?.message == "Address out of range." ||
              response?.message == "Address not found."
            )
              setAddressValidationAlert(true);
            else
              store.userStore?.setErrorAlert(true, {
                title: response?.message,
              });
          } else {
            dispatch({
              field: "isInvalidAddress",
              value: false,
            });

            dispatch({
              field: "taxAmount",
              value: response?.tax_amount,
            });

            dispatch({
              field: "deliveryFee",
              value: response?.message || 0,
            });
          }

          store.userStore?.setLoader(false);
        });
    }
  }, [
    address,
    internalAddress?.suite,
    internalAddress?.streetName,
    internalAddress?.streetNumber,
    internalAddress?.postalCode,
    internalAddress?.city,
    internalAddress?.province,
    store?.userStore?.isHydrated,
  ]);

  tipAmount = useMemo(() => {
    if (tip !== "Other")
      return (tip / 100) * currentGroupOrder?.attributes?.subtotal;
    else return customTip || 0;
  }, [currentGroupOrder?.attributes?.subtotal, tip, customTip]);

  grandTotalAmount = useMemo(() => {
    return parseFloat(
      parseFloat(
        currentGroupOrder?.attributes?.meals[0]?.attributes?.subtotal
      ) +
        parseFloat(taxAmount) +
        parseFloat(tipAmount) +
        parseFloat(currentGroupOrder?.attributes?.service_fee) +
        parseFloat(deliveryFee)
    ).toFixed(2);
  }, [
    currentGroupOrder?.attributes?.subtotal,
    deliveryFee,
    taxAmount,
    tipAmount,
  ]);

  useEffect(() => {
    let params = {
      location_id: locationId,
    };

    let payload = {
      meal_series: {
        service: "grouped",
      },
    };

    if (currentGroupOrder) {
      if (provider.deliveryInstructionsLevel == "yes") {
        params.delivery_instructions = provider.comment;
      } else if (provider.deliveryInstructionsLevel == "no") {
        payload.meal_series.delivery_instructions = provider.comment;
      }
      store.userStore.setLoader(true);
      store?.mealManagementStore
        ?.updateGroupOrder(currentGroupOrder?.id, payload, params)
        .then(() => {
          store.userStore.setLoader(false);
        });
    }
  }, [provider.deliveryInstructionsLevel]);

  const handleDefaultStripeCard = (paymentID) => {
    store.paymentInfoStore.updateDefaultLocationCreditCards(paymentID, {
      location_id: locationId,
      default: true,
    });
  };

  const createCheckOutSession = async () => {
    const stripe = await stripePromise;

    const origin =
      typeof window !== "undefined" && window.location.origin
        ? window.location.origin
        : "";

    let payload = {
      email,
      group_order_id: currentGroupOrder?.id,
      success_url: `${origin}/group-order/admin/orders/${currentGroupOrder?.attributes?.slug}`,
      cancel_url: `${origin}/group-order/checkout/${currentGroupOrder?.attributes?.slug}`,
      restaurant_name: currentGroupOrder?.attributes?.restaurant_name,
    };

    store.groupOrderStore
      ?.createGroupOrderStripeSession(payload)
      .then((response) => {
        store.userStore?.setLoader(false);

        const result = null;
        if (response?.id) {
          store.groupOrderStore.setCurrentGroupOrder(null);

          result = stripe.redirectToCheckout({
            sessionId: response.id,
          });
        }
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const isAbleToProceedToPayment = () => {
    const isValidPhone = phone && isValidPhoneNumber(provider.phone);
    const isValidEmail = email && validateEmail(email);
    const userInfoAvailable =
      firstName && lastName && isValidPhone && isValidEmail;

    return billAs == "company"
      ? company && userInfoAvailable
      : userInfoAvailable;
  };

  const handleTemporaryCard = (card) => {
    dispatch({ field: "temporaryCard", value: card });
    dispatch({ field: "showTemporaryCard", value: true });
    dispatch({ field: "temporaryCardSelected", value: true });
  };

  const scrollToPostalCodeInput = () => {
    const inputElement = document.getElementById("postalCode");
    if (inputElement) {
      const yOffset = -60;
      const y =
        inputElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const proceedToPayment = async (e) => {
    e.preventDefault();

    const isValidPhone = phone && isValidPhoneNumber(provider.phone);
    const isValidEmail = email && validateEmail(email);

    const stripe = await stripePromise;

    if (newCard) {
      store.paymentInfoStore
        .createLocationPaymentIntent({
          location_id: locationID,
          group_order_id: currentGroupOrder?.id,
          amount:
            currentGroupOrder?.attributes?.meals[0]?.attributes?.grand_total,
        })
        .then((response) => {
          store.userStore?.setLoader(false);
          const intentId = response?.id;
          const client_secret = response?.client_secret;

          const { error, paymentIntent } = stripe
            .confirmCardPayment(client_secret, {
              payment_method: {
                card: cardElement,
              },
            })
            .then(function (result) {
              if (result.error) {
                console.error("Error:", result.error.message);
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  let params = {
                    location_id: locationId,
                    stripe_payment_intent_id: intentId,
                  };

                  store.userStore.setLoader(true);
                  store?.mealManagementStore
                    ?.groupOrderCheckout(currentGroupOrder?.id, params)
                    .then((response) => {
                      if (response?.data?.attributes?.status == "placed") {
                        navigate(
                          `/group-order/order-confirmation/${currentGroupOrder?.id}`
                        );
                      }
                      store.userStore.setLoader(false);
                    });
                }
              }
            });
        })
        .catch(() => store.userStore.setLoader(false));
    } else if (invoiceExist) {
      let params = {
        location_id: locationId,
        paid_by_invoice: true,
      };

      store.userStore.setLoader(true);
      store?.mealManagementStore
        ?.groupOrderCheckout(currentGroupOrder?.id, params)
        .then((response) => {
          store.userStore.setLoader(false);
          if (response?.data?.attributes?.status == "placed") {
            navigate(
              `/group-order/order-confirmation/${currentGroupOrder?.id}`
            );
          }
        });
    } else {
      let params = {
        location_id: locationId,
        stripe_card_id: defaultPaymentMethodId,
      };

      store.userStore.setLoader(true);
      store?.mealManagementStore
        ?.groupOrderCheckout(currentGroupOrder?.id, params)
        .then((response) => {
          if (response?.data?.attributes?.status == "placed") {
            navigate(
              `/group-order/order-confirmation/${currentGroupOrder?.id}`
            );
          }
          store.userStore.setLoader(false);
        });
    }

    // sendProceedPaymentRequest();
  };

  const sendProceedPaymentRequest = (bypassDifferentAddress = false) => {
    let employeePayload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      suite: completeAddress.suite,
      street_name: completeAddress.street_name,
      street_number: completeAddress.street_number,
      city: completeAddress.city,
      province: completeAddress.province,
      postal_code: completeAddress.postal_code,
      zone_id: currentGroupOrder.attributes?.zone_id,
      delivery_date: moment(deliveryDate).format("YYYY-MM-DD"),
      target_time: deliveryTime,
      delivery_instructions: comment,
      menu_id: currentGroupOrder?.attributes?.menu_id,
      source_of_business: "hungerhub",
      billable_as: provider?.billAs,
      group_order_id: currentGroupOrder?.attributes?.id,
    };

    if (provider?.billAs == "company")
      employeePayload.company_name = provider.company;

    if (bypassDifferentAddress) {
      employeePayload.override_location_check = true;
    }

    store.userStore?.setLoader(true);
    store.groupOrderStore
      ?.createGroupOrderEmployee(employeePayload)
      .then((response) => {
        if (response?.employee_id && response?.shipment_id) {
          createCheckOutSession();
        } else if (
          !response?.success &&
          response?.address_fields &&
          !bypassDifferentAddress
        ) {
          store.userStore?.setLoader(false);
          const address = response?.address_fields;
          setBypassPlaceOrder(getByPassLocationCheckText(address));
        } else store.userStore?.setLoader(false);
      })
      .catch(() => store.userStore?.setLoader(false));
  };

  const onClickLeftButtonInAlert = () => {
    setAddressValidationAlert(null);
    // router.push("/");
  };

  const onClickRightButtonInAlert = () => {
    store?.userStore?.setAddress("");

    setAddressValidationAlert(null);
  };

  const getOrderPlacedAttendees = (attendees) => {
    return attendees?.filter(
      (attendee) => attendee?.attributes?.status == "placed"
    );
  };

  const getTotalQuantity = (items) => {
    let quantity = 0;
    items.forEach((item) => {
      quantity += item?.attributes?.quantity;
    });

    return quantity;
  };

  const updateCutleryRequired = (required) => {
    store.userStore.setLoader(true);

    let payload = {
      meal_series: {
        meals_attributes: [
          {
            cutlery_required: required,
            id: currentGroupOrder?.attributes?.meals[0]?.attributes?.id,
          },
        ],
      },
    };

    let params = {
      location_id: currentGroupOrder?.attributes?.location_id,
    };

    store?.mealManagementStore
      ?.updateGroupOrder(currentGroupOrder?.id, payload, params)
      .then(() => {
        store.userStore.setLoader(false);

        refetch();
      });
  };

  return (
    <>
      <div
        className="flex border-t-2 justify-center border-border-gray bg-background min-h-screen md:pt-8 w-full"
        // style={{ maxWidth: "1440px" }}
      >
        {currentGroupOrder?.attributes?.meals[0]?.attributes
          ?.uncheckedout_orders?.length > 0 ? (
          <div
            className="flex flex-col w-full mx-auto my-5 px-5"
            style={{ maxWidth: "1200px" }}
          >
            <div className="flex items-center">
              <span className="font-inter-medium text-[24px] md:text-[32px] mr-3">
                Order Details
              </span>
            </div>

            <div className="flex flex-col md:flex-row items-start mt-7">
              <div className="flex flex-col p-5 bg-white rounded-3xl w-full md:w-3/5 md:mr-8 static md:sticky md:top-0">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mt-1">
                  <div className="flex flex-col">
                    <span className="font-inter-medium text-md2 text-black-light">
                      {
                        currentGroupOrder?.attributes?.meals[0]?.attributes
                          ?.restaurant_name
                      }
                    </span>

                    <span className="text-md text-secondary-text">
                      Total items:{" "}
                      {getOrderPlacedAttendees(
                        currentGroupOrder?.attributes?.meals[0]?.attributes
                          ?.uncheckedout_orders
                      )?.reduce((acc, attendee) => {
                        return acc + attendee?.attributes?.order_items?.length;
                      }, 0)}
                    </span>
                  </div>
                </div>

                <div className="bg-background p-2 rounded-md mt-4">
                  <label className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer">
                    <span className="ml-1 font-inter-medium text-sm w-44">
                      Request cutlery
                    </span>

                    <input
                      autoComplete="off"
                      type="checkbox"
                      className="form-checkbox"
                      onChange={(e) => updateCutleryRequired(e.target.checked)}
                      checked={
                        currentGroupOrder?.attributes?.meals[0]?.attributes
                          ?.cutlery_required
                      }
                    />
                  </label>
                </div>

                <div className="mt-4">
                  {getOrderPlacedAttendees(
                    currentGroupOrder?.attributes?.meals[0]?.attributes
                      ?.uncheckedout_orders
                  )?.map((attendee, index) => (
                    <div key={`${index}-cartItem`}>
                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                        <span className="text-md font-inter-medium text-primary-black">
                          <EmbedHTML
                            text={`${attendee?.attributes?.full_name}`}
                            className="text-primary-black"
                          />
                        </span>
                        <span className="text-sm">
                          Total Qty:{" "}
                          {getTotalQuantity(attendee?.attributes?.order_items)}
                        </span>
                      </div>

                      {attendee?.attributes?.order_items?.map((orderItem) => (
                        <div className="px-3">
                          <GroupOrderCheckoutItem
                            orderItem={orderItem}
                            key={orderItem.id}
                            moreThanOneItemInCategory={
                              attendee?.attributes?.order_items?.length > 1
                            }
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>

              <form onSubmit={proceedToPayment} autoComplete="off">
                <div className="flex flex-col p-5 bg-white rounded-3xl">
                  <span className="text-[20px] font-inter-semibold">
                    Delivery Details
                  </span>

                  {currentUser && isAdmin && (
                    <div className="flex flex-col w-full mt-4">
                      <span className="text-sm mb-1">Delivery address</span>
                      <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                        {
                          currentGroupOrder.attributes?.address?.attributes
                            ?.complete_address
                        }
                      </div>
                    </div>
                  )}

                  {(!currentUser || !isAdmin) && (
                    <>
                      <div className="flex flex-col sm:flex-row w-full justify-between mt-5">
                        <div className="flex flex-col w-full sm:w-1/2">
                          <span className="text-sm mb-1">Street number</span>
                          <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2">
                            {completeAddress?.street_number}
                          </div>
                        </div>

                        <div className="flex flex-col w-full sm:w-1/2 sm:ml-3">
                          <label className="font-inter-normal text-sm">
                            Unit/suite (optional)
                          </label>
                          <input
                            name={"suite"}
                            className="input-gray-bg-border text-sm bg-white mt-1"
                            value={provider.suite}
                            onChange={(e) =>
                              dispatch({
                                field: "suite",
                                value: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-3 gap-1">
                        <label className="font-inter-normal text-sm">
                          Street name
                        </label>
                        <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2 mt-1">
                          {completeAddress?.street_name}
                        </div>
                      </div>

                      <div className="flex flex-col sm:flex-row w-full justify-between mt-5">
                        <div className="flex flex-col w-full sm:w-1/2">
                          <span className="text-sm mb-1">City</span>
                          <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2">
                            {completeAddress?.city}
                          </div>
                        </div>

                        <div className="flex flex-col w-full sm:w-1/2 sm:ml-3">
                          <span className="text-sm mb-1">Province</span>
                          <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2">
                            {completeAddress?.province}
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 gap-1">
                        <label className="font-inter-normal text-sm">
                          Postal Code
                        </label>
                        <input
                          id="postalCode"
                          name={"postalCode"}
                          className={classNames(
                            "input-gray-bg-border text-sm bg-white mt-1",
                            {
                              "border-attention":
                                postalCodeError && showPostalCodeError,
                            }
                          )}
                          value={postalCode}
                          onChange={(e) =>
                            dispatch({
                              field: "postalCode",
                              value: e.target.value,
                            })
                          }
                        />

                        {showPostalCodeError && postalCodeError && (
                          <InputError error="Please enter your postal code" />
                        )}
                      </div>
                    </>
                  )}

                  <div className="flex flex-col sm:flex-row w-full justify-between mt-5">
                    <div className="flex flex-col w-full sm:w-1/2">
                      <span className="text-sm mb-1">Delivery date</span>
                      <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2">
                        <img src={GreyCalendarIcon} alt="calendar-icon" />
                        {/* {moment(cart?.cartDate).format("MMMM Do, YYYY")} */}
                        {moment(
                          `${currentGroupOrder?.attributes?.meals[0]?.attributes?.meal_date} ${currentGroupOrder?.attributes?.meals[0]?.attributes?.target_time}`,
                          "YYYY-MM-DD"
                        ).format("MMMM DD, YYYY")}
                      </div>
                    </div>

                    <div className="flex flex-col w-full sm:w-1/2 sm:ml-3">
                      <span className="text-sm mb-1">Time</span>
                      <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex gap-2">
                        <img src={GreyClockIcon} alt="calendar-icon" />

                        {moment(
                          `${currentGroupOrder?.attributes?.meals[0]?.attributes?.meal_date} ${currentGroupOrder?.attributes?.meals[0]?.attributes?.target_time}`,
                          "hh:mm A"
                        ).format("hh:mm a")}
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mt-5">
                    <span className="text-sm mb-1">
                      Delivery instructions (optional)
                    </span>

                    <input
                      className="input-gray-bg-border text-sm bg-white"
                      placeholder="Ex. Leave at door"
                      value={provider.comment}
                      onChange={(e) =>
                        dispatch({ field: "comment", value: e.target.value })
                      }
                    />
                  </div>

                  {provider.comment !==
                    currentGroupOrder?.attributes?.address?.attributes
                      ?.delivery_instructions &&
                    isAdmin && (
                      <fieldset>
                        <p className="text-md font-inter-semibold mt-[16px]">
                          Should we apply these instructions to all deliveries
                          with this address?
                        </p>

                        <div className="mt-[12px] space-y-2">
                          {notificationMethods.map((notificationMethod) => (
                            <div
                              key={notificationMethod.id}
                              className="flex items-center"
                            >
                              <div
                                className="flex cursor-pointer"
                                onClick={(e) => {
                                  dispatch({
                                    field: "deliveryInstructionsLevel",
                                    value: notificationMethod.id,
                                  });
                                }}
                              >
                                <img
                                  alt="radio-button"
                                  src={
                                    deliveryInstructionsLevel ==
                                    notificationMethod.id
                                      ? RadioActive
                                      : RadioInActive
                                  }
                                />
                                <div className="d-row items-center ml-3">
                                  {notificationMethod.title}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    )}
                </div>

                {!currentUser || !isAdmin ? (
                  <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl">
                    <span className="text-[20px] font-inter-medium">
                      Billing Info
                    </span>

                    <div className="flex flex-col min-[560px]:flex-row flex-wrap mt-4">
                      <div
                        className="flex cursor-pointer"
                        onClick={() =>
                          dispatch({ field: "billAs", value: "company" })
                        }
                      >
                        <img
                          alt="radio-button"
                          src={
                            provider?.billAs === "company"
                              ? RadioActive
                              : RadioInActive
                          }
                        />

                        <span className="text-md ml-2">Bill as a company</span>
                      </div>

                      <div
                        className="flex mt-3 min-[560px]:mt-0 min-[560px]:ml-4 cursor-pointer"
                        onClick={() =>
                          dispatch({ field: "billAs", value: "individual" })
                        }
                      >
                        <img
                          alt="radio-button-1"
                          src={
                            provider?.billAs === "individual"
                              ? RadioActive
                              : RadioInActive
                          }
                        />

                        <span className="text-md ml-2">
                          Bill as an individual
                        </span>
                      </div>
                    </div>

                    <div className="mt-3 gap-1">
                      <label className="font-inter-normal text-sm">
                        First name{" "}
                      </label>
                      <input
                        required
                        disabled={currentUser && !isAdmin && firstName}
                        name="firstNameField"
                        className="input-gray-bg-border text-sm bg-white"
                        value={firstName}
                        onChange={(e) =>
                          dispatch({
                            field: "firstName",
                            value: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="mt-3 gap-1">
                      <label className="font-inter-normal text-sm">
                        Last name{" "}
                      </label>
                      <input
                        disabled={currentUser && !isAdmin && lastName}
                        required
                        name={"lastNameField"}
                        className="input-gray-bg-border text-sm bg-white"
                        value={lastName}
                        onChange={(e) =>
                          dispatch({ field: "lastName", value: e.target.value })
                        }
                      />
                    </div>

                    {provider?.billAs == "company" && (
                      <div className="mt-3 gap-1">
                        <label className="font-inter-normal text-sm">
                          Company name{" "}
                        </label>
                        <input
                          required
                          disabled={
                            currentUser &&
                            !isAdmin &&
                            currentUser?.attributes?.company
                          }
                          name={"companyField"}
                          className="input-gray-bg-border text-sm bg-white"
                          value={company}
                          onChange={(e) =>
                            dispatch({
                              field: "company",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>
                    )}

                    <div className="mt-3 gap-1">
                      <label className="font-inter-normal text-sm">
                        Phone number
                      </label>
                      <PhoneInput
                        required
                        disabled={
                          currentUser &&
                          !isAdmin &&
                          currentUser?.attributes?.phone
                        }
                        id="phone"
                        defaultCountry="CA"
                        value={phone}
                        className={classNames(
                          "input-gray-bg-border text-sm bg-white",
                          {
                            "border-attention": phoneError,
                          }
                        )}
                        onChange={(value) => {
                          if (phoneError)
                            dispatch({ field: "phoneError", value: "" });

                          dispatch({
                            field: "phone",
                            value: value ? value : "",
                          });
                        }}
                      />
                    </div>

                    {phoneError && <InputError error={phoneError} />}

                    <div className="mt-3 gap-1">
                      <label className="font-inter-normal text-sm">Email</label>
                      <input
                        disabled={currentUser && !isAdmin && email}
                        required
                        name={"emailField"}
                        className="input-gray-bg-border text-sm bg-white"
                        type={"email"}
                        value={email}
                        onChange={(e) =>
                          dispatch({ field: "email", value: e.target.value })
                        }
                      />
                    </div>

                    {!currentUser && (
                      <div className="mt-3 gap-1">
                        <label className="font-inter-normal text-sm">
                          Confirm email
                        </label>
                        <input
                          required
                          name={"confirmEmailField"}
                          className="input-gray-bg-border text-sm bg-white"
                          type={"email"}
                          value={confirmEmail}
                          onChange={(e) =>
                            dispatch({
                              field: "confirmEmail",
                              value: e.target.value,
                            })
                          }
                        />
                      </div>
                    )}

                    {!currentUser && (
                      <button
                        type="button"
                        onClick={() => store.userStore.setLoginModal(true)}
                        className="text-start btn-style mt-5 text-sm hover:text-hh-accent-dark text-indigo-500"
                      >
                        Already have an account?{" "}
                        <span className="underline">Log in</span>
                      </button>
                    )}
                  </div>
                ) : currentUser && isAdmin ? (
                  <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl gap-3 w-full">
                    <span className="text-[20px] font-inter-semibold">
                      Billing Info
                    </span>

                    <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                      {currentUser?.attributes?.first_name}
                    </div>

                    <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                      {currentUser?.attributes?.last_name}
                    </div>

                    <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                      {currentUser?.attributes?.phone}
                    </div>

                    <div className="h-12 rounded-xl px-[19px] text-slate-500 text-sm disabled bg-zinc-100 items-center flex">
                      {currentUser?.attributes?.email}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {currentUser && isAdmin && (
                  <div className="flex flex-col p-5 bg-white mt-6 rounded-3xl gap-3 w-full">
                    <span className="text-[20px] font-inter-semibold">
                      Payment method
                    </span>

                    {!invoiceExist && (
                      <div className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer w-full pr-2">
                        <span className="font-inter-semibold text-lg w-44">
                          Credit card
                        </span>

                        <button
                          type="button"
                          className={classNames(
                            "btn-light btn-style py-3 px-5 border-indigo-900 text-indigo-900 rounded-md"
                          )}
                          onClick={() => {
                            setAddNewCard(true);
                            dispatch({ field: "temporaryCard", value: null });
                            dispatch({
                              field: "showTemporaryCard",
                              value: false,
                            });
                            dispatch({
                              field: "temporaryCardSelected",
                              value: false,
                            });
                          }}
                        >
                          Add a new card
                        </button>
                      </div>
                    )}

                    {!invoiceExist &&
                      stripeCreditCards?.payment_methods?.data?.map(
                        (data, index) => (
                          <div
                            className="bg-white border border-zinc-200 h-16 rounded-lg mt-[12px] px-[12px] py-[16px] d-row justify-between"
                            key={index}
                          >
                            <div key={"photo"} className="flex items-center">
                              <div
                                className="flex cursor-pointer"
                                onClick={() => {
                                  handleDefaultStripeCard(data?.id);
                                  dispatch({
                                    field: "paidByInvoice",
                                    value: false,
                                  });
                                  dispatch({
                                    field: "defaultCard",
                                    value: data?.id,
                                  });
                                  dispatch({
                                    field: "temporaryCardSelected",
                                    value: false,
                                  });
                                }}
                              >
                                <img
                                  alt="radio-button"
                                  src={
                                    data?.id == defaultCard &&
                                    !paidByInvoice &&
                                    !temporaryCardSelected
                                      ? RadioActive
                                      : RadioInActive
                                  }
                                />
                                <div className="d-row items-center ml-3">
                                  <img
                                    className="relative w-[40px] h-[40px]"
                                    alt="Frame"
                                    src={
                                      data?.card?.display_brand == "visa"
                                        ? VisaCardIcon
                                        : MasterCardIcon
                                    }
                                  />

                                  <div className="d-col ml-3">
                                    <small className="text-sm text-slate-700 font-inter-medium">
                                      **** {data?.card?.last4}
                                    </small>

                                    <small className="text-sm text-slate-500 font-inter-normal">
                                      Expires{" "}
                                      {`${
                                        data?.card?.exp_month
                                      }/${data?.card?.exp_year
                                        .toString()
                                        .slice(-2)}`}
                                    </small>
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        )
                      )}

                    {showTemporaryCard && (
                      <div className="bg-white border border-zinc-200 h-16 rounded-lg mt-[12px] px-[12px] py-[16px] d-row justify-between">
                        <div key={"photo"} className="flex items-center">
                          <div
                            className="flex cursor-pointer"
                            onClick={() => {
                              dispatch({
                                field: "paidByInvoice",
                                value: false,
                              });

                              dispatch({
                                field: "temporaryCardSelected",
                                value: true,
                              });
                            }}
                          >
                            <img
                              alt="radio-button"
                              src={
                                temporaryCard && temporaryCardSelected
                                  ? RadioActive
                                  : RadioInActive
                              }
                            />
                            <div className="d-row items-center ml-3">
                              <img
                                className="relative w-[40px] h-[40px]"
                                alt="Frame"
                                src={
                                  temporaryCard?.display_brand == "visa"
                                    ? VisaCardIcon
                                    : MasterCardIcon
                                }
                              />

                              <div className="d-col ml-3">
                                <small className="text-sm text-slate-700 font-inter-medium">
                                  **** {temporaryCard?.last4}
                                </small>

                                <small className="text-sm text-slate-500 font-inter-normal">
                                  Expires{" "}
                                  {`${
                                    temporaryCard?.exp_month
                                  }/${temporaryCard?.exp_year
                                    .toString()
                                    .slice(-2)}`}
                                </small>
                              </div>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    )}

                    {invoiceExist && (
                      <>
                        <div className="bg-white border border-zinc-200 rounded-lg px-[12px] py-[16px] d-row justify-between">
                          <div key={"photo"} className="flex items-center">
                            <div
                              className="flex cursor-pointer"
                              onClick={() => {
                                dispatch({
                                  field: "paidByInvoice",
                                  value: true,
                                });
                                dispatch({
                                  field: "temporaryCardSelected",
                                  value: false,
                                });

                                store.paymentInfoStore.setIsNewCard(false);
                              }}
                            >
                              <img alt="radio-button" src={RadioActive} />
                              <div className="d-row items-center ml-3">
                                <img
                                  className="relative w-[40px] h-[40px]"
                                  alt="Frame"
                                  src={FileInputIcon}
                                />

                                <div className="d-col ml-3">
                                  <small className="text-sm text-slate-700 font-inter-medium">
                                    Invoice details
                                  </small>

                                  <small className="text-sm text-slate-500 font-inter-normal">
                                    {defaultLocation?.account_billing_name}{" "}
                                    <br />
                                    {
                                      defaultLocation?.account_billing_address
                                    }{" "}
                                    <br />
                                    {defaultLocation?.account_billing_emails?.map(
                                      (email, index) => {
                                        return <p key={index}>{email}</p>;
                                      }
                                    )}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <div className="flex flex-col p-5 bg-white mt-6 rounded-xl">
                  <span className="text-2xl font-inter-semibold">Summary</span>

                  <div className="mt-5">
                    <div className="flex w-full items-center justify-between">
                      <span className="text-md">Items</span>
                      <span className="text-md">
                        {priceFormatter(
                          currentGroupOrder?.attributes?.meals[0]?.attributes
                            ?.subtotal
                        )}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <div className="flex items-center">
                        <span className="text-md">Delivery</span>

                        <Tooltip
                          grayIcon
                          tooltipText={
                            "Your delivery cost is dependent on restaurant and proximity to your delivery address"
                          }
                          id="delivery-tooltip"
                          backgroundColor={"white"}
                          textColor="black"
                          place={"bottom"}
                        />
                      </div>

                      <span className="text-md">
                        {priceFormatter(
                          currentGroupOrder?.attributes?.meals[0]?.attributes
                            ?.delivery_fee
                        )}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <div className="flex items-center">
                        <span className="text-md">Service Fee</span>

                        <Tooltip
                          grayIcon
                          tooltipText={
                            "A service fee of seven percent of food total charges is inclusive of all hungerhub fees"
                          }
                          id="service-tooltip"
                          backgroundColor={"white"}
                          textColor="black"
                          place={"bottom"}
                        />
                      </div>

                      <span className="text-md">
                        {priceFormatter(
                          currentGroupOrder?.attributes?.meals[0]?.attributes
                            ?.service_fee
                        )}
                      </span>
                    </div>

                    <div className="flex w-full items-center justify-between mt-3">
                      <span className="text-md">Tax</span>

                      <span className="text-md">
                        {priceFormatter(
                          currentGroupOrder?.attributes?.meals[0]?.attributes
                            ?.tax_amount
                        )}
                      </span>
                    </div>

                    <div className="border-t border-b border-border-gray my-4 py-5">
                      <div className="flex w-full items-center justify-between">
                        <span className="text-md font-inter-medium">Tip</span>

                        <span className="text-md">
                          {priceFormatter(
                            currentGroupOrder?.attributes?.meals[0]?.attributes
                              ?.tip
                          )}
                        </span>
                      </div>

                      <div className="hidden sm:flex w-full flex-wrap items-center">
                        {TIP_VALUES_DESKTOP?.map((tipValue) => (
                          <TipButton
                            key={`${tipValue}-tip`}
                            onClick={() =>
                              dispatch({
                                field: "tip",
                                value: tipValue,
                              })
                            }
                            tipValue={tipValue}
                            tip={
                              currentGroupOrder?.attributes?.meals[0]
                                ?.attributes?.tip
                            }
                          />
                        ))}
                      </div>

                      <div className="flex sm:hidden w-full flex-wrap items-center">
                        {TIP_VALUES_MOBILE?.map((tipValue) => (
                          <TipButton
                            key={`${tipValue}-tip-mob`}
                            onClick={() =>
                              dispatch({
                                field: "tip",
                                value: tipValue,
                              })
                            }
                            tipValue={tipValue}
                            tip={
                              currentGroupOrder?.attributes?.meals[0]
                                ?.attributes?.tip
                            }
                          />
                        ))}
                      </div>

                      {provider.tip == "Other" && (
                        <div className="flex mt-5 space-x-5">
                          <div className="flex items-center text-md rounded-xl bg-background w-full py-2 px-3 h-12 border border-border-gray">
                            <span className="mr-1">$</span>

                            <input
                              className="text-sm bg-background h-[46px] w-full focus:outline-none"
                              value={otherTip}
                              type="number"
                              autoFocus
                              min="0"
                              step=".01"
                              onKeyDown={preventMinus}
                              onPaste={preventPasteNegative}
                              onChange={(e) =>
                                dispatch({
                                  field: "otherTip",
                                  value:
                                    e.target.value !== ""
                                      ? Math.abs(e.target.value)
                                      : e.target.value,
                                })
                              }
                            />
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              dispatch({
                                field: "customTip",
                                value: otherTip,
                              });

                              updateTipAmount();
                            }}
                            className="btn-purple w-28 text-sm"
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>

                    {!currentUser && (
                      <div className="flex border-b border-border-gray pb-5 mt-5">
                        <div className="mr-3">
                          <img
                            layout="fixed"
                            width={16}
                            height={16}
                            alt="info-button-checkout"
                            src={InfoButton}
                          />
                        </div>

                        <span className="text-md">
                          If you have a promo code or a discount, you will be
                          able to apply it on the payments page prior to payment
                        </span>
                      </div>
                    )}

                    <div className="flex w-full items-center justify-between mt-5 mb-16 md:mb-0">
                      <span className="text-md font-inter-medium">Total</span>

                      <span className="text-md font-inter-medium">
                        {priceFormatter(
                          currentGroupOrder?.attributes?.meals[0]?.attributes
                            ?.grand_total
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex mt-5 md:mt-0 bg-white md:bg-transparent py-5 md:static w-full">
                  <button
                    className="btn-purple w-full mt-4 py-5 h-[80px]"
                    type="submit"
                  >
                    <span
                      className={`text-sm font-inter-medium ${
                        isAbleToProceedToPayment()
                          ? "text-white"
                          : "text-secondary-gray"
                      }`}
                    >
                      {userLogin ? "Place order" : "Proceed to payment"}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <>
            {store.userStore?.isHydrated && (
              <button className="text-md p-5 btn-style" type="button">
                <a href="/">
                  You cannot checkout before adding items to your cart, click
                  here to go to home.
                </a>
              </button>
            )}
          </>
        )}

        {addressValidationAlert && (
          <ConfirmationModal
            avoidClose
            visible={addressValidationAlert}
            setModal={setAddressValidationAlert}
            title={"Restaurant outside delivery area"}
            body={`${currentGroupOrder?.attributes?.restaurant_name} cannot deliver to the updated address, please revert to original address or browse other restaurants`}
            leftButtonText={"Browse other restaurants"}
            rightButtonText={"Return to page"}
            onClickLeftButton={onClickLeftButtonInAlert}
            onClickRightButton={onClickRightButtonInAlert}
          />
        )}

        {bypassPlaceOrder && (
          <ConfirmationModal
            avoidClose
            visible={bypassPlaceOrder}
            setModal={setBypassPlaceOrder}
            body={bypassPlaceOrder}
            rightButtonText="Place Order"
            onClickRightButton={() => {
              sendProceedPaymentRequest(true);
            }}
          />
        )}

        <AddPaymentCard
          avoidClose
          visible={addNewCard}
          setModal={setAddNewCard}
          handleTemporaryCard={handleTemporaryCard}
          handlePaymentMethod={() =>
            dispatch({
              field: "paidByInvoice",
              value: false,
            })
          }
        />
      </div>
    </>
  );
};

GroupOrderCheckout.groupOrderCheckoutLayout = true;

export default observer(GroupOrderCheckout);
