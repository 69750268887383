import "./Ilustration.css";
import { useCallback } from "react";

const Ilustration = ({
  bgImage,
  textStyle,
  text,
  width,
  height,
  updateChosenRestaurantFilters,
}) => {
  const handleClick = useCallback(() => {
    const value =
      text == "Sandwiches"
        ? "Sandwiches & Burgers"
        : text == "Mexican"
        ? "Mexican & Latin American"
        : text;
    updateChosenRestaurantFilters("cuisine", value);
  }, [text]);

  return (
    <div
      onClick={handleClick}
      className="flex cursor-pointer cuisineCard relative items-center justify-center bg-background my-2 rounded-lg"
    >
      <img
        src={bgImage}
        width={width}
        height={height}
        alt={`${text}-image`}
        objectFit="cover"
      />

      <span
        className="text-sm captionText font-inter-medium flex-wrap break-words"
        style={{ position: "absolute", bottom: 8, ...textStyle }}
      >
        {text}
      </span>
    </div>
  );
};

export default Ilustration;
