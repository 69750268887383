import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";
import RemoveLocationTeam from "./removeLocationTeam";

function CreateTeam({ visible, setModal, isEdit, selectedTeam, setTeamAdded }) {
  const [confirmTeamRemove, setConfirmTeamRemove] = useState(false);
  const [teamName, setTeamName] = useState("");
  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  const { defaultLocation } = userStore;

  useEffect(() => {
    if (isEdit) setTeamName(selectedTeam?.label);
    else setTeamName("");
  }, [isEdit]);

  const handleSave = () => {
    let payload = {
      team: {
        name: teamName,
      },
    };

    store.userStore.setLoader(true);
    store.teamStore
      .createTeam(
        {
          location_id: defaultLocation?.organizationId,
        },
        payload
      )
      .then((response) => {
        store.userStore.setLoader(false);
        setTeamAdded(response?.data);
        setModal(false);
      });
  };

  const handleUpdate = () => {
    let payload = {
      team: {
        name: teamName,
      },
    };
    store.userStore.setLoader(true);
    store.teamStore
      .updateTeam(
        {
          location_id: defaultLocation?.organizationId,
        },
        selectedTeam?.value,
        payload
      )
      .then((response) => {
        store.userStore.setLoader(false);
        setTeamAdded(response?.data);
        setModal(false);
      });
  };

  const handleTeamDelete = () => {
    store.userStore.setLoader(true);
    store.teamStore
      .deleteTeam(
        {
          location_id: defaultLocation?.organizationId,
        },
        selectedTeam?.value
      )
      .then(() => {
        store.userStore.setLoader(false);
        setTeamAdded();
        setModal(false);
      });
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] h-[326px] d-col justify-between rounded-xl transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="d-row justify-between items-center">
                  <h2 className="text-2xl font-inter-bold text-[#3a3a3a]">
                    {isEdit ? "Edit Team" : "Create New Team"}
                  </h2>
                  <button
                    onClick={() => setModal(false)}
                    className="text-dark-gray hover:text-yellow-600"
                    aria-label="Close"
                  >
                    <span className="text-xl">&times;</span>
                  </button>
                </div>

                <div className="d-col items-start py-1.5 text-sm select-non cursor-pointer">
                  <span className="ml-1 font-inter-semibold text-[18px] mb-[12px] text-sm w-40 text-[#2d3958]">
                    {isEdit ? "Team name" : "Name your team"}
                  </span>

                  <input
                    id="team-name"
                    className="input-gray-bg focus:ring-0 address-inputs my-0 rounded-xl text-sm w-full !text-md bg-background pl-4"
                    placeholder="Add a team name"
                    value={teamName}
                    onChange={(e) => {
                      setTeamName(e.target.value);
                    }}
                  />
                </div>

                {isEdit && (
                  <div
                    className="h-12 py-2 items-center justify-center flex rounded-xl mt-2 bg-[#f7f9ff] cursor-pointer"
                    onClick={() => setConfirmTeamRemove(true)}
                  >
                    <span className="text-[#575be8] font-inter-medium">
                      Delete Team
                    </span>
                  </div>
                )}

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={() => (isEdit ? handleUpdate() : handleSave())}
                  >
                    {isEdit ? "Apply" : "Create Team"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>

            <RemoveLocationTeam
              visible={confirmTeamRemove}
              setModal={setConfirmTeamRemove}
              teamName={teamName}
              handleDelete={handleTeamDelete}
            />
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default CreateTeam;
