import React from "react";
import {
  CateringServices,
  CateringStep1,
  CateringStep2,
  CateringStep3,
  GroupOrderServices,
  GroupOrderStep1,
  GroupOrderStep2,
  GroupOrderStep3,
  UncateringServices,
  UncateringStep1,
  UncateringStep2,
  UncateringStep3,
} from "assets/img";
import { useNavigate } from "react-router-dom";

function OurServices() {
  const navigate = useNavigate();

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      <div className="desktop-screen:hidden tablet-screen:flex flex-col justify-start items-start gap-[18px] flex mb-[34px]">
        <div className="text-slate-700 text-[32px] font-inter-semibold capitalize leading-[48px]">
          Compare our Services
        </div>

        <div className="text-slate-700 text-base font-inter-normal leading-tight ml-1">
          Select the meal that works best for you and your team. You can also
          use multiple services Uncatering for lunch & On Demand for your next
          meeting!
        </div>
      </div>

      <div className="justify-start items-start gap-4 grid desktop-screen:grid-cols-4 tablet-screen:grid-cols-4 grid-cols-1">
        <div className="desktop-screen:w-60 tablet-screen:w-[120px] flex-col justify-start items-start gap-[65px] inline-flex">
          <div className="flex-col justify-start items-start gap-[18px] flex">
            <div className="hidden desktop-screen:flex desktop-screen:w-[242px] text-slate-700 text-[32px] font-inter-semibold capitalize leading-[48px]">
              Compare our Services
            </div>

            <div className="hidden desktop-screen:flex desktop-screen:w-[242px] text-slate-700 text-base font-inter-normal leading-tight">
              Select the meal that works best for you and your team. You can
              also use multiple services Uncatering for lunch & On Demand for
              your next meeting!
            </div>
          </div>

          <div className="hidden desktop-screen:flex tablet-screen:flex desktop-screen:pt-4 tablet-screen:pt-72 flex-col justify-start items-start gap-[76px] flex">
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight desktop-screen:mt-4 tablet-screen:mt-12">
              Minimum spend
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight desktop-screen:mt-2 tablet-screen:mt-4">
              Restaurants
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight desktop-screen:mt-4 tablet-screen:mt-16">
              Deadline to order
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by
            </div>
            <div className="text-slate-700 text-lg font-inter-semibold leading-tight desktop-screen:mt-3 tablet-screen:mt-10">
              Delivered by
            </div>
          </div>
        </div>

        <div className="desktop-screen:w-60 tablet-screen:w-[151.31px] w-full desktop-screen:px-10 tablet-screen:px-0 pb-8 bg-slate-50 rounded-xl flex-col justify-start desktop-screen:items-center tablet-screen:items-start screen-sm:items-start gap-[65px] inline-flex">
          <div className="desktop-screen:w-[239px] tablet-screen:w-[151.31px] w-full desktop-screen:h-56 tablet-screen:h-64 p-3 bg-indigo-500 rounded-xl flex-col justify-start items-center gap-3 inline-flex">
            <img className="w-[72px] h-[72px]" src={UncateringServices} />
            <div className="text-center text-white text-xl font-inter-semibold leading-normal">
              Uncatering
            </div>
            <div className="text-center text-white text-base font-inter-medium leading-tight">
              Individuals ordering on a set schedule{" "}
            </div>
          </div>

          <div className="desktop-screen:w-[190px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-4 mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              20+ employees ordering on a recurring basis
            </span>
          </div>

          <div className="text-slate-700 desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Minimum spend:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              $350 per meal
            </span>
          </div>

          <div className="desktop-screen:w-[182px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-5 mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Restaurants:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              Multiple restaurants to choose from per meal{" "}
            </span>
          </div>

          <div className="desktop-screen:w-[182px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Deadline to order:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              2 hours <br />
              e.g. 10:30am for lunch
            </span>
          </div>

          <div className="desktop-screen:w-[227px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              Customized and placed by individuals
            </span>
          </div>

          <div className="desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-2 tablet-screen:mt-[12px] mt-2">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Delivered by:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              hungerhub team
            </span>
          </div>
        </div>

        <div className="desktop-screen:w-60 tablet-screen:w-[151.31px] w-full desktop-screen:px-10 tablet-screen:px-0 pb-8 bg-slate-50 rounded-xl flex-col justify-start desktop-screen:items-center tablet-screen:items-start screen-sm:items-start desktop-screen:gap-[75px] tablet-screen:gap-[78px] gap-[68px] inline-flex">
          <div className="desktop-screen:w-[239px] tablet-screen:w-[151.31px] w-full desktop-screen:h-56 tablet-screen:h-64 p-3 bg-indigo-500 rounded-xl flex-col justify-start items-center gap-3 inline-flex">
            <img className="w-[72px] h-[72px]" src={GroupOrderServices} />
            <div className="text-center text-white text-xl font-inter-semibold leading-normal">
              Group ordering
            </div>
            <div className="text-center text-white text-base font-inter-medium leading-tight">
              Group orders individually customized{" "}
            </div>
          </div>

          <div className="desktop-screen:w-[230px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-1 screen-sm:mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              Events, meetings or with external invitees
            </span>
          </div>

          <div className="text-slate-700 desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-2 mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Minimum spend:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              $150 per meal
            </span>
          </div>

          <div className="desktop-screen:w-[201px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Restaurants:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              One restaurant, selected by you
            </span>
          </div>

          <div className="text-slate-700 desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-2 tablet-screen:mt-8 mt-2">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Deadline to order:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              16 hours
            </span>
          </div>

          <div className="desktop-screen:w-[227px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-[6px] mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              Customized and placed by individuals
            </span>
          </div>

          <div className="desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Delivered by:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              hungerhub team
            </span>
          </div>
        </div>

        <div className="desktop-screen:w-60 tablet-screen:w-[151.31px] w-full desktop-screen:px-10 tablet-screen:px-0 pb-8 bg-slate-50 rounded-xl flex-col justify-start desktop-screen:items-center tablet-screen:items-start screen-sm:items-start desktop-screen:gap-[75px] tablet-screen:gap-[78px] gap-[68px] inline-flex">
          <div className="desktop-screen:w-[239px] tablet-screen:w-[151.31px] w-full desktop-screen:h-56 tablet-screen:h-64 p-3 bg-indigo-500 rounded-xl flex-col justify-start items-center gap-3 inline-flex">
            <img className="w-[72px] h-[72px]" src={CateringServices} />
            <div className="text-center text-white text-xl font-inter-semibold leading-normal">
              On Demand Catering
            </div>
            <div className="text-center text-white text-base font-inter-medium leading-tight">
              Catering ordered placed by one on behalf of many
            </div>
          </div>

          <div className="desktop-screen:w-[230px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-1 screen-sm:mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Ideal for:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              Large scale events or family style catering
            </span>
          </div>

          <div className="text-slate-700 desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-2 mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Minimum spend:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              $150 per meal
            </span>
          </div>

          <div className="desktop-screen:w-[201px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Restaurants:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              Selected by one individual (you)
            </span>
          </div>

          <div className="text-slate-700 desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-2 tablet-screen:mt-8 mt-2">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Deadline to order:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              16 hours
            </span>
          </div>

          <div className="desktop-screen:w-[201px] desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6 desktop-screen:mt-0 tablet-screen:mt-[6px] mt-0">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Orders placed by:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              Selected by one (you) on behalf of group
            </span>
          </div>

          <div className="desktop-screen:text-center tablet-screen:text-start screen-sm:text-start text-slate-700 text-base font-inter-semibold leading-tight desktop-screen:px-0 tablet-screen:px-6 px-6">
            <div className="desktop-screen:hidden tablet-screen:hidden screen-sm:flex inline-flex text-slate-700 text-lg font-inter-semibold leading-tight">
              Delivered by:
            </div>
            <span className="desktop-screen:ml-2 tablet-screen:ml-0 screen-sm:ml-2">
              hungerhub team
            </span>
          </div>
        </div>
      </div>

      {/* For Uncatering  */}
      <div className="flex-col justify-start items-start flex mt-24">
        <div className="flex-col justify-start items-start flex">
          <div className="text-neutral-700 text-[32px] font-inter-semibold capitalize leading-[48px] mb-[12px]">
            Uncatering
          </div>

          <div className="desktop-screen:w-2/3 tablet-screen:w-full text-slate-700 text-base font-inter-normal leading-tight mb-[24px] ml-1">
            Empower your employees with the best possible way to order lunches
            as part of a meal plan solution for your office.
          </div>

          <button
            className="btn-blue-accent rounded-[32px] h-10 w-[204px] px-6 py-2.5"
            onClick={() => navigate("/get-started-uncatering")}
          >
            Enable meal plan
          </button>
        </div>

        <div className="flex-col justify-start items-start flex mt-[48px]">
          <div className="text-gray-800 text-2xl font-inter-semibold leading-loose mb-[12px]">
            How does it work?
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            How the meal plan of the future works...
          </div>

          <div className="justify-start items-start gap-[23px] grid desktop-screen:grid-cols-3 tablet-screen:grid-cols-3 grid-cols-1">
            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={UncateringStep1}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step1
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                <p className="font-inter-medium">
                  Create a meal plan for your workplace and add your team
                  member.
                </p>{" "}
                Set a daily, weekly, or meeting/event specific budgets.
              </div>
            </div>

            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={UncateringStep2}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step2
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Team members individually choose and customize meals <br /> From
                a rotation of restaurants, there’s something for everyone.From
                diets such as Gluten-free or Vegan to taste preferences like "No
                onions", "Extra cheese" and "dressing on the side".
              </div>
            </div>

            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={UncateringStep3}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step3
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Meals delivered, each in a sealed package labelled with a name
                hungerhub’s own team members deliver meals in easily
                identifiable packaging, meaning less headache for you and peace
                of mind for the rest of the team.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For GroupOrder  */}
      <div className="flex-col justify-start items-start flex mt-20">
        <div className="flex-col justify-start items-start flex">
          <div className="text-neutral-700 text-[32px] font-inter-semibold capitalize leading-[48px] mb-[12px]">
            Group ordering
          </div>

          <div className="desktop-screen:w-2/3 tablet-screen:w-full text-slate-700 text-base font-inter-normal leading-tight mb-[24px] ml-1">
            Perfect for meetings and events... Group Orders combine the peace of
            mind of a catered meal with the flexibility allowing invitees to
            select their meals.
          </div>

          <button
            className="btn-blue-accent rounded-[32px] h-10 w-[204px] px-6 py-2.5"
            onClick={() => navigate("/get-started-group")}
          >
            Start ordering
          </button>
        </div>

        <div className="flex-col justify-start items-start flex mt-[48px]">
          <div className="text-gray-800 text-2xl font-inter-semibold leading-loose mb-[12px]">
            How does it work?
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            You will have full visibility of all orders within a group order and
            can edit it along the way.
          </div>

          <div className="justify-start items-start gap-[23px] grid desktop-screen:grid-cols-3 tablet-screen:grid-cols-3 grid-cols-1">
            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={GroupOrderStep1}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step1
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                The restaurant of your choice - set a date, time, and per person
                budget.
              </div>
            </div>

            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={GroupOrderStep2}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step2
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Share a unique link with your invitees that allows them to
                select and customize their items.
              </div>
            </div>

            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={GroupOrderStep3}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step3
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Everything delivered together, labelled with names.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* For Catering  */}
      <div className="flex-col justify-start items-start flex mt-20">
        <div className="flex-col justify-start items-start flex">
          <div className="text-neutral-700 text-[32px] font-inter-semibold capitalize leading-[48px] mb-[12px]">
            On Demand Catering
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px] ml-1">
            Need catering for an event or a meeting and want to order for the
            whole group?
          </div>

          <button
            className="btn-blue-accent rounded-[32px] h-10 w-[204px] px-6 py-2.5"
            onClick={() => navigate("/get-started-catering")}
          >
            Start ordering
          </button>
        </div>

        <div className="flex-col justify-start items-start flex mt-[48px]">
          <div className="text-gray-800 text-2xl font-inter-semibold leading-loose mb-[12px]">
            How does it work?
          </div>

          <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
            You will have full visibility of all orders within a group order and
            can edit it along the way.
          </div>

          <div className="justify-start items-start gap-[23px] grid desktop-screen:grid-cols-3 tablet-screen:grid-cols-3 grid-cols-1">
            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={CateringStep1}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step1
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Set your date and time and browse through available restaurants.
              </div>
            </div>

            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={CateringStep2}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step2
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Select the quantity and items you need.
              </div>
            </div>

            <div className="desktop-screen:w-72 tablet-screen:w-52 pr-2 rounded-xl flex-col justify-start">
              <img
                className="w-[284px] h-[182px] mb-[16px]"
                src={CateringStep3}
              />

              <div className="text-slate-700 text-base font-inter-semibold leading-none mb-[10px]">
                Step3
              </div>
              <div className="text-slate-700 text-base font-inter-normal leading-tight mb-[24px]">
                Everything delivered together, family style or individual meals,
                it’s up to you.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;
