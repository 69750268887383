import { action, makeObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import { errorHandler } from "utils/middlewares/errorHandler";

class TeamStore {
  constructor() {
    makePersistable(this, {
      name: "TeamStore",
      properties: [],
      storage: window.localStorage,
    });
    makeObservable(this, {});
  }

  async createTeam(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.teams()}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async createTeamUser(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.users()}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async deleteTeamUser(params, empId) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.teamEmployees()}/${empId}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async updateTeam(params, teamId, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .patch(`${api.teams()}/${teamId}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async deleteTeam(params, teamId) {
    let paramsString = qs.stringify(params);

    return client()
      .delete(`${api.teams()}/${teamId}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async resetPassword(params) {
    let paramsString = qs.stringify(params, { arrayFormat: "brackets" });

    return client()
      .get(`${api.resetPassword()}?${paramsString}`)
      .then(
        action("fetchSuccess", (data) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler({
            title: "No email found",
            body: "No user associated with this account.",
          });
          return error;
        })
      );
  }

  async createBulkUsers(params, payload, isFileUpload) {
    let paramsString = qs.stringify(params);

    return client(isFileUpload)
      .post(`${api.teamUsers()}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          return error;
        })
      );
  }

  async updateTeamUser(userId, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .patch(`${api.users()}/${userId}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async deleteBulkUsers(params) {
    let queryString = qs.stringify(params, { arrayFormat: "brackets" });

    return client()
      .delete(`${api.deleteEmployees()}?${queryString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error.response);
          return error;
        })
      );
  }

  async updateBulkUsers(params, payload) {
    let queryString = qs.stringify(params, { arrayFormat: "brackets" });

    return client()
      .patch(`${api.updateEmployees()}?${queryString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error.response);
          return error;
        })
      );
  }
}

const teamStore = new TeamStore();
export default teamStore;
