import React, { useState } from "react";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/img";

dayjs.extend(weekday);
dayjs.extend(weekOfYear);

const MonthPicker = ({ onMonthChange }) => {
  // Initialize the current week state
  const [currentDate, setCurrentDate] = useState(dayjs());

  const goToNextMonth = () => {
    setCurrentDate(currentDate.add(1, "month"));
    onMonthChange(currentDate.add(1, "month"));
  };

  const goToPrevMonth = () => {
    setCurrentDate(currentDate.subtract(1, "month"));
    onMonthChange(currentDate.subtract(1, "month"));
  };

  const startOfWeek = currentDate.startOf("isoWeek").format("MMMM YYYY");

  return (
    <div className="w-[245px] h-[48px] rounded-lg d-row items-center">
      <button
        onClick={goToPrevMonth}
        className="bg-light-gray px-[16px] py-[12px] rounded-lg cursor-pointer"
      >
        <img src={ChevronLeftIcon} className="h-[20px] w-[20px]" />
      </button>

      <span className="font-inter-semibold text-[24px] text-sm text-heading px-[8px]">{`${startOfWeek}`}</span>

      <button
        onClick={goToNextMonth}
        className="bg-light-gray px-[16px] py-[12px] rounded-lg cursor-pointer"
      >
        <img src={ChevronRightIcon} className="h-[20px] w-[20px]" />
      </button>
    </div>
  );
};

export default MonthPicker;
