import React, { useEffect, useState } from "react";
import { WarningImg, hhLogo } from "assets/img";
import { isPresent } from "utils/helpers/array";

function ErrorPage() {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const storedErrors = sessionStorage.getItem("errorsData");
    if (storedErrors) {
      setErrors(JSON.parse(storedErrors));
    }
  }, []);

  return (
    <div className="w-full">
      <div className="flex justify-center py-4 border border-bottom border-[#f7f9ff]">
        <img
          src={hhLogo}
          alt="hungerhub-logo"
          style={{
            width: "150px",
            height: "55px",
          }}
        />
      </div>

      <div className="flex bg-white px-10 py-4 flex-col gap-2">
        <div className="flex justify-center">
          <img src={WarningImg} alt="warning-icon" />
        </div>

        <div className="text-center text-[#2d3958] text-2xl font-inter-semibold leading-loose">
          Error in Uploading Some Lines
        </div>

        <p className="font-inter-normal whitespace-pre-line text-base text-[#2d3958]">
          Some lines couldn't be uploaded due to errors. Please review and
          correct the listed issues before re-uploading the file. The other
          lines have been uploaded successfully. Don't worry—if you re-upload
          the entire file, duplicate entries will not be created.
        </p>

        {isPresent(errors) && (
          <div className="p-3 bg-[#f7f9ff] rounded-xl mt-2">
            <p className="text-[#d56c76] text-sm font-inter-normal mb-4 px-3">
              {errors?.length} errors found
            </p>

            <div className="flex-col justify-center items-start inline-flex mt-2 grid grid-cols-1">
              {errors?.map((error, index) => (
                <div
                  key={index}
                  className="text-[#d56c76] text-sm font-inter-medium py-2 px-3"
                >
                  {error}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ErrorPage;
