import React, { useContext, useReducer, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ChangeDeliveryTime,
  ChangeSubsidy,
  ChangeContact,
  ChangeDeliveryInstructions,
  ChangeExpectedOrders,
  ChangeDeliveryAddress,
} from "alerts";
import { useManagePlan } from "contexts/ManagePlanContext";
import { useQuery } from "@tanstack/react-query";
import { MobXProviderContext, observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { toJS } from "mobx";
import { priceFormatter } from "utils/helpers/validation";
import { formattedAddres } from "utils/helpers/string";
import { NotFound, NotificationBanner } from "components";
import dateTime from "utils/helpers/dateTime";
import dayjs from "dayjs";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

function RequestChangesToMeal() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.state?.referrer || -1;
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);

  const { locationID, defaultLocation } = userStore;
  const { mealId } = useParams();

  const {
    managePlan,
    setManagePlan,
    changeDeliveryTime,
    setChangeDeliveryTime,
    changeExpectedOrders,
    setChangeExpectedOrders,
    changeSubsidy,
    setChangeSubsidy,
    setChangeContact,
    changeContact,
    changeDeliveryInstructions,
    setChangeDeliveryInstructions,
    changeAddress,
    setChangeAddress,
  } = useManagePlan();

  const initialState = {
    meal: null,
    usersWithOrderInfo: [],
    userTeamFilter: null,
    userOrderSearchText: "",
    totalUsersUnderMeal: 0,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["groupOrder", locationID, mealId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.homeStore.fetchMealDetail(mealId, locationID);
    },
  });

  useEffect(() => {
    dispatch({ field: "meal", value: data });

    dispatch({
      field: "totalUsersUnderMeal",
      value: data?.attributes?.location_employees?.length,
    });
  }, [data]);

  let { meal } = provider;

  useEffect(() => {
    refetch();
  }, [changeDeliveryTime, changeSubsidy, changeExpectedOrders, changeAddress]);

  const getRequestStatus = (targetMapping) => {
    let request = [...data?.attributes?.change_requests]
      .reverse() // Reverse the array to traverse from last to first
      ?.find((change) => change?.attributes?.target_mapping == targetMapping);

    if (request) {
      if (targetMapping == "delivery_time")
        return `Requested update: ${request?.attributes?.change_request_items?.target_time?.new_value} - under review`;
      else if (targetMapping == "expected_bags") {
        return `Requested update: ${request?.attributes?.change_request_items?.expected_bags?.new_value} - under review`;
      } else if (targetMapping == "subsidy") {
        let subsidyType =
          request?.attributes?.change_request_items?.subsidy_type?.new_value;

        let text = "";
        if (subsidyType == "daily") {
          let newVal =
            request?.attributes?.change_request_items?.daily_subsidy?.new_value;
          text = `Daily - Mon $${newVal?.monday}  Tue $${newVal?.tuesday}  Wed $${newVal?.wednesday}  Thu $${newVal?.thursday}  Fri $${newVal?.friday}  Sat $${newVal?.saturday}  Sun $${newVal?.sunday}`;
        } else
          text = `Weekly ${request?.attributes?.change_request_items?.subsidy?.new_value}`;
        return `Requested update: ${text} - under review`;
      } else if (targetMapping == "delivery_address") {
        return `Requested update: ${formattedAddres(
          request?.attributes?.change_request_items?.address_id?.new_value
        )} - under review`;
      }
    }
    return "";
  };

  return (
    <div className="d-col py-[34px] w-full px-[58px]">
      <button
        className="flex items-center cursor-pointer mb-[24px]"
        onClick={() => {
          navigate(path);
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ width: 14, height: 14 }} />
        <span className="text-md text-left font-inter-medium text-black-light ml-2">
          Back
        </span>
      </button>

      <NotificationBanner
        message={`Request is under review by hungerhub, this message will disappear once changes have been updated.`}
        messageType="warning"
        visible={meal?.attributes?.changes_under_review}
        abilityToDismiss={false}
        hideClose={true}
      />
      <div className="d-col items-center w-[665px] self-center">
        <h2 className="font-inter-semibold text-dark-gray text-[24px] mt-[5px]">
          Request Changes to a meal on a specific day
        </h2>

        <div className="mt-[33px] bg-[#F4F4F4] px-[10px] py-[13px] rounded-lg">
          <p className="font-inter-medium text-dark-gray text-[14px]">
            The changes made here will apply only to the meal order on this
            specific date. Modifications can be made up to 12 hours before your
            scheduled meal delivery. To adjust the whole meal series, please
            visit the meal
            <button
              onClick={() => {
                store.mealManagementStore.fetchMealPlan(
                  locationID,
                  meal?.attributes?.meal_series_id
                );
                setManagePlan(true);
                navigate("/meal-management");
              }}
              className="text-catering ml-1"
            >
              meal management section here.
            </button>
          </p>
        </div>

        <h2 className="font-inter-semibold text-dark-gray text-[24px] mt-[5px]">
          Meal Name: {provider?.meal?.attributes?.name}
        </h2>

        <h3 className="font-inter-semibold text-dark-gray text-[20px] mt-[8px]">
          Meal Date:{" "}
          {dayjs(provider?.meal?.attributes?.meal_date).format(
            "ddd, MMMM D, YYYY"
          )}
        </h3>

        <>
          <div className="p-[16px] bg-light-gray w-full d-row rounded-lg justify-between items-center mt-[24px]">
            <div className="d-col">
              <small className="text-dark-gray self-start text-[14px] font-inter-medium">
                Delivery time
              </small>
              <small className="font-inter-semibold text-[16px] text-dark-gray">
                {dateTime.formatTimeWithFormat(
                  meal?.attributes?.target_time,
                  "hh:mm a",
                  meal?.attributes?.tzinfo
                )}
              </small>

              {meal?.attributes?.changes_under_review &&
                getRequestStatus("delivery_time") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("delivery_time")}
                  </small>
                )}
            </div>

            <button
              onClick={() => {
                setChangeDeliveryTime(true);
              }}
              className="text-catering font-inter-medium text-sm"
            >
              Request change
            </button>
          </div>

          <div className="p-[16px] bg-light-gray rounded-lg w-full d-row justify-between items-start mt-[24px]">
            <div className="d-col w-3/4">
              <small className="text-dark-gray self-start text-[14px] font-inter-medium">
                Subsidy:
              </small>

              {meal?.attributes?.subsidy_type == "weekly" ? (
                <small className="font-inter-semibold text-[16px] text-dark-gray">
                  {priceFormatter(meal?.attributes?.subsidy)}
                </small>
              ) : (
                <strong className="font-inter-semibold text-dark-gray">
                  <div className="grid grid-cols-3 gap-2">
                    {meal?.attributes?.daily_subsidy &&
                      Object.keys(meal?.attributes?.daily_subsidy)?.map(
                        (day) => (
                          <div className="bg-white items-center justify-center capitalize px-[12px] py-[10px] d-row rounded-3xl">
                            <small className="mr-2 font-inter-medium text-dark-gray text-[12px]">
                              {day}:
                            </small>
                            <small className="font-inter-medium text-dark-gray text-[12px]">
                              {priceFormatter(
                                meal?.attributes?.daily_subsidy[day]
                              )}
                            </small>
                          </div>
                        )
                      )}
                  </div>
                </strong>
              )}

              {meal?.attributes?.employee_pays_by_credit_card && (
                <div className="d-col mt-2">
                  <strong className="text-dark-gray font-inter-semibold tex-[16px]">
                    Overpayment enabled
                  </strong>
                  <small className="text-secondary font-inter-medium text-[14px]">
                    Allows individuals to charge their personal credit cards if
                    they go over allotted subisidy
                  </small>
                </div>
              )}

              {meal?.attributes?.changes_under_review &&
                getRequestStatus("subsidy") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("subsidy")}
                  </small>
                )}
            </div>

            <button
              onClick={() => {
                setChangeSubsidy(true);
              }}
              className="text-catering font-inter-medium text-sm"
            >
              Request change
            </button>
          </div>

          <div className="p-[16px] rounded-lg bg-light-gray w-full d-row justify-between items-center mt-[24px]">
            <div className="d-col">
              <small className="text-dark-gray self-start text-[14px] font-inter-medium">
                Expected order
              </small>

              <small className="font-inter-medium text-dark-gray text-[12px]">
                The number of people expected to place an order
              </small>
              <small className="font-inter-medium text-[16px] text-dark-gray">
                {meal?.attributes?.expected_bags ?? 0}
              </small>

              {meal?.attributes?.changes_under_review &&
                getRequestStatus("expected_bags") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("expected_bags")}
                  </small>
                )}
            </div>

            <button
              onClick={() => {
                setChangeExpectedOrders(true);
              }}
              className="text-catering font-inter-medium text-sm"
            >
              Request change
            </button>
          </div>

          <div className="d-row w-full items-center justify-between mt-[24px]">
            <span className="text-dark-gray self-start text-[18px] font-inter-semibold">
              Teams & Users
            </span>

            <button
              className="text-catering btn-outline font-inter-medium text-sm"
              onClick={() => {
                navigate(`/home/manage-teams/${meal?.id}`);
              }}
            >
              Manage teams and users
            </button>
          </div>

          <div className="p-[16px] bg-light-gray rounded-lg w-full d-row justify-between items-center mt-[12px]">
            <div className="d-col">
              <small className="font-inter-medium text-dark-gray text-[14px]">
                Teams assigned to this meal
              </small>
              {meal?.attributes?.teams?.map((team) => (
                <span className="font-inter-semibold text-[16px] text-dark-gray">
                  {team?.name}
                </span>
              ))}
            </div>

            <small className="text-dark-gray self-start font-inter-regular text-sm">
              {provider?.totalUsersUnderMeal} users in total
            </small>
          </div>

          <span className="text-dark-gray self-start mt-[24px] text-[18px] font-inter-semibold">
            Delivery address details and point of contact
          </span>

          <div className="p-[16px] bg-light-gray rounded-lg w-full d-row justify-between items-center mt-[24px]">
            <div className="d-col w-3/4">
              <small className="font-inter-semibold text-dark-gray text-[16px]">
                Delivery address
              </small>
              <small className="font-inter-medium text-[14px] text-dark-gray">
                {formattedAddres(meal?.attributes?.address?.attributes)}
              </small>

              <small className="font-inter-semibold mt-[8px] text-dark-gray text-[16px]">
                Delivery instructions
              </small>
              <small className="font-inter-medium text-[14px] text-dark-gray">
                {meal?.attributes?.address?.attributes?.delivery_instructions}
              </small>

              <small className="font-inter-semibold mt-[8px] text-dark-gray text-[16px]">
                Point of contact
              </small>
              <small className="font-inter-medium text-[14px] text-dark-gray">
                {meal?.attributes?.address?.attributes?.delivery_contact_name}
              </small>

              {meal?.attributes?.changes_under_review &&
                getRequestStatus("delivery_address") && (
                  <small className="text-[#566985] d-row items-center text-[14px] font-inter-medium">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      color="#E39F55"
                      size="sm"
                      style={{ width: 14, height: 14 }}
                      className="small-icon mr-2"
                      data-class="my-tooltip"
                    />
                    {getRequestStatus("delivery_address")}
                  </small>
                )}
            </div>

            <button
              onClick={() => {
                setChangeAddress(true);
              }}
              className="text-catering font-inter-medium text-sm"
            >
              Request change
            </button>
          </div>
        </>
      </div>
      {changeDeliveryTime && (
        <ChangeDeliveryTime
          visible={changeDeliveryTime}
          setModal={setChangeDeliveryTime}
          time={meal?.attributes?.target_time}
          updatingMeal={true}
          mealId={meal?.attributes?.id}
          tzinfo={meal?.attributes?.tzinfo}
        />
      )}
      {changeSubsidy && (
        <ChangeSubsidy
          visible={changeSubsidy}
          setModal={setChangeSubsidy}
          updatingMeal={true}
          meal={meal}
        />
      )}
      {changeExpectedOrders && (
        <ChangeExpectedOrders
          visible={changeExpectedOrders}
          setModal={setChangeExpectedOrders}
          updatingMeal={true}
          meal={meal}
        />
      )}
      {changeAddress && (
        <ChangeDeliveryAddress
          visible={changeAddress}
          setModal={setChangeAddress}
          updatingMeal={true}
          meal={meal}
        />
      )}
    </div>
  );
}

export default RequestChangesToMeal;
