import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useContext, useEffect, useReducer, useState } from "react";
import { MobXProviderContext } from "mobx-react";
import SearchBar from "components/searchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faComment } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CopyLink, TrashIconRed } from "assets/img";
import TooltipNew from "components/Tooltip";
import { priceFormatter } from "utils/helpers/validation";
import ManageOrderDetailsModal from "components/ManageOrderDetailsModal";
import ConfirmationModal from "components/ConfirmationModal";
import ConfirmationBadge from "components/ConfirmationBadge";
import {
  dateFormat,
  dateTimeFormatLong,
  deadlineFormat,
  longDateFormatWithShortDay,
  smallDateFormat,
  timeFormat,
} from "utils/helpers/dateTime";
import dateTime from "utils/helpers/dateTime";
import moment from "moment-timezone";
import EmbedHTML from "components/shared/embedHTML";
import { toTitleCase } from "utils/helpers/string";
import AddGroupItemModal from "components/AddGroupItemModal";
import MissingPaymentMethodModal from "components/MissingPaymentMethodModal";
import EditTipModal from "components/EditTipModal";
import classNames from "classnames";
import { getETA } from "utils/helpers/restaurants";
import { getOrderStatusForAttendee } from "utils/helpers/order";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const TrashButton = ({ onClick }) => (
  <button onClick={onClick} className="flex items-start btn-style">
    <img src={TrashIconRed} lang="fixed" />
  </button>
);

const GroupOrderDetails = ({ hostname }) => {
  const initialState = {
    orderItemsSearchText: "",
    detailsModalVisible: false,
    cancelOrderModalVisible: null,
    deleteOrderModalVisible: null,
    editOrderItemsModal: false,
    editNameAttachedModalVisible: false,
    attendeeToUpdate: null,
    showCopyLinkBadge: false,
    order: null,
    missingPaymentMethod: false,
    confirmationBeforeCheckout: false,
    groupOrderStatus: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const paymentInfoStore = toJS(store?.paymentInfoStore);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const [editItemModal, setEditItemModal] = useState(false);
  const [hideCancelledOrder, setHideCancelledOrder] = useState(true);
  const [modalKey, setModalKey] = useState(0);
  const [editTipModal, setEditTipModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const { groupOrderId, locationId } = useParams();
  const navigate = useNavigate();
  let { currentUser, defaultLocation } = userStore ?? {};
  let { cutlery, currentGroupOrder } = groupOrderStore ?? {};
  let { defaultPaymentMethodId } = paymentInfoStore;

  let {
    orderItemsSearchText,
    detailsModalVisible,
    cancelOrderModalVisible,
    deleteOrderModalVisible,
    showCopyLinkBadge,
    attendeeToUpdate,
    order,
    missingPaymentMethod,
    confirmationBeforeCheckout,
    groupOrderStatus,
  } = provider;

  let interval = null;
  const orderStatus = order?.attributes?.status;

  const { isPending, error, data } = useQuery({
    queryKey: ["groupOrder", locationId, groupOrderId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.mealManagementStore.fetchGroupOrderOnly(
        groupOrderId,
        locationId
      );
    },
  });

  useEffect(() => {
    dispatch({ field: "order", value: data });
  }, [data]);

  const getOrder = () => {
    store.userStore?.setLoader(true);
    setTimeout(() => {
      store.mealManagementStore
        .fetchGroupOrderOnly(groupOrderId, locationId)
        .then((response) => {
          store.userStore?.setLoader(false);

          if (response?.attributes) {
            dispatch({ field: "order", value: response });
            fetchGroupOrderStatus();
          }
        })
        .catch(() => {
          store.userStore?.setLoader(false);
        });
    }, [1000]);
  };

  useEffect(() => {
    if (locationId) {
      store.paymentInfoStore.getLocationCreditCards({
        location_id: locationId,
      });
    }
  }, []);

  useEffect(() => {
    if (order?.id) {
      fetchGroupOrderStatus();

      interval = setInterval(() => {
        fetchGroupOrderStatus();
      }, 120000);

      return () => {
        if (interval) clearInterval(interval);
      };
    }
  }, [order?.id]);

  const fetchGroupOrderStatus = () => {
    store.userStore.setLoader(true);
    store.homeStore
      .fetchGroupOrderStatus(order?.id, locationId)
      .then((response) => {
        dispatch({ field: "groupOrderStatus", value: response });
        store.userStore.setLoader(false);
      });
  };

  const getURL = () => {
    return `${
      process.env.REACT_APP_CATERING_APP_LINK
    }/group-order/user/restaurant/${
      order?.attributes?.meals[0]?.attributes?.menu_code
    }/${order?.attributes?.invitation_link}${
      order?.attributes?.source_of_business === "restaurant"
        ? "?utm_source=odc"
        : ""
    }`;
  };

  const copyLinkToDashboard = () => {
    if (order?.attributes && defaultPaymentMethodId) {
      const updatedURL = getURL();

      navigator.clipboard.writeText(updatedURL);

      dispatch({ field: "showCopyLinkBadge", value: true });

      setTimeout(() => {
        dispatch({ field: "showCopyLinkBadge", value: false });
      }, 2000);
    } else {
      const updatedURL = getURL();

      navigator.clipboard.writeText(updatedURL);
      dispatch({ field: "missingPaymentMethod", value: true });
    }
  };

  const updateItem = (id, payload) => {
    store.userStore.setLoader(true);
    store?.mealManagementStore
      ?.updateUncheckedOutOrder(id, data?.attributes?.invitation_link, payload)
      .then(() => {
        store.userStore.setLoader(false);
        setEditItemModal(false);

        getOrder();
      });
  };

  const onClickRemoveAttendee = (attendee) => {
    dispatch({
      field: "attendeeToUpdate",
      value: attendee,
    });

    dispatch({
      field: "deleteOrderModalVisible",
      value: true,
    });
  };

  const removeAttendee = () => {
    const payload = {
      uncheckedout_order: {
        status: "cancelled",
      },
    };
    store.userStore.setLoader(true);
    store?.mealManagementStore
      ?.updateUncheckedOutOrder(
        attendeeToUpdate.id,
        data?.attributes?.invitation_link,
        payload
      )
      .then(() => {
        store.userStore.setLoader(false);

        dispatch({
          field: "deleteOrderModalVisible",
          value: null,
        });

        getOrder();
      });
  };

  const handleOnChange = (e) => {
    dispatch({ field: "orderItemsSearchText", value: e?.target?.value });
  };

  const isAbleToCheckout = () => {
    const minimumOrderPreferences =
      order?.attributes?.meals[0]?.attributes?.minimum_order_preferences;

    const sortedPreferences = minimumOrderPreferences?.sort(
      (a, b) => a.minimum_amount - b.minimum_amount
    );

    if (sortedPreferences?.length > 0) {
      return (
        order?.attributes?.meals[0]?.attributes?.grand_total >
        sortedPreferences[0]?.minimum_amount
      );
    }
    return false;
  };

  const minimumOrderAmount = () => {
    const minimumOrderPreferences =
      order?.attributes?.meals[0]?.attributes?.minimum_order_preferences;

    const sortedPreferences = minimumOrderPreferences?.sort(
      (a, b) => a.minimum_amount - b.minimum_amount
    );

    if (sortedPreferences?.length > 0) {
      return sortedPreferences[0]?.minimum_amount;
    }
    return 0;
  };

  const moveToCheckout = (checkoutType = "credit") => {
    const isPastDeadline = moment(
      order?.attributes?.meals[0]?.attributes?.cutoff,
      "YYYY-MM-DD hh:mm A"
    ).isBefore(moment());

    const deadlineString = moment(
      order?.attributes?.meals[0]?.attributes?.cutoff,
      deadlineFormat
    ).format(`${longDateFormatWithShortDay} ${timeFormat}`);

    if (isPastDeadline) {
      store.userStore.setErrorAlert(true, {
        title: "Deadline passed",
        body: `The deadline to place your order is ${deadlineString}. Please update the deadline in manage details to submit the order.`,
      });
    } else {
      dispatch({ field: "confirmationBeforeCheckout", value: checkoutType });
    }
  };

  const onClickCheckoutConfirmation = () => {
    navigate(
      `/group-order/checkout/${order?.id}${
        confirmationBeforeCheckout == "invoice" ? "?pay_with_invoice=true" : ""
      }`
    );
  };

  const isActiveOrder = () => {
    // return isPresent(
    //   order?.attributes?.meals[0]?.attributes?.uncheckedout_orders
    // );
    return (
      order?.attributes?.status === null ||
      order?.attributes?.status === "active"
    );
  };

  const isActiveWithInvoice = () => {
    return (
      (order?.attributes?.status === null ||
        order?.attributes?.status === "active") &&
      defaultLocation?.invoicePayments === "enabled"
    );
  };

  const getStatusString = () => {
    if (groupOrderStatus?.status == "placed") {
      if (groupOrderStatus?.shipment_status == "completed") {
        return "Your group order is complete.";
      } else if (groupOrderStatus?.shipment_status == "cancelled") {
        return (
          <span>
            Your group order {order?.attributes?.name} has been successfully
            cancelled and all orders placed by invitees have been removed.
            <button>View past orders.</button>
          </span>
        );
      } else if (
        groupOrderStatus?.shipment_status == "confirmed" &&
        groupOrderStatus?.eta?.delivery_eta == null &&
        groupOrderStatus?.eta?.delivery_started == null
      ) {
        return "Your group order has been confirmed.";
      } else if (
        groupOrderStatus?.shipment_status == "confirmed" &&
        groupOrderStatus?.eta?.delivery_eta == null &&
        groupOrderStatus?.eta?.delivery_started == false
      ) {
        return "Your group order is in progress.";
      } else if (
        groupOrderStatus?.shipment_status == "confirmed" &&
        groupOrderStatus?.eta?.delivery_eta
      ) {
        return `Your estimated time of arrival: ${getETAString()}`;
      } else if (
        groupOrderStatus?.shipment_status == "sent" ||
        groupOrderStatus?.shipment_status == "confirmed" ||
        groupOrderStatus?.shipment_status == "preview"
      ) {
        return "Your group order is in progress.";
      } else if (groupOrderStatus?.shipment_status == "pending") {
        return `Your group order ${order?.attributes?.name} has been processed and has been sent to the restaurant. `;
      }
    } else {
      switch (groupOrderStatus?.status) {
        case "active":
          return `Your order will be processed and sent automatically to the restaurant at 
          
          ${dateTime.formatDateWithTimzoneInfo(
            order?.attributes?.meals[0]?.attributes?.cutoff,
            smallDateFormat,
            order?.attributes?.tzinfo
          )}`;
        case "completed":
          return "Your order is complete.";
        case "cancelled_due_to_invalid_payment_method":
          return `${order?.attributes?.name} was cancelled because the payment could not be processed or you have not entered a payment method. Our team might be able to assist you in placing this order without losing the choices your guests have picked. Contact us. `;
        case "cancelled_due_to_low_order_amount":
          return `${order?.attributes?.name} was cancelled because the order did not meet the minimum order value. Our team may be able to assist you in placing this order without losing the selections your guests have made. Please contact us for support. Contact us.`;
        case "cancelled":
          return (
            <span>
              Your group order {order?.attributes?.name} has been successfully
              cancelled and all orders placed by invitees have been removed.
              <button>View past orders.</button>
            </span>
          );
        default:
          return "";
      }
    }
  };

  const getETAString = () => {
    if (groupOrderStatus?.eta?.delivery_eta) {
      let now = moment(new Date());
      let eta = moment(groupOrderStatus?.eta?.delivery_eta).utc();
      let duration = Math.floor(moment.duration(eta.diff(now)).asMinutes());
      if (duration < 5) return "Arriving";
      return `ETA ${getETA(eta, order?.attributes?.tzinfo)}`;
    }
  };

  const showCancelButton = () => {
    if (order?.attributes?.status == "active") return true;
    else {
      if (parseFloat(order.attributes.grand_total).toFixed(2) >= 500)
        return false;

      let dateTime = moment(
        order.attributes.order_date + " " + order.attributes.delivery_time,
        "YYYY-MM-DD hh:mm A"
      );

      let duration = moment.duration(dateTime.diff(moment()));
      let hours = duration.asHours();

      let sortedMinimumOrderPreferences =
        order?.attributes?.meals[0]?.attributes?.minimum_order_preferences?.sort(
          (a, b) => a.minimum_amount - b.minimum_amount
        );

      if (
        hours < calculateOrderCancellationHours(sortedMinimumOrderPreferences)
      )
        return false;

      return true;
    }
  };

  const calculateOrderCancellationHours = (array) => {
    let targetValue = order?.attributes?.subtotal;

    if (array.length === 0) return 0;

    if (array.length === 1) {
      return array[0]?.required_notice_time;
    }

    if (targetValue > array[array.length - 1].minimum_amount) {
      return array[array.length - 1].required_notice_time;
    }

    for (let i = 0; i < array.length - 1; i++) {
      let currentObj = array[i];
      let nextObj = array[i + 1];

      if (
        targetValue >= currentObj.minimum_amount &&
        targetValue <= nextObj.minimum_amount
      ) {
        return currentObj.required_notice_time;
      }
    }
  };

  const onCancelOrder = (id) => {
    let payload = {
      meal_series: {
        status: "cancelled",
        cancelled_by: "user",
      },
    };
    let params = {
      location_id: order?.attributes?.location_id,
    };

    store.userStore.setErrorAlert(true, { title: "response?.message" });
    return;
    store.userStore?.setLoader(true);
    store?.mealManagementStore
      ?.updateGroupOrder(order?.id, payload, params)
      .then((response) => {
        store.userStore?.setLoader(false);
        dispatch({
          field: "cancelOrderModalVisible",
          value: null,
        });
        store.userStore.setErrorAlert(true, { title: response?.message });
        getOrder();
      })
      .catch(() => {
        store.userStore?.setLoader(false);
      });
  };

  const onCancelGroupOrder = () => {
    let payload = {
      meal_series: {
        status: "cancelled",
        cancelled_by: "user",
      },
    };
    let params = {
      location_id: order?.attributes?.location_id,
    };

    store.userStore.setLoader(true);
    store?.mealManagementStore
      ?.updateGroupOrder(order?.id, payload, params)
      .then((response) => {
        store.userStore.setLoader(false);
        if (response?.attributes) {
          dispatch({
            field: "cancelOrderModalVisible",
            value: null,
          });
          getOrder();
        }
      });
  };

  const onClickCancelOrder = () => {
    if (order?.attributes?.order_id) {
      onCancelOrder(order?.attributes?.order_id);
    } else {
      onCancelGroupOrder();
    }
  };

  const getFilteredAttendees = (attendees) => {
    const filteredAttendees = attendees?.filter((attendee) => {
      const attendeeName =
        attendee?.attributes?.user_name?.toLowerCase() ||
        attendee?.attributes?.full_name?.toLowerCase();

      const matchesMenuItem = attendee?.group_order_items?.some(
        (groupOrderItem) => {
          const menuItemDisplayName =
            groupOrderItem?.attributes?.menu_item?.display_name?.toLowerCase();

          return menuItemDisplayName.includes(
            orderItemsSearchText?.toLowerCase()
          );
        }
      );

      const filterAttendeeByStatus =
        (hideCancelledOrder &&
          (attendee?.attributes?.status === "placed" ||
            attendee?.attributes?.status === "delivered" ||
            attendee?.attributes?.status === "confirmed" ||
            attendee?.attributes?.status === "pending")) ||
        !hideCancelledOrder;

      if (
        filterAttendeeByStatus &&
        (attendeeName.includes(orderItemsSearchText?.toLowerCase()) ||
          matchesMenuItem)
      ) {
        return attendee;
      }

      return false;
    });

    return filteredAttendees;
  };

  function setAdminCookie() {
    const cookieValue = "true";

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30); // Expires in 30 days

    const cookieString = `admin=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;

    document.cookie = cookieString;
  }

  const moveToOrderPage = () => {
    setAdminCookie();
    store.userStore.setLoader(true);

    const url = `${
      process.env.REACT_APP_CATERING_APP_LINK
    }/group-order/user/restaurant/${
      order?.attributes?.meals[0]?.attributes?.menu_code
    }/${order?.attributes?.invitation_link}?admin=true${
      order?.attributes?.source_of_business === "restaurant"
        ? "&utm_source=odc"
        : ""
    }`;
    store.userStore.setLoader(false);

    window.open(url, "_blank");
  };

  const generatePDF = () => {
    const pdfName = `${order?.attributes?.user_name}_${order?.attributes?.name}_${order?.attributes?.order_date}_${order?.attributes?.id}`;
    store?.groupOrderStore.generatePDF(order?.attributes?.slug, pdfName);
  };

  const totalDetails = (
    <div
      className={classNames({
        "flex flex-col w-full border border-background rounded-[24px] py-6 px-4": true,
        "opacity-50": orderStatus == "cancelled",
      })}
    >
      <div className="flex items-center">
        <span className="text-md2 font-inter-medium">
          {order?.attributes?.meals[0]?.attributes?.restaurant_name}
        </span>
      </div>

      <small className="text-secondary text-[12px] font-inter-medium mt-[8px]">
        • Minimum total for order: {priceFormatter(minimumOrderAmount())}
      </small>

      {!isAbleToCheckout() && (
        <div className="bg-[#F4F4F4] p-[12px] text-[12px] font-inter-normal text-dark-gray rounded-lg mt-[8px]">
          The minimum order total for this restaurant has not been met yet. Once
          the minimum is reached, you will be able to place the order or it will
          be placed automatically at the order deadline.
        </div>
      )}

      <div className="mt-5">
        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Food Total</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.meals[0]?.attributes?.subtotal)}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <div className="flex items-center">
            <span className="text-md">Service Fee</span>
            <TooltipNew
              grayIcon
              tooltipText="A service fee of five percent of total item charges is inclusive of all hungerhub charges"
              id="service-tooltip"
              backgroundColor="white"
              textColor="black"
              place="bottom"
            />
          </div>

          <span className="text-md">
            {priceFormatter(
              order?.attributes?.meals[0]?.attributes?.service_fee
            )}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <div className="flex items-center">
            <span className="text-md">Delivery</span>
            <TooltipNew
              grayIcon
              tooltipText="Your delivery cost is dependent on restaurant and proximity to your delivery address"
              id="delivery-tooltip"
              backgroundColor="white"
              textColor="black"
              place="bottom"
            />
          </div>

          <span className="text-md">
            {priceFormatter(
              order?.attributes?.meals[0]?.attributes?.delivery_fee
            )}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Tax</span>

          <span className="text-md">
            {priceFormatter(
              order?.attributes?.meals[0]?.attributes?.tax_amount
            )}
          </span>
        </div>

        <div className="flex w-full items-center justify-between mt-3">
          <span className="text-md">Tip</span>

          <span className="text-md">
            {priceFormatter(order?.attributes?.meals[0]?.attributes?.tip)}
          </span>
        </div>

        {isActiveOrder() && (
          <div className="w-full d-col items-end">
            <button
              onClick={() => setEditTipModal(true)}
              className="text-catering text-[16px] font-inter-medium"
            >
              Edit
            </button>
          </div>
        )}

        {order?.attributes?.discounted_amount > 0 && (
          <div className="flex w-full items-center justify-between mt-3">
            <span className="text-md">Discount</span>

            <span className="text-md">
              -{priceFormatter(order?.attributes?.discounted_amount)}
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-between mt-6 mb-16 md:mb-6 border-t border-border-gray pt-6">
        <span className="text-md">Total</span>

        <span className="text-md">
          {priceFormatter(order?.attributes?.meals[0]?.attributes?.grand_total)}
        </span>
      </div>

      {isActiveOrder() ? (
        <div className="bg-background p-2 rounded-md">
          <label className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer">
            <span className="ml-1 font-inter-regular text-sm w-40">
              Request cutlery
            </span>

            <input
              autoComplete="off"
              type="checkbox"
              className="form-checkbox"
              onChange={(e) =>
                store.groupOrderStore.updateCutlery(e.target.checked)
              }
              checked={cutlery}
            />
          </label>
        </div>
      ) : (
        order?.attributes?.cutlery && (
          <span className="font-inter-regular text-sm bg-background p-3 rounded-md w-full">
            Cutlery requested
          </span>
        )
      )}

      {isActiveOrder() && defaultPaymentMethodId && (
        <button
          onClick={() => moveToCheckout()}
          disabled={!isAbleToCheckout()}
          className={classNames({
            "btn-purple bg-catering mt-5 py-3 md:h-12": true,
            "hover:!bg-hh-accent-dark opacity-50 bg-accent-gray text-white":
              !isAbleToCheckout(),
          })}
        >
          <span className="text-sm font-inter-medium">
            Finalize now and pay with credit card
          </span>
        </button>
      )}

      {isActiveWithInvoice() && (
        <button
          onClick={() => moveToCheckout("invoice")}
          disabled={!isAbleToCheckout()}
          className={classNames({
            "btn-outline mt-[12px] py-3 md:h-12": true,
            "hover:bg-gray-200 text-accent-gray border-accent-gray":
              !isAbleToCheckout(),
          })}
        >
          <span className="text-sm font-inter-medium">
            Finalize now and pay with invoice
          </span>
        </button>
      )}
    </div>
  );

  const onClickManageDetails = () => {
    const isPastAdminDeadline = moment(
      order?.attributes?.admin_deadline,
      deadlineFormat
    ).isBefore(moment());

    if (isPastAdminDeadline) {
      let payload = {
        menu_id: order?.attributes?.menu_id,
        attendee: Math.abs(order?.attributes?.expected_attendees),
        budget_per_attendee: order?.attributes?.per_person_budget,
        selected_date: moment(order.attributes?.order_date).format(dateFormat),
        current_date_time: moment().format(dateTimeFormatLong),
        delivery_time: moment(
          order?.attributes?.delivery_time,
          "hh:mm A"
        ).format(timeFormat),
        admin: true,
        admin_date: moment(
          new Date(moment(order?.attributes?.admin_deadline, deadlineFormat))
        ).format(dateFormat),
      };

      store.userStore.setLoader(true);
      store?.groupOrderStore.getTimesForGroupOrder(payload).then((response) => {
        store.userStore.setLoader(false);

        if (response?.timeslots?.length == 0) {
          store.userStore?.setErrorAlert(true, {
            title: "Deadline Passed",
            body: "The deadline to submit this order has passed, please start a chat with our support team to see if the order can still be placed.",
          });
        } else {
          dispatch({ field: "detailsModalVisible", value: true });
        }
      });
    } else {
      dispatch({ field: "detailsModalVisible", value: true });
    }
  };

  const manageDetailsButton = (
    <button
      onClick={onClickManageDetails}
      className="btn-style bg-primary-dark hover:bg-hh-accent-dark items-center justify-center text-white text-md py-3 px-4 rounded-[8px] mr-2 font-inter-medium"
    >
      Manage details
    </button>
  );

  const isFutureDeliveryDate = order
    ? moment(
        `${order?.attributes?.order_date} ${order?.attributes?.delivery_time}`,
        deadlineFormat
      ).isAfter(moment())
    : false;

  const combinedOrders = [
    ...(order?.attributes?.meals[0]?.attributes?.uncheckedout_orders || []),
    ...(order?.attributes?.meals[0]?.attributes?.orders || []),
  ];

  return (
    <>
      {currentUser && order && (
        <div
          className="flex flex-col justify-center mx-auto p-5 md:p-[40px] md:pt-[32px]"
          style={{ maxWidth: "1440px" }}
        >
          <div className="flex">
            <Link href="/orders">
              <button
                className="flex items-center cursor-pointer"
                onClick={() => {
                  navigate("/home");
                }}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  style={{ width: 14, height: 14 }}
                />
                <span className="text-md text-left text-black-light ml-3">
                  Back to Orders
                </span>
              </button>
            </Link>
          </div>

          <div className="flex mt-8 bg-background px-[10px] py-[14px] items-center rounded-xl">
            <div className="mr-2">
              <span class="relative flex h-3 w-3">
                <span
                  className={classNames({
                    "animate-ping absolute inline-flex h-full w-full rounded-full opacity-100": true,
                    "bg-red-700": !defaultPaymentMethodId,
                    "bg-yellow-400": defaultPaymentMethodId,
                    "bg-green-700": order?.attributes?.status == "placed",
                  })}
                />
                <span
                  className={classNames({
                    "relative inline-flex rounded-full h-3 w-3": true,
                    "bg-red-300": !defaultPaymentMethodId,
                    "bg-yellow-500": defaultPaymentMethodId,
                    "bg-green-500": order?.attributes?.status == "placed",
                  })}
                />
              </span>
            </div>

            <span className="text-[16px] text-[#2D3958] font-inter-semibold">
              {defaultPaymentMethodId
                ? getStatusString()
                : "Please update your payment details to be able to finalize this order"}
              {!defaultPaymentMethodId && (
                <button
                  className="text-catering ml-1 font-inter-medium text-[16px]"
                  onClick={() => {
                    defaultLocation?.uncatering
                      ? navigate("/billing/payment-info")
                      : navigate("/billing/marketplace/payment-info");
                  }}
                >
                  Update payment details
                </button>
              )}
            </span>
          </div>

          <div
            className={`flex flex-col border border-[#DEE2E6] border-1 rounded-lg p-[12px] mt-[32px] sm:flex-row sm:items-center justify-between mt-6
          ${orderStatus === "cancelled" && "opacity-50"}
          `}
          >
            <div className="d-col w-full">
              <div className="d-row justify-between">
                <div className="flex flex-col md:flex-row md:items-center">
                  <span className="text-[24px] font-inter-semibold mr-3">
                    {order?.attributes?.name}
                  </span>

                  {isActiveOrder() && (
                    <button
                      type="button"
                      onClick={copyLinkToDashboard}
                      className="flex btn-style items-center mt-3 md:mt-0"
                    >
                      <img src={CopyLink} layout="fixed" />

                      <span className="text-hh-accent-light text-md font-inter-semibold ml-2">
                        Copy invitation link
                      </span>
                    </button>
                  )}
                </div>

                {showCancelButton() && (
                  <div className="flex justify-between mt-6 sm:mt-0">
                    <button
                      onClick={() =>
                        dispatch({
                          field: "cancelOrderModalVisible",
                          value: true,
                        })
                      }
                      className="btn-style mr-3 border-primary-dark border hover:bg-hh-accent-dark items-center justify-center text-md text-primary-dark hover:text-white py-3 px-4 rounded-[8px] font-inter-medium"
                    >
                      Cancel Order
                    </button>

                    {manageDetailsButton}
                  </div>
                )}

                {orderStatus !== "cancelled" && orderStatus !== "active" && (
                  <button
                    className="btn-purple bg-catering"
                    onClick={generatePDF}
                  >
                    Download PDF invoice
                  </button>
                )}
              </div>

              <div className="grid grid-cols-4 gap-4 mt-[16px]">
                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Deadline to order
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {dateTime.formatDateWithTimzoneInfo(
                      order?.attributes?.meals[0]?.attributes?.cutoff,
                      "ddd, MMMM Do, yyyy",
                      order?.attributes?.tzinfo
                    )}
                  </span>

                  <span className="text-[14px] font-inter-medium">
                    {dateTime.formatTimeWithFormat(
                      order?.attributes?.meals[0]?.attributes?.cutoff,
                      "hh:mm a",
                      order?.attributes?.tzinfo
                    )}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Delivery date
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {dateTime.formatDate(
                      order?.attributes?.meals[0]?.attributes?.meal_date,
                      "ddd, MMMM D, yyyy"
                    )}{" "}
                    {dateTime.checkAndGetTimezone(order?.attributes?.tzinfo)}
                  </span>

                  <span className="text-[14px] font-inter-medium">
                    {dateTime.formatTimeWithFormat(
                      order?.attributes?.meals[0]?.attributes?.target_time,
                      "hh:mm a",
                      order?.attributes?.tzinfo
                    )}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Point of contact
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {
                      order.attributes?.address?.attributes
                        ?.delivery_contact_name
                    }
                  </span>

                  <span className="text-[14px] font-inter-medium">
                    {
                      order.attributes?.address?.attributes
                        ?.delivery_contact_phone
                    }
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Budget per person
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {priceFormatter(order.attributes?.subsidy)}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Delivery address
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {order.attributes?.address?.attributes?.complete_address}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-[14px] font-inter-semibold text-black-light">
                    Delivery instructions
                  </span>
                  <span className="text-[14px] font-inter-medium">
                    {
                      order.attributes?.address?.attributes
                        ?.delivery_instructions
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full mt-8">
            <div className="flex md:hidden">{totalDetails}</div>

            <div className="flex flex-col w-full md:w-[60%]">
              <div className="flex flex-col sm:flex-row justify-between gap-2 items-center w-full">
                <button
                  disabled={!isActiveOrder()}
                  onClick={moveToOrderPage}
                  className={classNames({
                    "btn-style w-full sm:w-auto  min-w-[110px] h-12 text-white font-inter-medium text-md rounded-[8px] py-3 px-4 items-center justify-center mr-2": true,
                    "bg-gray-400 opacity-50": !isActiveOrder(),
                    "bg-primary-dark hover:bg-hh-accent-dark": isActiveOrder(),
                  })}
                >
                  Add items
                </button>

                <SearchBar
                  value={orderItemsSearchText}
                  handleOnChange={handleOnChange}
                  placeholder="Search"
                  className="w-full !mr-0 mt-6 sm:mt-0"
                />

                <div
                  className={classNames({
                    "lg:w-2/4 md:w-1/3 w-full ml-1 bg-light-grey rounded-full px-2 py-[9px] h-12 font-inter-normal border border-catering items-center justify-center inline-flex gap-1.5": true,
                    "opacity-20": orderStatus == "cancelled",
                  })}
                >
                  <small className="text-catering text-[15px] leading-tight font-inter-normal">
                    Hide cancelled orders
                  </small>

                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={hideCancelledOrder}
                      className="sr-only peer"
                      onChange={(e) => setHideCancelledOrder(e.target.checked)}
                    />
                    <div className=" w-7 h-4 p-0.5 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:start-[2px] after:bg-white after:w-3 after:h-3 after:shadow after:border after:rounded-full after:transition-all  peer-checked:bg-indigo-500"></div>
                  </label>
                </div>
              </div>

              <div className="flex mt-4 items-center bg-light-grey rounded-[4px] px-3 py-6">
                <span className="w-1/3 sm:w-2/12 text-sm font-inter-medium">
                  Name
                </span>

                <span className="hidden sm:flex sm:w-[100px] text-sm font-inter-medium ml-2">
                  QTY
                </span>

                <span className="w-2/3 sm:w-4/12 text-sm font-inter-medium">
                  Items in order
                </span>

                <span className="hidden sm:flex sm:w-2/12 text-sm font-inter-medium">
                  Status
                </span>

                <span className="hidden sm:flex sm:w-2/12 text-sm font-inter-medium">
                  Food Total
                </span>
              </div>

              {getFilteredAttendees(combinedOrders)?.map((attendee, index) => (
                <div
                  key={`attendee-order-${index}`}
                  className={classNames({
                    "flex w-full my-4 px-2": true,
                    "opacity-50": attendee?.attributes?.status === "cancelled",
                  })}
                >
                  <div className="flex flex-col w-1/3 items-start sm:w-2/12">
                    <span className="text-md font-inter-semibold mb-2">
                      {attendee?.attributes?.user_name ||
                        attendee?.attributes?.full_name}
                    </span>

                    {isActiveOrder() &&
                      attendee?.attributes?.status !== "cancelled" && (
                        <button
                          onClick={() => {
                            dispatch({
                              field: "attendeeToUpdate",
                              value: attendee,
                            });

                            setEditItem(
                              attendee?.attributes?.order_items?.length > 1
                                ? attendee?.attributes?.order_items
                                : attendee?.attributes?.order_items[0]
                            );
                            setEditItemModal(true);
                          }}
                          className="btn-blue text-catering font-inter-medium text-[16px]"
                        >
                          Edit order
                        </button>
                      )}
                  </div>

                  <div className="flex flex-col w-2/3 sm:w-6/12">
                    {attendee?.attributes?.order_items?.map(
                      (orderItem, index) => (
                        <div className="flex">
                          <div className="hidden sm:flex sm:w-[100px] ml-2">
                            <span className="text-md font-inter-semibold text-center">
                              {orderItem?.attributes?.quantity}
                            </span>
                          </div>

                          <div
                            key={`${attendee?.first_name}-order-item-${index}`}
                            className="flex w-full flex-col mb-6 mr-2"
                          >
                            <div className="flex w-full">
                              <div className="flex flex-col ml-[6px]">
                                <span className="text-sm font-inter-semibold">
                                  <EmbedHTML
                                    text={
                                      orderItem?.attributes?.menu_item
                                        ?.display_name_with_html
                                    }
                                    className="text-primary-black capitalize"
                                  />
                                </span>

                                {orderItem?.attributes?.order_item_options?.map(
                                  (option, index) => (
                                    <div className="flex" key={option.id}>
                                      <span className="flex flex-col text-sm">
                                        <EmbedHTML
                                          isRow
                                          text={
                                            option?.attributes
                                              ?.option_group_display_name_with_html
                                          }
                                          appendClass="mr-1"
                                          className="text-primary-black font-inter-medium"
                                        />

                                        <EmbedHTML
                                          text={
                                            option?.attributes
                                              ?.option_display_name_with_html
                                          }
                                          className="text-primary-black"
                                        />
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>

                            {orderItem?.attributes?.special_instructions && (
                              <div className="flex items-center ">
                                <div
                                  className="flex"
                                  style={{ width: 12, height: 12 }}
                                >
                                  <FontAwesomeIcon
                                    icon={faComment}
                                    size="1x"
                                    className="small-icon"
                                  />
                                </div>

                                <span className="text-sm ml-2">
                                  Instructions:{" "}
                                  <span className="font-inter-medium">
                                    {
                                      orderItem?.attributes
                                        ?.special_instructions
                                    }
                                  </span>
                                </span>
                              </div>
                            )}

                            <div className="flex sm:hidden mt-6">
                              {isActiveOrder() && (
                                <TrashButton
                                  onClick={() =>
                                    onClickRemoveAttendee(attendee)
                                  }
                                />
                              )}

                              <span className="text-attention text-sm ml-2">
                                Delete Order
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className="hidden sm:flex sm:w-2/12 text-md">
                    {toTitleCase(
                      getOrderStatusForAttendee(
                        attendee?.attributes?.status,
                        order?.attributes?.status
                      )
                    )}
                  </div>

                  <div className="hidden sm:flex sm:w-2/12 text-md">
                    {priceFormatter(attendee?.attributes?.subtotal)}
                  </div>
                </div>
              ))}

              {!order?.attributes?.meals[0].attributes.uncheckedout_orders
                ?.length > 0 &&
                !order?.attributes?.meals[0].attributes.orders?.length > 0 && (
                  <small className="self-center font-inter-semibold text-base text-[#566985] mt-[80px]">
                    No orders yet. Make sure to share the link with your guests.
                  </small>
                )}
            </div>

            <div className="hidden md:flex w-[40%] ml-4">{totalDetails}</div>
          </div>

          {detailsModalVisible && (
            <ManageOrderDetailsModal
              order={order}
              visible={detailsModalVisible}
              getOrder={getOrder}
              setModal={(value) =>
                dispatch({ field: "detailsModalVisible", value })
              }
            />
          )}

          {editItemModal && (
            <AddGroupItemModal
              modalKey={modalKey}
              edit={true}
              editItem={editItem}
              visible={editItemModal}
              setModal={setEditItemModal}
              updateItem={updateItem}
              attendeeToUpdate={attendeeToUpdate}
              onClickRemoveAttendee={onClickRemoveAttendee}
              showEditSection={true}
              setModalKey={setModalKey}
            />
          )}

          {editTipModal && (
            <EditTipModal
              visible={editTipModal}
              setEditTipModal={setEditTipModal}
              data={data}
              getOrder={getOrder}
            />
          )}

          {cancelOrderModalVisible && (
            <ConfirmationModal
              visible={cancelOrderModalVisible}
              setModal={(value) =>
                dispatch({ field: "cancelOrderModalVisible", value })
              }
              body="Cancelling the group order will permanently cancel all items ordered by your invitees and delete your order before it is sent to the restaurant."
              title={`Are you sure you want to cancel ${order?.attributes?.name}?`}
              leftButtonText="Go Back"
              onClickLeftButton={() =>
                dispatch({ field: "cancelOrderModalVisible", value: null })
              }
              rightButtonText={"Yes, Cancel group Order"}
              onClickRightButton={onClickCancelOrder}
              groupOrderAlert={true}
            />
          )}

          {deleteOrderModalVisible && (
            <ConfirmationModal
              visible={deleteOrderModalVisible}
              setModal={(value) =>
                dispatch({ field: "deleteOrderModalVisible", value })
              }
              title="Are you sure you want to delete this meal?"
              body={`This will cancel the meal placed by ${attendeeToUpdate?.attributes?.full_name} and remove it from your group order.?`}
              leftButtonText="Cancel"
              onClickLeftButton={() =>
                dispatch({ field: "deleteOrderModalVisible", value: null })
              }
              rightButtonText="Yes, Delete Meal"
              onClickRightButton={removeAttendee}
              groupOrderAlert={true}
            />
          )}
        </div>
      )}
      {showCopyLinkBadge && (
        <ConfirmationBadge applyLeft text={"Link copied succesfully"} />
      )}

      {missingPaymentMethod && (
        <MissingPaymentMethodModal
          visible={missingPaymentMethod}
          setModal={(value) =>
            dispatch({ field: "missingPaymentMethod", value })
          }
        />
      )}

      {confirmationBeforeCheckout && (
        <ConfirmationModal
          visible={confirmationBeforeCheckout}
          setModal={(value) =>
            dispatch({ field: "confirmationBeforeCheckout", value })
          }
          body={`If you place your order now, no additional guests can join. The order deadline is ${dateTime.formatDateWithTimzoneInfo(
            order?.attributes?.meals[0]?.attributes?.cutoff,
            "ddd, MMMM Do, yyyy",
            order?.attributes?.tzinfo
          )} ${dateTime.formatTimeWithFormat(
            order?.attributes?.meals[0]?.attributes?.cutoff,
            "hh:mm a",
            order?.attributes?.tzinfo
          )}, after which the group order will be automatically processed. `}
          title="Are you sure you want to place the order now?"
          leftButtonText="Cancel"
          onClickLeftButton={() =>
            dispatch({ field: "confirmationBeforeCheckout", value: null })
          }
          rightButtonText="Continue"
          onClickRightButton={onClickCheckoutConfirmation}
          groupOrderAlert={true}
        />
      )}
    </>
  );
};

export default observer(GroupOrderDetails);
