import { useState, useContext, useEffect } from "react";
import { NotificationBanner, WeeklyOrders, WeekPicker } from "components";
import { refreshIcon } from "assets/img";
import { ActiveMeals } from "./index";
import { MobXProviderContext, observer } from "mobx-react";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { useLocation, useNavigate } from "react-router-dom";

function Home() {
  const { mealManagementStore, homeStore, userStore } =
    useContext(MobXProviderContext);
  const defaultLocation = userStore.defaultLocation;
  const activeMeals = homeStore.activeMeals;
  const mealsByWeek = homeStore.mealsByWeek;
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDailyMeals(dayjs());
  }, []);

  useEffect(() => {
    const startOfWeek = dayjs().tz(defaultLocation?.tzinfo).startOf("week");
    const endOfWeek = dayjs().tz(defaultLocation?.tzinfo).endOf("week");

    homeStore.fetchActiveMealsForWeek(
      {
        start_date: startOfWeek.format("YYYY-MM-DD"),
        end_date: endOfWeek.format("YYYY-MM-DD"),
      },
      defaultLocation?.organizationId
    );
  }, [selectedDate]);

  const fetchDailyMeals = (date = selectedDate) => {
    const startDate = date.format("YYYY-MM-DD");
    const endDate = date.format("YYYY-MM-DD");
    homeStore.fetchActiveMeals(
      { start_date: startDate, end_date: endDate },
      defaultLocation?.organizationId
    );
  };

  const onDateChange = (selected) => {
    setSelectedDate(selected);
    fetchDailyMeals(selected);
  };

  const isUnderReview = activeMeals?.some(
    (meal) => meal?.attributes?.under_review == true
  );

  return (
    <div className="rounded-lg p-5 flex flex-col min-w-full">
      {isUnderReview && (
        <NotificationBanner
          message="Your uncatering request is under review by hungerhub, this message will disappear once changes have been updated."
          messageType="warning"
          visible={isUnderReview}
          hideClose={true}
          messageLink={isUnderReview && "Contact us"}
          abilityToDismiss={false}
          handleLinkClick={() => userStore.setFreshChatOpen(true)}
        />
      )}

      <div className="hidden lg:flex mt-[32px] flex-row justify-between">
        <WeekPicker dayPicker={true} onDateChange={onDateChange} />

        <button
          onClick={() => fetchDailyMeals()}
          className="flex flex-row bg-light-gray items-center px-4 py-[4px] rounded-lg ml-6"
        >
          <img className="h-4 w-4 mr-[8px]" src={refreshIcon} />
          <small className="text-dark-gray font-inter-regular text-sm">
            Refresh
          </small>
        </button>
      </div>
      <div className="hidden lg:hidden md:block mt-[32px]  ">
        <div className="flex flex-row w-full">
          <button className="flex flex-row bg-light-gray items-center px-4 py-[4px] rounded-lg ml-6">
            <img className="h-4 w-4 mr-[8px]" src={refreshIcon} />
            <small className="text-dark-gray font-inter-regular text-sm">
              Refresh
            </small>
          </button>
        </div>
        <div className="mt-[32px]">
          <WeekPicker dayPicker={true} width="w-full" />
        </div>
      </div>

      <ActiveMeals
        data={activeMeals}
        locationId={defaultLocation?.organizationId}
      />
      <div className="mt-[30px] mb-[24px] d-row justify-between">
        <h2 className="font-inter-bold text-dark-gray text-[20px]">
          This week orders
        </h2>

        <button
          onClick={() => navigate("/meal-management")}
          className="btn-blue-accent rounded-lg"
        >
          Manage meal Plans
        </button>
      </div>

      <WeeklyOrders
        data={mealsByWeek}
        locationId={defaultLocation?.organizationId}
        navigate={navigate}
      />
    </div>
  );
}

export default observer(Home);
