import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useContext, useEffect, useReducer, useState } from "react";
import SearchBar from "components/searchBar";
import { MobXProviderContext } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faComment } from "@fortawesome/free-solid-svg-icons";
import { Takeaway, Chinese, Wooden } from "assets/img";
import { priceFormatter } from "utils/helpers/validation";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import EmbedHTML from "components/shared/embedHTML";
import { useParams } from "react-router-dom";
import dateTime from "utils/helpers/dateTime";
import { formattedAddres } from "utils/helpers/string";
import { ViewBag } from "alerts";

const ViewOrder = ({ hostname }) => {
  const initialState = {
    orderItemsSearchText: "",
    detailsModalVisible: false,
    cancelOrderModalVisible: null,
    deleteOrderModalVisible: null,
    editOrderItemsModal: false,
    editNameAttachedModalVisible: false,
    attendeeToUpdate: null,
    showCopyLinkBadge: false,
    order: null,
    shipmentDetail: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const [viewBagModal, setViewBagModal] = useState(false);
  const [bagId, setBagId] = useState(null);
  const router = {};
  let { locationID, defaultLocation } = userStore ?? { name: "abdul" };
  let { cutlery, currentGroupOrder } = groupOrderStore ?? {};
  let { orderItemsSearchText, attendeeToUpdate, shipmentDetail } = provider;
  const { shipmentId } = useParams();

  const navigate = useNavigate();

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["shipmentDetail", locationID, shipmentId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.homeStore.fetchUncateringShipmentDetail(
        shipmentId,
        locationID
      );
    },
  });

  useEffect(() => {
    dispatch({ field: "shipmentDetail", value: data });
  }, [data]);

  const handleOnChange = (e) => {
    dispatch({ field: "orderItemsSearchText", value: e?.target?.value });
  };

  return (
    <div className="w-full">
      <div className="flex flex-col justify-center mx-auto p-5 md:p-[40px] md:pt-[32px]">
        <div className="flex">
          <button
            className="flex items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ width: 14, height: 14 }}
            />
            <span className="text-md text-left font-inter-medium text-dark-gray ml-2">
              Back
            </span>
          </button>
        </div>

        <strong className="font-inter-semibold text-[32px] text-dark-gray my-[24px]">
          {shipmentDetail?.attributes?.restaurant_name}
        </strong>

        <div className="d-row justify-start items-center text-sm mb-4 bg-light-gray p-[12px] h-[84px] rounded-lg">
          <div className="d-col justify-center min-w-[215px]">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Date
            </small>
            <small className="font-inter-medium text-[16px] text-dark-gray">
              {dateTime.formatDate(
                shipmentDetail?.attributes?.meal_date,
                "ddd, MMMM Do, yyyy"
              )}{" "}
              {dateTime.checkAndGetTimezone(shipmentDetail?.attributes?.tzinfo)}
            </small>
          </div>

          <div className="w-[0.5px] h-full bg-accent-gray mx-[16px]" />

          <div className="d-col justify-center min-w-[215px]">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Meal{" "}
            </small>
            <small className="font-inter-medium text-[16px] text-dark-gray">
              {shipmentDetail?.attributes?.meal_name}
            </small>
          </div>

          <div className="w-[0.5px] h-full bg-accent-gray mx-[16px]" />

          <div className="d-col justify-center min-w-[95px]">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Status{" "}
            </small>
            <small className="font-inter-medium text-[16px] text-dark-gray">
              {shipmentDetail?.attributes?.status == "pending"
                ? "Placed"
                : shipmentDetail?.attributes?.status}
            </small>
          </div>

          <div className="w-[0.5px] h-full bg-accent-gray mx-[16px]" />

          <div className="d-col justify-center">
            <small className="font-inter-regular text-[14px] text-dark-gray">
              Delivery Address{" "}
            </small>
            <small className="font-inter-medium text-[16px] text-dark-gray">
              {shipmentDetail?.attributes?.address &&
                formattedAddres(
                  shipmentDetail?.attributes?.address?.attributes
                )}
            </small>
          </div>
        </div>

        <div className="px-[16px] py-[24px] d-row justify-between bg-light-gray rounded-t-[0px] rounded-b-[24px]">
          <div className="d-col">
            <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
              Food total
            </small>
            <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
              Tip
            </small>
            <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
              Tax
            </small>
            <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
              Grand total
            </small>
          </div>

          <div className="d-col">
            <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
              {priceFormatter(shipmentDetail?.attributes?.food_total)}
            </small>
            <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
              {priceFormatter(shipmentDetail?.attributes?.tip)}
            </small>
            <small className="mt-[14px] font-inter-normal text-[14px] text-dark-gray">
              {priceFormatter(shipmentDetail?.attributes?.tax_amount)}
            </small>
            <small className="mt-[14px] font-inter-semibold text-[14px] text-dark-gray">
              {priceFormatter(shipmentDetail?.attributes?.grand_total)}
            </small>
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full mt-8">
          <div className="flex flex-col w-full ">
            <div className="flex flex-col lg:flex-row justify-between lg:items-center w-full md:gap-9">
              <div className="d-row justify-between">
                <div className="bg-light-gray rounded-lg d-row p-[12px] min-w-[175px] mr-[12px]">
                  <img
                    src={Takeaway}
                    className="w-[38px] h-[38px] object-contain mr-[16px]"
                  />
                  <div className="d-col">
                    <small className="font-inter-regular text-[16px] text-dark-gray">
                      Bags
                    </small>
                    <small className="text-dark-gray text-[20px] font-inter-semibold">
                      {shipmentDetail?.attributes?.bags_count}
                    </small>
                  </div>
                </div>

                <div className="bg-light-gray rounded-lg d-row p-[12px] min-w-[175px] mr-[12px]">
                  <img
                    src={Chinese}
                    className="w-[38px] h-[38px] object-contain mr-[16px]"
                  />
                  <div className="d-col">
                    <small className="font-inter-regular text-[16px] text-dark-gray">
                      Items
                    </small>
                    <small className="text-dark-gray text-[20px] font-inter-semibold">
                      {shipmentDetail?.attributes?.items_count}
                    </small>
                  </div>
                </div>

                <div className="bg-light-gray rounded-lg d-row p-[12px] min-w-[175px]">
                  <img
                    src={Wooden}
                    className="w-[38px] h-[38px] object-contain mr-[16px]"
                  />
                  <div className="d-col">
                    <small className="font-inter-regular text-[16px] text-dark-gray">
                      Cutlery
                    </small>
                    <small className="text-dark-gray text-[20px] font-inter-semibold">
                      {shipmentDetail?.attributes?.cutlery_count}
                    </small>
                  </div>
                </div>
              </div>

              <div className="d-row justify-between">
                <SearchBar
                  value={orderItemsSearchText}
                  handleOnChange={handleOnChange}
                  placeholder="Search"
                  className="w-full !mr-0 mt-6 sm:mt-0"
                  width="lg:w-[274px] md:w-4/5"
                />

                <button
                  className="btn-blue-accent ml-[12px] w-[123px] text-[13px] px-[2px]"
                  onClick={() =>
                    store.mealManagementStore?.generatePDF(
                      shipmentDetail?.attributes?.orders[0]?.id,
                      defaultLocation?.organizationId,
                      shipmentDetail?.attributes?.restaurant_name
                    )
                  }
                >
                  Export PDF
                </button>
              </div>
            </div>

            <div className="flex mt-[24px] items-center bg-light-grey rounded-[4px] bg-light-gray px-3 py-6">
              <span className="w-1/3 sm:w-2/12 text-[14px] font-inter-semibold">
                Name
              </span>

              <div className="w-2/3 flex flex-row pr-[6%] justify-between sm:w-6/12">
                <small className="text-[14px] w-full font-inter-semibold">
                  Item
                </small>

                <small className="text-[14px] w-[107px] font-inter-semibold">
                  Food Total
                </small>
              </div>

              <span className="hidden sm:flex sm:w-2/12 text-[14px] font-inter-semibold">
                Order Total
              </span>

              <span className="hidden sm:flex sm:w-2/12 text-[14px] font-inter-semibold">
                Cutlery
              </span>

              <span className="hidden sm:flex sm:w-2/12 text-[14px] font-inter-semibold">
                Bag#
              </span>

              <span className="hidden sm:flex w-2/12 text-[14px] font-inter-semibold"></span>
            </div>

            {shipmentDetail?.attributes?.orders?.map((attendee, index) => (
              <div
                key={`attendee-order-${index}`}
                className={`flex w-full items-center my-4 py-[16px] px-2 ${
                  index % 2 != 0 ? "bg-[#F7F9FF]" : "bg-white"
                }`}
              >
                <div className="flex flex-col w-1/3 sm:w-2/12">
                  <span className="text-dark-gray text-[16px] font-inter-semibold">
                    {attendee?.first_name} {attendee?.last_name}
                  </span>
                </div>

                <div className="flex flex-col w-2/3 sm:w-6/12 pr-[6%]">
                  {attendee?.order_items?.map((orderItem, index, arr) => (
                    <div className="flex w-full">
                      <div
                        key={`${attendee?.first_name}-order-item-${index}`}
                        className="flex w-full flex-col mt-2"
                      >
                        <div className="flex w-full">
                          <div className="flex flex-col ml-[6px]">
                            <span className="text-[14px] font-inter-semibold text-dark-gray">
                              <EmbedHTML
                                text={
                                  orderItem?.attributes?.menu_item
                                    ?.display_name_with_html
                                }
                                className="text-primary-black capitalize"
                              />
                            </span>

                            {orderItem?.attributes?.order_item_options?.map(
                              (option, index) => (
                                <div className="flex" key={option.id}>
                                  <div className="flex flex-row">
                                    <EmbedHTML
                                      isRow
                                      text={
                                        option?.attributes
                                          ?.option_group_display_name_with_html +
                                        ": "
                                      }
                                      appendClass="mr-1"
                                      append
                                      className="text-primary-black text-[14px] font-inter-regular text-dark-gray"
                                    />

                                    <EmbedHTML
                                      text={
                                        option?.attributes
                                          ?.option_display_name_with_html
                                      }
                                      className="text-primary-black text-[14px] font-inter-regular text-dark-gray"
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        {orderItem?.attributes?.special_instructions && (
                          <div className="flex items-center">
                            <div
                              className="flex"
                              style={{ width: 12, height: 12 }}
                            >
                              <FontAwesomeIcon
                                icon={faComment}
                                className="w-[16px] h-[16px]"
                              />
                            </div>

                            <span className="text-sm ml-2">
                              Instructions:{" "}
                              <span className="font-inter-medium text-dark-gray font-inter-medium text-[14px]">
                                {orderItem?.attributes?.special_instructions}
                              </span>
                            </span>
                          </div>
                        )}

                        {index != arr?.length - 1 && (
                          <div className="bg-accent-gray w-[132px] h-[0.5px] my-[12px]" />
                        )}
                      </div>

                      <div className="w-[107px] flex flex-col text-left mt-2">
                        <small className="text-[14px] font-inter-semibold text-dark-gray">
                          {priceFormatter(orderItem?.attributes?.total_price)}
                        </small>

                        {orderItem?.attributes?.order_item_options?.map(
                          (option, index) => (
                            <div className="flex" key={option.id}>
                              <span className="flex flex-row">
                                <small className="text-primary-black text-[14px] font-inter-regular text-dark-gray">
                                  +{priceFormatter(option?.attributes?.price)}
                                </small>
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="items-start sm:flex sm:w-2/12 d-col justify-center text-md">
                  <small className="font-inter-medium text-[14px] tex-dark-gray">
                    Tax: {priceFormatter(attendee?.tax)}
                  </small>
                  <small className="font-inter-medium text-[14px] tex-dark-gray">
                    TIp: {priceFormatter(attendee?.tip)}
                  </small>
                  <small className="font-inter-medium text-[14px] tex-dark-gray">
                    Paid by user: {priceFormatter(attendee?.paid_by_user)}
                  </small>
                  <small className="font-inter-medium text-[14px] tex-dark-gray">
                    Paid by office: {priceFormatter(attendee?.paid_by_office)}
                  </small>
                </div>
                <div className="items-center sm:flex sm:w-2/12 font-inter-medium text-[14px] text-[16px] text-[#2B313C] justify-start text-md">
                  {attendee?.cutlery ? "Yes" : "No"}
                </div>
                <div className="items-center sm:flex sm:w-2/12 justify-start text-md font-inter-medium text-[14px] text-[16px] text-[#2B313C]">
                  {attendee?.bag_number}
                </div>
                <div className="hidden sm:flex justify-start items-center sm:w-2/12">
                  <button
                    onClick={() => {
                      setBagId(attendee?.id);
                      setViewBagModal(true);
                    }}
                    className="btn-outline"
                  >
                    View bag
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {viewBagModal && (
        <ViewBag
          visible={viewBagModal}
          bagId={bagId}
          setModal={setViewBagModal}
        />
      )}
    </div>
  );
};

export default observer(ViewOrder);
