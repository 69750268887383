import React, { useState, useEffect, useReducer, useContext } from "react";
import classNames from "classnames";
import { NotFound, SearchBar } from "components";
import { customStylesSubsidy } from "utils/helpers/styles";
import Select from "react-select";
import { UploadCSV, CreateTeam } from "alerts";
import { MobXProviderContext, observer } from "mobx-react";
import { toJS } from "mobx";
import {
  FolderOpenIcon,
  UploadFileIcon,
  CrossImg,
  EditPencilIcon,
} from "assets/img";
import { isPresent } from "utils/helpers/array";
import AddUserToTeam from "alerts/addUserToTeam";
import TooltipNew from "components/Tooltip";
import BulkUserEdit from "alerts/bulkUserEdit";
import CsvResponseModal from "components/CsvResponseModal";

function UncateringTeam() {
  const initialState = {
    teams: [],
    selectedTeam: null,
    teamAdmins: [],
    teamUsers: [],
    teamOptions: [],
    searchText: "",
    selectedMeal: null,
    selectedStatus: null,
    selectedRole: null,
    filteredTeams: [],
    filteredTeamUsers: [],
    mealOptions: null,
    teamMealOptions: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [uploadCSV, setUploadCSV] = useState(false);
  const [createTeam, setCreateTeam] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(true);
  const [addUser, setAddUser] = useState(false);
  const [bulkUsers, setBulkUsers] = useState([]);
  const [editTeam, setEditTeam] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editBulkUsers, setEditBulkUsers] = useState(false);
  const [teamAdded, setTeamAdded] = useState(false);
  const [provider, dispatch] = useReducer(reducer, initialState);
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  let { defaultLocation } = userStore;
  let {
    teams,
    selectedTeam,
    teamAdmins,
    teamUsers,
    teamOptions,
    filteredTeams,
    filteredTeamUsers,
  } = provider;
  const [errors, setErrors] = useState([]);
  const [recordError, setRecordError] = useState(false);
  const [isSuccessResponse, setIsSuccessResponse] = useState(false);
  const [isErrorResponse, setIsErrorResponse] = useState(false);

  const statusOptions = [
    { label: "Sent", value: "Sent" },
    { label: "Activated", value: "Activated" },
  ];

  const UserRoleOptions = [
    { label: "Account Admin", value: "Account admin" },
    { label: "Team Admin", value: "Team admin" },
    { label: "User", value: "User" },
  ];

  useEffect(() => {
    store.userStore.setLoader(true);

    store.mealManagementStore
      .fetchMealPlans(defaultLocation?.organizationId, {
        service_eq: "uncatering",
      })
      .then((response) => {
        const options = response?.map((meal) => {
          return {
            label: meal?.attributes?.name,
            value: meal?.id,
          };
        });
        dispatch({
          field: "mealOptions",
          value: options,
        });
      });

    store.mealManagementStore
      ?.fetchTeams({
        location_id: defaultLocation?.organizationId,
        employee_id: defaultLocation?.value,
      })
      .then((response) => {
        if (isPresent(response?.data)) {
          dispatch({ field: "teams", value: response?.data });
          dispatch({ field: "filteredTeams", value: response?.data });

          const teamOptions = response?.data?.map((team) => {
            return {
              value: team.id,
              label: team?.attributes?.name,
            };
          });
          dispatch({ field: "teamOptions", value: teamOptions });
          filterTeams(response?.data);
        }
      });

    store.userStore.setLoader(false);
  }, [defaultLocation.organizationId, userAdded, teamAdded]);

  useEffect(() => {
    filterTeams(teams);
  }, [showAllUsers, selectedTeam]);

  useEffect(() => {
    const filteredTeam = teams?.map((team) => ({
      ...team,
      attributes: {
        ...team.attributes,
        admins: team.attributes.admins?.filter((admin) => {
          const roleMatches =
            !provider.selectedRole ||
            admin?.attributes?.role == provider.selectedRole?.value;

          const statusMatches =
            !provider.selectedStatus ||
            admin?.attributes?.status == provider.selectedStatus?.value;

          const mealMatches =
            !provider.selectedMeal ||
            admin?.attributes?.meals?.some(
              (meal) =>
                meal.toLowerCase() == provider.selectedMeal?.label.toLowerCase()
            );

          const searchMatches =
            !provider.searchText ||
            admin?.attributes?.first_name
              .toLowerCase()
              .includes(provider.searchText.toLowerCase()) ||
            admin?.attributes?.last_name
              .toLowerCase()
              .includes(provider.searchText.toLowerCase()) ||
            admin?.attributes?.email
              .toLowerCase()
              .includes(provider.searchText.toLowerCase());

          return roleMatches && statusMatches && searchMatches && mealMatches;
        }),

        employees: team.attributes.employees?.filter((employee) => {
          const roleMatches =
            !provider.selectedRole ||
            employee?.attributes?.role == provider.selectedRole?.value;

          const statusMatches =
            !provider.selectedStatus ||
            employee?.attributes?.status == provider.selectedStatus?.value;

          const mealMatches =
            !provider.selectedMeal ||
            employee?.attributes?.meals?.some(
              (meal) =>
                meal.toLowerCase() == provider.selectedMeal?.label.toLowerCase()
            );

          const searchMatches =
            !provider.searchText ||
            employee?.attributes?.first_name
              .toLowerCase()
              .includes(provider.searchText.toLowerCase()) ||
            employee?.attributes?.last_name
              .toLowerCase()
              .includes(provider.searchText.toLowerCase()) ||
            employee?.attributes?.email
              .toLowerCase()
              .includes(provider.searchText.toLowerCase());

          return roleMatches && statusMatches && searchMatches && mealMatches;
        }),
      },
    }));

    const filteredUserTeams = teamUsers?.filter((user) => {
      const statusMatches =
        !provider.selectedStatus ||
        user?.attributes?.status == provider.selectedStatus?.value;

      const mealMatches =
        !provider.selectedMeal ||
        user?.attributes?.meals?.some(
          (meal) =>
            meal.toLowerCase() == provider.selectedMeal?.label.toLowerCase()
        );

      const searchMatches =
        !provider.searchText ||
        user?.attributes?.first_name
          .toLowerCase()
          .includes(provider.searchText.toLowerCase()) ||
        user?.attributes?.last_name
          .toLowerCase()
          .includes(provider.searchText.toLowerCase()) ||
        user?.attributes?.email
          .toLowerCase()
          .includes(provider.searchText.toLowerCase());

      return statusMatches && mealMatches && searchMatches;
    });

    dispatch({ field: "filteredTeams", value: filteredTeam });
    dispatch({ field: "filteredTeamUsers", value: filteredUserTeams });
  }, [
    provider.selectedRole,
    provider.selectedStatus,
    provider.selectedMeal,
    provider.searchText,
  ]);

  const onEmployeeChecked = (e, person) => {
    if (e.target.checked) {
      let isPresent = bulkUsers?.find((user) => user.id == person?.id);

      if (!isPresent) {
        setBulkUsers([...bulkUsers, { ...person }]);
      }
    } else {
      let filtered = bulkUsers?.filter((user) => user?.id !== person?.id);
      setBulkUsers(filtered);
    }
  };

  const filterTeams = (teams) => {
    let teamAdmin = [];
    let teamUsers = [];

    if (!showAllUsers) {
      teams?.forEach((team) => {
        if (team.id == selectedTeam.value) {
          team?.attributes?.admins?.forEach((admin) => {
            teamAdmin.push(admin);
          });

          team?.attributes?.employees?.forEach((employee) => {
            teamUsers.push(employee);
          });
        }
      });

      dispatch({ field: "teamAdmins", value: teamAdmin });
      dispatch({ field: "teamUsers", value: teamUsers });
      dispatch({ field: "filteredTeamUsers", value: teamUsers });
    }
  };

  const handleTeamAdded = (team) => {
    setCreateTeam(false);
    setEditTeam(false);
    setTeamAdded((prev) => !prev);
    if (team) {
      setShowAllUsers(false);

      dispatch({
        field: "selectedTeam",
        value: {
          label: team?.attributes?.name,
          value: team?.id,
        },
      });
    } else {
      setShowAllUsers(true);
    }
  };

  const handleOnChange = (e) => {
    dispatch({ field: "searchText", value: e?.target?.value });
  };

  const handleClearFilters = () => {
    dispatch({ field: "selectedMeal", value: null });
    dispatch({ field: "selectedStatus", value: null });
    dispatch({ field: "selectedRole", value: null });
  };

  function UsersTable({ teams, role }) {
    return (
      <div className="mt-8 w-full flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="bg-light-gray">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  ></th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  >
                    First name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Last name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Meal
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  ></th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {teams?.map((user) => (
                  <tr key={user?.id} className="even:bg-light-gray">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox text-blue-600 h-4 w-4"
                          checked={bulkUsers?.some((u) => u.id == user?.id)}
                          onChange={(e) => {
                            onEmployeeChecked(e, user);
                          }}
                        />
                      </label>
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                      {user?.attributes?.first_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      {user?.attributes?.last_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      {user?.attributes?.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                      {user?.attributes?.meals?.map((meal) => (
                        <>
                          {meal}
                          <br />
                        </>
                      ))}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                      {user?.attributes?.status ?? "-"}
                    </td>
                    <td
                      className={classNames(
                        "whitespace-nowrap px-3 py-4 text-sm font-inter-medium capitalize",
                        {
                          "text-dark-gray": role === "admin",
                          "text-[#232777]": role === "full-time",
                        }
                      )}
                    >
                      <span className="inline-flex">
                        {user?.attributes?.role ?? "-"}
                        {role !== "full-time" &&
                          user?.attributes?.role == "Account admin" && (
                            <TooltipNew
                              darkGrayIcon
                              tooltipText={
                                "The account admin is responsible for <br /> managing the entire meal program at an <br /> organizational level"
                              }
                              id="account-admin-tooltip"
                              backgroundColor={"white"}
                              textColor="#2d3958"
                              place={"right"}
                            />
                          )}

                        {role !== "full-time" &&
                          user?.attributes?.role == "Team admin" && (
                            <TooltipNew
                              darkGrayIcon
                              tooltipText={
                                "Team admins refers to a user with the <br /> privileges to manage and oversee a specific <br /> team of users in a meal plan"
                              }
                              id="team-admin-tooltip"
                              backgroundColor={"white"}
                              textColor="#2d3958"
                              place={"right"}
                            />
                          )}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray"></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  function AllUsersTable({ teams }) {
    return (
      <div className="mt-8 w-full flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="bg-light-gray">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  ></th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-inter-regular text-dark-gray sm:pl-3 "
                  >
                    First name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Last name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Meal
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Team
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-inter-regular text-dark-gray"
                  >
                    Role
                  </th>
                  <th></th>
                </tr>
              </thead>

              <tbody className="bg-white">
                {teams?.map((team) => (
                  <>
                    {team?.attributes?.admins?.map((admin) => (
                      <tr key={admin?.id} className="even:bg-light-gray">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox text-blue-600 h-4 w-4"
                              checked={bulkUsers?.some(
                                (u) => u.id == admin?.id
                              )}
                              onChange={(e) => {
                                onEmployeeChecked(e, admin);
                              }}
                            />
                          </label>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                          {admin?.attributes?.first_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {admin?.attributes?.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {admin?.attributes?.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {admin?.attributes?.meals?.map((meal) => (
                            <>
                              {meal}
                              <br />
                            </>
                          ))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium capitalize text-[#232777]">
                          {team?.attributes?.name ?? "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {admin?.attributes?.status ?? "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium capitalize text-[#232777]">
                          {/* {admin?.attributes?.role ?? "-"} */}
                          <span className="inline-flex">
                            {admin?.attributes?.role ?? "-"}
                            {admin?.attributes?.role == "Account admin" && (
                              <TooltipNew
                                darkGrayIcon
                                tooltipText={
                                  "The account admin is responsible for <br /> managing the entire meal program at an <br /> organizational level"
                                }
                                id="account-admin-tooltip"
                                backgroundColor={"white"}
                                textColor="#2d3958"
                                place={"right"}
                              />
                            )}

                            {admin?.attributes?.role == "Team admin" && (
                              <TooltipNew
                                darkGrayIcon
                                tooltipText={
                                  "Team admins refers to a user with the <br /> privileges to manage and oversee a specific <br /> team of users in a meal plan"
                                }
                                id="team-admin-tooltip"
                                backgroundColor={"white"}
                                textColor="#2d3958"
                                place={"right"}
                              />
                            )}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray"></td>
                      </tr>
                    ))}

                    {team?.attributes?.employees?.map((employee) => (
                      <tr key={employee?.id} className="even:bg-light-gray">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-3">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="form-checkbox text-blue-600 h-4 w-4"
                              checked={bulkUsers?.some(
                                (u) => u.id == employee?.id
                              )}
                              onChange={(e) => {
                                onEmployeeChecked(e, employee);
                              }}
                            />
                          </label>
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-inter-medium text-dark-gray sm:pl-3">
                          {employee?.attributes?.first_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {employee?.attributes?.last_name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {employee?.attributes?.email}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray">
                          {employee?.attributes?.meals?.map((meal) => (
                            <>
                              {meal}
                              <br />
                            </>
                          ))}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium capitalize text-[#232777]">
                          {team?.attributes?.name ?? "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray capitalize">
                          {employee?.attributes?.status ?? "-"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium capitalize text-[#232777]">
                          {/* {employee?.attributes?.role ?? "-"} */}
                          <span className="inline-flex">
                            {employee?.attributes?.role ?? "-"}
                            {employee?.attributes?.role == "Account admin" && (
                              <TooltipNew
                                darkGrayIcon
                                tooltipText={
                                  "The account admin is responsible for <br /> managing the entire meal program at an <br /> organizational level"
                                }
                                id="account-admin-tooltip"
                                backgroundColor={"white"}
                                textColor="#2d3958"
                                place={"right"}
                              />
                            )}
                            {employee?.attributes?.role == "Team admin" && (
                              <TooltipNew
                                darkGrayIcon
                                tooltipText={
                                  "Team admins refers to a user with the <br /> privileges to manage and oversee a specific <br /> team of users in a meal plan"
                                }
                                id="team-admin-tooltip"
                                backgroundColor={"white"}
                                textColor="#2d3958"
                                place={"right"}
                              />
                            )}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm font-inter-medium text-dark-gray"></td>
                      </tr>
                    ))}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div class="flex-col w-full justify-start items-start py-[24px] px-[32px]">
      <div class="justify-start items-center">
        <div class="justify-start items-center gap-4 flex">
          <button
            className={classNames({
              "text-heading font-inter-medium text-[16px] p-[12px] rounded-lg capitalize": true,
              "bg-background": showAllUsers,
            })}
            onClick={() => {
              setShowAllUsers(true);
              dispatch({
                field: "selectedTeam",
                value: null,
              });
              setBulkUsers([]);
              handleClearFilters();
            }}
          >
            All
          </button>

          {filteredTeams?.map((team) => (
            <button
              className={classNames({
                "text-heading font-inter-medium text-[16px] p-[12px] rounded-lg capitalize flex": true,
                "bg-background": team?.attributes?.name == selectedTeam?.label,
              })}
              onClick={() => {
                setShowAllUsers(false);
                dispatch({
                  field: "selectedTeam",
                  value: { label: team?.attributes?.name, value: team?.id },
                });
                setBulkUsers([]);
                handleClearFilters();
              }}
            >
              {team?.attributes?.name}
              {team?.attributes?.name == selectedTeam?.label && (
                <span
                  className="ml-2 mt-1"
                  onClick={() => {
                    setEditTeam(true);
                    setCreateTeam(false);
                  }}
                >
                  <img src={EditPencilIcon} />
                </span>
              )}
            </button>
          ))}

          <button
            onClick={() => {
              setCreateTeam(true);
              setEditTeam(false);
            }}
            class="btn-outline bg-background border-0 font-inter-medium h-full p-[12px]"
          >
            + Create new team
          </button>
        </div>
      </div>

      {!showAllUsers && (
        <div class="flex-col justify-start items-start mt-[53px] w-full">
          <div class="font-inter-semibold text-[20px] text-dark-gray">
            Admins
          </div>

          {isPresent(teamAdmins) ? (
            <UsersTable teams={teamAdmins} role="admin" />
          ) : (
            <div className="d-col w-full items-center justify-center py-12">
              <div className="text-sm w-1/2 font-inter-semibold text-[#566884] text-center">
                No team assigned yet. Add users to the team to get started. Once
                added, you can assign one user as the Team Admin by setting
                their role.
              </div>
            </div>
          )}
        </div>
      )}

      <div class="flex-col justify-start items-start mt-[53px] w-full">
        <div class="d-row justify-between w-full">
          <div class="font-inter-semibold text-[20px] text-dark-gray">
            Users
          </div>

          <div class="justify-end items-center gap-3 flex">
            <div class="justify-end items-center gap-3 flex">
              <button
                class="btn-light-gray d-row justify-center items-center text-heading text-[16px]"
                onClick={() => setUploadCSV(true)}
              >
                <img src={FolderOpenIcon} className="mr-2" />
                Bulk upload users
              </button>

              {showAllUsers && (
                <button class="btn-light-gray d-row justify-center items-center text-heading text-[16px]">
                  <img src={UploadFileIcon} className="mr-2" />
                  Export user data
                </button>
              )}

              <button
                className="btn-light-gray text-[16px] text-white bg-catering"
                onClick={() => setAddUser(true)}
              >
                + Add user
              </button>
            </div>
          </div>
        </div>

        <div class="mt-[24px] flex-col items-start gap-6 flex">
          <div class="self-stretch justify-between items-center inline-flex">
            <div class="justify-end items-center gap-2 flex">
              <SearchBar
                width="w-[360px]"
                placeholder="Search"
                handleOnChange={handleOnChange}
                value={provider.searchText}
              />

              <div class="text-heading font-inter-normal text-sm">
                Filter by:
              </div>

              <Select
                styles={customStylesSubsidy({ preferences: true })}
                aria-labelledby="sort-by"
                aria-label="sort-by"
                placeholder="Meal"
                closeMenuOnSelect
                value={provider.selectedMeal}
                onChange={(option) => {
                  dispatch({ field: "selectedMeal", value: option });
                }}
                options={provider.mealOptions}
                className="min-w-[146px] border border-gray-200 rounded-xl"
              />

              <Select
                styles={customStylesSubsidy({ preferences: true })}
                aria-labelledby="sort-by"
                aria-label="sort-by"
                placeholder="Status"
                closeMenuOnSelect
                value={provider.selectedStatus}
                onChange={(option) => {
                  dispatch({ field: "selectedStatus", value: option });
                }}
                options={statusOptions}
                className="min-w-[126px] border border-gray-200 rounded-xl"
              />

              {showAllUsers && (
                <Select
                  styles={customStylesSubsidy({ preferences: true })}
                  aria-labelledby="sort-by"
                  aria-label="sort-by"
                  placeholder="Role"
                  closeMenuOnSelect
                  value={provider.selectedRole}
                  onChange={(option) => {
                    dispatch({ field: "selectedRole", value: option });
                  }}
                  options={UserRoleOptions}
                  className="min-w-[146px] text-sm font-inter-regular border border-gray-200 rounded-xl"
                />
              )}
            </div>

            <button
              class="btn-light-gray d-row justify-center items-center font-inter-regular w-auto px-[16px] text-heading text-[16px]"
              onClick={handleClearFilters}
            >
              <img src={CrossImg} className="mr-2" />
              Clear Filters
            </button>
          </div>

          {showAllUsers ? (
            isPresent(filteredTeams) ? (
              <AllUsersTable teams={filteredTeams} />
            ) : (
              <div className="d-col w-full items-center mt-4">
                <NotFound
                  data={filteredTeams}
                  message="No users in this team yet"
                />
                <button className="btn-disabled-mode bg-[#f7f9ff] border-0">
                  + Add user
                </button>
              </div>
            )
          ) : isPresent(filteredTeamUsers) ? (
            <UsersTable teams={filteredTeamUsers} role="full-time" />
          ) : (
            <div className="d-col w-full items-center mt-4">
              <NotFound
                data={filteredTeamUsers}
                message="No users in this team yet"
              />
              <button
                className="btn-disabled-mode bg-[#f7f9ff] border-0"
                onClick={() => setAddUser(true)}
              >
                + Add user
              </button>
            </div>
          )}
        </div>
      </div>

      {isPresent(bulkUsers) && (
        <div class="fixed bottom-0 right-0 d-row items-center justify-center left-0 ">
          <div className="btn-blue-accent rounded-3xl px-[32px] py-[16px] d-row items-center hover:bg-catering h-[48px] ">
            <small className="font-inter-medium text-[16px]">
              {bulkUsers?.length} user selected
            </small>

            <button
              className="font-inter-medium text-white underline text-[16px] py-0 ml-4"
              onClick={() => {
                if (bulkUsers?.length == 1) {
                  setEditUser(true);
                } else {
                  setEditBulkUsers(true);
                }
              }}
            >
              Edit
            </button>
          </div>
        </div>
      )}

      <UploadCSV
        visible={uploadCSV}
        setModal={setUploadCSV}
        setErrors={setErrors}
        setRecordError={setRecordError}
        setIsSuccessResponse={setIsSuccessResponse}
        setIsErrorResponse={setIsErrorResponse}
      />

      <CreateTeam
        visible={createTeam || editTeam}
        setModal={() => {
          setCreateTeam(false);
          setEditTeam(false);
        }}
        isEdit={editTeam}
        selectedTeam={selectedTeam}
        setTeamAdded={handleTeamAdded}
      />

      <AddUserToTeam
        visible={addUser || editUser}
        setModal={() => {
          setAddUser(false);
          setEditUser(false);
        }}
        isEdit={editUser}
        teams={teamOptions}
        handleAddUser={() => {
          setUserAdded((prev) => !prev);
          setAddUser(false);
          setEditUser(false);
          setBulkUsers([]);
        }}
        editUser={bulkUsers[0]}
      />

      <BulkUserEdit
        visible={editBulkUsers}
        setModal={setEditBulkUsers}
        teams={teamOptions}
        bulkUsers={bulkUsers}
        handleBulkUpdate={() => {
          setBulkUsers([]);
          setUserAdded((prev) => !prev);
        }}
      />

      <CsvResponseModal
        visible={isErrorResponse || isSuccessResponse}
        title={`${
          isSuccessResponse
            ? "CSV File Uploaded Successfully!"
            : recordError
            ? "Error in Uploading Some Lines"
            : "Error in Uploading"
        }`}
        body={`${
          isSuccessResponse
            ? ""
            : recordError
            ? "Some lines couldn't be uploaded due to errors. Please review and correct the listed issues before re-uploading the file. The other lines have been uploaded successfully. Don't worry—if you re-upload the entire file, duplicate entries will not be created."
            : "The file couldn't be uploaded. Please ensure you are using the correct template and try re-uploading."
        }`}
        errors={errors ?? []}
        isSuccess={isSuccessResponse}
        dismissAlert={() => {
          setIsErrorResponse(false);
          setIsSuccessResponse(false);
        }}
        handleUploadAnotherFile={() => {
          setIsErrorResponse(false);
          setIsSuccessResponse(false);
          setUploadCSV(true);
        }}
      />
    </div>
  );
}

export default observer(UncateringTeam);
