import { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import Select, { components } from "react-select";
import { useNavigate } from "react-router-dom";
import dateTime from "utils/helpers/dateTime";

function ChangeMealDate({ visible, setModal, meals = [] }) {
  const [meal, setSelectedMeal] = useState(null);

  const navigate = useNavigate();

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      overflowY: "auto", // Allow scrolling if content overflows
    }),
  };

  const onSavePress = () => {
    setModal(false);
    navigate(`/home/request-meal-changes/${meal?.value}`);
  };

  const options = meals &&
    Array.isArray(meals) && [
      ...meals.map((meal) => ({
        label: dateTime.formatDate(meal?.meal_date, "ddd MMM, DD"),
        value: meal.id,
      })),
    ];

  const onChangeMeal = (meal) => {
    setSelectedMeal(meal);
  };

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[651px] min-h-[457px] d-col justify-between rounded-xl transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white px-8 pb-8 pt-[24px] text-left align-middle shadow-xl transition-all overflow-visible">
                <div>
                  <div className="d-row justify-between mb-[24px] items-center">
                    <h2 className="text-2xl font-inter-bold">
                      Request Changes for a Single day
                    </h2>

                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <span className="font-inter-regular">
                    Select from dates that
                  </span>

                  <div className="mt-[24px]">
                    <Select
                      styles={customStyles}
                      isSearchable={false}
                      placeholder="Select from addresses on file"
                      closeMenuOnSelect
                      value={meal}
                      onChange={onChangeMeal}
                      options={options}
                    />
                  </div>
                </div>

                <div className="d-row w-full items-center mt-[24px] justify-center">
                  <button
                    className="btn-light btn-style py-3 px-5"
                    onClick={() => setModal(false)}
                  >
                    Cancel
                  </button>

                  <button
                    className="btn-purple btn-style py-3 px-6 ml-3"
                    onClick={onSavePress}
                  >
                    Go To Date
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ChangeMealDate;
