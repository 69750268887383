import React, { Fragment, useContext, useEffect, useReducer } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import classNames from "classnames";
import moment from "moment-timezone";
import Select from "react-select";
import { formattedAddres } from "utils/helpers/address";
import { customStylesSubsidy } from "utils/helpers/styles";
import { CalendarImg, MapIcon } from "assets/img";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "components/DatePicker";
import TimePickerDropdown from "components/TimePickerDropdown";
import { useNavigate } from "react-router-dom";

function AddressTimeModal({ visibleModal, showModal, orderType = "Catering" }) {
  const initialState = {
    locationAddresses: null,
    selectedAddress: null,
    date: "",
    time: "12:00 pm",
    availableTimes: null,
    availableDates: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const { locationAddresses, selectedAddress } = provider;
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const { defaultLocation } = userStore;

  let navigate = useNavigate();

  useEffect(() => {
    store.userStore.setLoader(true);
    store.deliveryInfoStore
      ?.getLocationAddresses({ location_id: defaultLocation?.organizationId })
      .then((response) => {
        dispatch({ field: "locationAddresses", value: response?.data });
        store.userStore.setLoader(false);
      })
      .catch(() => store.userStore.setLoader(false));
  }, [defaultLocation?.organizationId]);

  useEffect(() => {
    if (provider.date) store.userStore.setDate(provider.date);
    if (provider.time) store.userStore.setTime(provider.time);
  }, [provider.date, provider.time]);

  const isValidInfo = () => {
    return provider.selectedAddress && provider.date && provider.time;
  };

  const addressOptions =
    locationAddresses && Array.isArray(locationAddresses)
      ? [
          ...locationAddresses?.map((address) => ({
            label: formattedAddres(address.attributes),
            value: address.id,
          })),
          {
            label: "Enter new delivery address",
            value: "enter_new_delivery_address",
          },
        ]
      : [
          {
            label: "Enter new delivery address",
            value: "enter_new_delivery_address",
          },
        ];

  const handleAddressChange = (address) => {
    if (address.value === "enter_new_delivery_address") {
      dispatch({
        field: "selectedAddress",
        value: {
          label: "Enter new delivery address",
          value: "enter_new_delivery_address",
        },
      });
      navigate("/delivery-info/create", { state: { from: "addMeal" } });
    } else {
      dispatch({ field: "selectedAddress", value: address });
      store.userStore.setNewAddress(address);

      getAvailableDates(address?.label);
    }
  };

  const getAvailableDates = (
    address = provider.selectedAddress?.label,
    date = provider.date
  ) => {
    store.userStore.setLoader(true);
    store.userStore
      .getAvailableDates({
        address: address,
        month: date ? moment(date).month() + 1 : moment().month() + 1,
        year: date ? moment(date).year() : moment().year(),
        current_date_time: moment().format("YYYY-MM-DD HH:mm"),
      })
      .then((response) => {
        dispatch({ field: "availableDates", value: response?.dates });
        store.userStore.setLoader(false);
      });
  };

  const getAvailableTimes = (
    address = provider.selectedAddress?.label,
    date = provider.date
  ) => {
    store.userStore.setLoader(true);
    store?.userStore
      ?.getAvailableTimes({
        address: address,
        selected_date: moment(date).format("YYYY-MM-DD"),
        current_date_time: moment().format("YYYY-MM-DD HH:mm"),
      })
      .then((response) => {
        dispatch({ field: "availableTimes", value: response?.times });
        store.userStore.setLoader(false);
      });
  };

  const handleContinueClick = () => {
    store.deliveryInfoStore.getAddress(
      { location_id: defaultLocation.organizationId },
      selectedAddress?.value
    );
    if (orderType == "Catering") navigate("/get-started-catering");
    else if (orderType == "Group") navigate("/get-started-group");
  };

  return (
    <Transition appear show={visibleModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full bg-white md:bg-transparent md:items-center md:justify-center md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full h-full sm:h-auto md:w-5/12 max-w-[567px] transform overflow-visible sm:rounded-2xl md:rounded-2xl bg-white py-10 px-8 pt-6 text-left align-middle md:shadow-xl transition-all">
                <div className="flex items-end justify-end w-full mb-2">
                  <button
                    type="button"
                    onClick={() => showModal(false)}
                    className="btn-style"
                  >
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#BBC0C5"
                    />
                  </button>
                </div>

                <div className="flex justify-center items-center">
                  <Dialog.Title
                    as="h1"
                    className="font-inter-semibold leading-8 text-center text-[#3a3a3a] text-2xl"
                  >
                    Where should the {orderType} Order be delivered?
                  </Dialog.Title>
                </div>

                <div className="mt-[32px]">
                  <div className="flex flex-col mt-5">
                    <div className="flex w-full md:mr-2 h-12 bg-background rounded pl-2">
                      <img src={MapIcon} alt="map-icon" className="pl-2" />

                      <div className="w-full">
                        <Select
                          styles={customStylesSubsidy({ noBorder: true })} // Pass the noBorder prop to customStylesSubsidy
                          aria-labelledby="sort-by"
                          aria-label="sort-by"
                          placeholder="Select your address"
                          closeMenuOnSelect
                          value={selectedAddress}
                          onChange={handleAddressChange}
                          options={addressOptions}
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex flex-col md:flex-row justify-between mt-5 overflow-scroll md:overflow-visible">
                    <div className="flex w-1/2 h-12 bg-background rounded pl-2">
                      <img
                        src={CalendarImg}
                        alt="calendar-icon"
                        className="pl-2 small-icon"
                        width={25}
                        height={25}
                      />

                      <DatePicker
                        selectedDate={provider.date}
                        setSelectedDate={(date) => {
                          dispatch({ field: "date", value: date });
                          store.userStore.setDate(date);
                        }}
                        minDate={new Date()}
                        format="MMMM d, yyyy"
                        placeholderText="Enter delivery date"
                        className="flex h-12 bg-light-gray justify-between rounded items-center"
                        pickerClassName="font-inter-regular"
                        showIcon={false}
                        availableDates={provider.availableDates}
                        fromModal={true}
                        getAvailableDates={getAvailableDates}
                        getAvailableTimes={getAvailableTimes}
                      />
                    </div>

                    <div className="flex flex-col w-full md:w-1/2 mt-3 md:mt-0 md:ml-6">
                      <div className="flex w-full md:mr-2 h-12 bg-background rounded pl-2">
                        <TimePickerDropdown
                          fromModal
                          labelName="address-time-modal"
                          isCart={false}
                          selectedDate={provider.date}
                          selectedTime={provider.time}
                          grayBackground={false}
                          allTimes={provider.availableTimes}
                          className="flex justify-between border-0 w-full"
                          backgroundColor={true}
                          setSelectedTime={(time) =>
                            dispatch({ field: "time", value: time })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hidden md:flex justify-center items-center mt-6">
                  <div className="flex w-full">
                    <button
                      className={classNames(
                        "btn-purple py-3 text-sm px-6 w-full",
                        {
                          "text-secondary-gray btn-disabled": !isValidInfo(),
                        }
                      )}
                      onClick={handleContinueClick}
                      disabled={!isValidInfo()}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default observer(AddressTimeModal);
