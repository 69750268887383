import React, { useState } from "react";
import { foodImage, thumbsDown, thumbsUp } from "assets/img";
import classNames from "classnames";
import { toJS } from "mobx";

const CuisineGrid = ({
  dietaryPreferences,
  handleReviewButton,
  selectedCuisines,
}) => {
  const handleClick = (name, review) => {
    handleReviewButton(name, review);
  };

  const isCuisineTypeReviewed = (name, reviewType) => {
    return selectedCuisines?.some(
      (cuisineType) =>
        cuisineType.name === name && cuisineType.review === reviewType
    );
  };

  return (
    <div className="grid grid-cols-6 gap-8">
      {dietaryPreferences?.map((preference, index) => (
        <div key={index} className="w-[161px]">
          <div className="bg-white">
            <img
              src={preference.url || foodImage} //this is a temporary image
              alt={`Img ${index}`}
              className="w-[161px] h-[76px] bg-light-gray rounded-t-xl"
            />

            <div className="bg-light-gray px-1 py-2 rounded-b-xl overflow-hidden">
              <div className="flex-col justify-start items-start px-[9px] h-40">
                <div className="self-stretch justify-start items-start gap-3 flex">
                  <div className="text-slate-700 text-base font-inter-semibold leading-tight">
                    {preference?.name}
                  </div>
                </div>

                <div className="py-2 flex flex-wrap">
                  {preference?.tags?.map((tag, tagIndex) => (
                    <div
                      key={tagIndex}
                      className="w-fit px-1.5 py-1 mr-2 mb-2 text-slate-700 bg-white rounded-3xl text-[10px] font-inter-medium"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-4 justify-start items-start gap-1 inline-flex">
            <button
              className={classNames(
                "hover-class grow shrink basis-0 h-[30px] px-1.5 py-2 rounded justify-center items-center gap-1 flex",
                {
                  "bg-light-gray hover:bg-indigo-500": !isCuisineTypeReviewed(
                    preference.name,
                    "like"
                  ),
                  "bg-indigo-500": isCuisineTypeReviewed(
                    preference.name,
                    "like"
                  ),
                }
              )}
              onClick={() => {
                handleClick(preference.name, "like");
              }}
            >
              <img
                className="hover-class"
                src={thumbsUp}
                alt="ThumbsUp"
                style={{
                  filter: isCuisineTypeReviewed(preference.name, "like")
                    ? "invert(82%) sepia(66%) saturate(14.06%) hue-rotate(176deg) brightness(110%) contrast(190%)"
                    : "none",
                }}
              />
            </button>

            <button
              className={classNames(
                "grow shrink basis-0 h-[30px] px-1.5 py-2 rounded justify-center items-center gap-1 flex text-xs font-inter-medium leading-[14px]",
                {
                  "bg-slate-50 text-indigo-900 hover:bg-indigo-500 hover:text-white":
                    !isCuisineTypeReviewed(preference.name, "neutral"),
                  "bg-indigo-500 text-white": isCuisineTypeReviewed(
                    preference.name,
                    "neutral"
                  ),
                }
              )}
              onClick={() => {
                handleClick(preference.name, "neutral");
              }}
            >
              Neutral
            </button>

            <button
              className={classNames(
                "hover-class grow shrink basis-0 h-[30px] px-1.5 py-2 rounded justify-center items-center gap-1 flex",
                {
                  "bg-slate-50 hover:bg-indigo-500": !isCuisineTypeReviewed(
                    preference.name,
                    "dislike"
                  ),
                  "bg-indigo-500": isCuisineTypeReviewed(
                    preference.name,
                    "dislike"
                  ),
                }
              )}
              onClick={() => {
                handleClick(preference.name, "dislike");
              }}
            >
              <img
                className="hover-class"
                src={thumbsDown}
                alt="ThumbsDown"
                style={{
                  filter: isCuisineTypeReviewed(preference.name, "dislike")
                    ? "invert(82%) sepia(66%) saturate(14.06%) hue-rotate(176deg) brightness(110%) contrast(190%)"
                    : "none",
                }}
              />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CuisineGrid;
