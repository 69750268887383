import { makeAutoObservable, observable, action, makeObservable } from "mobx";
import { makePersistable, clearPersistedStore } from "mobx-persist-store";
import { client } from "../store";
import api from "store/api";
import qs from "qs";
import { errorHandler } from "utils/middlewares/errorHandler";

class DeliveryInfoStore {
  locationAddress = null;
  locationAddresses = [];
  singleLocationAddress = null;
  primaryAddress = null;
  completeAddress = null;

  constructor() {
    makePersistable(this, {
      name: "DeliveryInfoStore",
      properties: ["primaryAddress", "completeAddress", "locationAddresses"],
      storage: window.localStorage,
    });
    makeObservable(this, {
      locationAddress: observable,
      locationAddresses: observable,
      singleLocationAddress: observable,
      primaryAddress: observable,
      completeAddress: observable,

      getLocationPrimaryAddress: action,
      updateLocationNameAndContact: action,
      getLocationAddresses: action,
      getAddress: action,
      updateAddress: action,
    });
  }

  async clearStoredData() {
    await clearPersistedStore(this);
  }

  async getLocationPrimaryAddress(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.locationAddress()}/${params?.location_id}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.locationAddress = data?.data;

          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async updateLocationNameAndContact(id, params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.locationAddresses()}/${id}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.primaryAddress = data?.data;

          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async getLocationAddresses(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.locationAddresses()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          const primaryAddress = data?.data?.find(
            (address) => address?.attributes?.primary
          );
          this.primaryAddress = primaryAddress;
          this.locationAddresses = data?.data;

          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async getAddress(params, addressId) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.locationAddresses()}/${addressId}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.completeAddress = data?.data;
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async updateAddress(params, addressId, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .put(`${api.locationAddresses()}/${addressId}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.getLocationAddresses(params);
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async createAddress(params, payload) {
    let paramsString = qs.stringify(params);

    return client()
      .post(`${api.locationAddresses()}?${paramsString}`, payload)
      .then(
        action("fetchSuccess", ({ data }) => {
          this.getLocationAddresses(params);
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }

  async getProvinces(params) {
    let paramsString = qs.stringify(params);

    return client()
      .get(`${api.provinces()}?${paramsString}`)
      .then(
        action("fetchSuccess", ({ data }) => {
          return data;
        }),
        action("fetchError", (error) => {
          errorHandler(error?.response);
          return error;
        })
      );
  }
}

const deliveryInfoStore = new DeliveryInfoStore();
export default deliveryInfoStore;
