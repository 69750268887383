const EmbedHTML = ({
  text,
  className = "text-black",
  append = null,
  isRow,
  appendClass,
  replaceText,
}) => {
  const updatedText = replaceText
    ? text
        ?.replace("is sold out of ", "is sold out of&nbsp;")
        ?.replace(/<\/?p>/g, "")
    : text;

  return (
    <>
      {!isRow ? (
        <div
          className={`font-inter ${className} ${append && "d-row"}`}
          dangerouslySetInnerHTML={{
            __html: updatedText + (append || ""),
          }}
        />
      ) : (
        <div className="d-row">
          <div
            className={`font-inter ${className} ${append && "d-row"}`}
            dangerouslySetInnerHTML={{ __html: updatedText }}
          />

          <div className={`${className} ${appendClass}`}>{append}</div>
        </div>
      )}
    </>
  );
};

export default EmbedHTML;
