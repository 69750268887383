import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
} from "react";
import { GrayInfoButton, InfoButton } from "assets/img";
import { MobXProviderContext } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import { NotificationBanner } from "components";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { toJS } from "mobx";
import { customStylesSubsidy } from "utils/helpers/styles";
import { validatePostalCodeLength } from "utils/helpers/validation";
import Select from "react-select";
import InputError from "components/InputError/InputError";

function DeliveryInfoDetail() {
  const [requestUpdate, setRequestUpdate] = useState(false);
  const store = useContext(MobXProviderContext);
  const userStore = store?.userStore;
  const [enablePhoneName, setEnablePhoneName] = useState(false);
  const [enableRequestAddressUpdate, setEnableRequestAddressUpdate] =
    useState(false);
  const [enableSaveChanges, setEnableSaveChanges] = useState(true);
  const [updateAddressAlert, setUpdateAddressAlert] = useState(false);
  const [pointOfContactNotification, showPointOfContactNotification] =
    useState(false);
  const [showAddressOutsideError, setShowAddressOutsideError] = useState(false);

  let { defaultLocation } = userStore;
  const { id } = useParams();

  const nameInputRef = useRef(null);
  const phoneInputRef = useRef(null);

  const initialState = {
    streetNumber: "",
    streetName: "",
    unit: "",
    city: "",
    province: "",
    postalCode: "",
    nickname: "",
    deliveryInstructions: "",
    destinationCode: "",
    deliveryContactName: "",
    deliveryContactPhone: "",
    deliveryVerification: "photo",
    isUnderReview: false,
    completeAddress: "",

    nicknameError: false,
    streetNameError: false,
    streetNumberError: false,
    cityError: false,
    provinceError: false,
    postalCodeError: false,
    provinceOptions: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const requestUpdateClick = useCallback(() => {
    setRequestUpdate(!requestUpdate);
  });

  const setData = (address) => {
    dispatch({
      field: "nickname",
      value: address?.attributes?.nickname,
    });
    dispatch({
      field: "streetNumber",
      value: address?.attributes?.street_number,
    });
    dispatch({
      field: "streetName",
      value: address?.attributes?.street_name,
    });
    dispatch({
      field: "city",
      value: address?.attributes?.city,
    });
    dispatch({
      field: "province",
      value: {
        label: address?.attributes?.province,
        value: address?.attributes?.province,
      },
    });
    dispatch({
      field: "postalCode",
      value: address?.attributes?.postal_code,
    });
    dispatch({
      field: "unit",
      value: address?.attributes?.suite,
    });
    dispatch({
      field: "deliveryInstructions",
      value: address?.attributes?.delivery_instructions,
    });
    dispatch({
      field: "destinationCode",
      value: address?.attributes?.destination_code,
    });
    dispatch({
      field: "deliveryContactPhone",
      value: address?.attributes?.delivery_contact_phone,
    });
    dispatch({
      field: "deliveryContactName",
      value: address?.attributes?.delivery_contact_name,
    });
    dispatch({
      field: "deliveryVerification",
      value: address?.attributes?.delivery_verification ?? "photo",
    });
    dispatch({
      field: "completeAddress",
      value: `${address?.attributes?.nickname} - ${address?.attributes?.complete_address}`,
    });
  };

  useEffect(() => {
    scrollToUp(0);

    store.userStore.setLoader(true);
    store.deliveryInfoStore
      .getProvinces({ location_id: defaultLocation?.organizationId })
      .then((response) => {
        store.userStore.setLoader(false);

        const provinceOptions = response?.map((province) => {
          return {
            label: province.name,
            value: province.code,
          };
        });

        dispatch({
          field: "provinceOptions",
          value: provinceOptions,
        });
      });
  }, []);

  useEffect(() => {
    store.userStore.setLoader(true);
    store.deliveryInfoStore
      .getAddress({ location_id: defaultLocation?.organizationId }, id)
      .then((response) => {
        setData(response?.data);
        store.userStore.setLoader(false);
      })
      .catch(() => store.userStore.setLoader(false));
  }, []);

  const scrollToUp = (top) => {
    window.scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };

  const updateAddress = (e, addressAlert) => {
    e.preventDefault();

    if (provider.nickname == "") {
      dispatch({ field: "nicknameError", value: true });
      scrollToUp(100);
      return;
    }

    if (
      !(provider.postalCode && validatePostalCodeLength(provider.postalCode))
    ) {
      dispatch({ field: "postalCodeError", value: true });
      scrollToUp(250);
      return;
    }

    if (provider.streetName == "") {
      dispatch({ field: "streetNameError", value: true });
      scrollToUp(200);
      return;
    }

    if (provider.streetNumber == "") {
      dispatch({ field: "streetNumberError", value: true });
      scrollToUp(100);
      return;
    }

    if (provider.city == "") {
      dispatch({ field: "cityError", value: true });
      scrollToUp(200);
      return;
    }

    if (provider.province == "") {
      dispatch({ field: "provinceError", value: true });
      scrollToUp(250);
      return;
    }

    const payload = {
      address: {
        suite: provider?.unit,
        street_number: provider?.streetNumber,
        street_name: provider?.streetName,
        city: provider?.city,
        province: provider?.province?.value,
        postal_code: provider?.postalCode,
        nickname: provider?.nickname,
        delivery_instructions: provider?.deliveryInstructions,

        delivery_contact_name: provider?.deliveryContactName,
        delivery_contact_phone: provider?.deliveryContactPhone,
        delivery_verification: provider?.deliveryVerification ?? "photo",
      },
    };

    store.userStore.setLoader(true);
    store.deliveryInfoStore
      ?.updateAddress(
        { location_id: defaultLocation?.organizationId },
        id,
        payload
      )
      .then((response) => {
        if (response?.response?.data?.errors) {
          setShowAddressOutsideError(true);
          store.userStore.setLoader(false);
          scrollToUp(0);
        } else {
          setData(response?.data);
          dispatch({
            field: "isUnderReview",
            value: response?.data.attributes.under_review,
          });
          store.userStore.setLoader(false);
          setEnableRequestAddressUpdate(false);
          setRequestUpdate(false);
          if (addressAlert) setUpdateAddressAlert(true);
          scrollToUp(0);
        }
      })
      .catch(() => store.userStore.setLoader(false));
  };

  const cancelUpdates = () => {
    store.userStore.setLoader(true);
    store.deliveryInfoStore
      .getAddress({ location_id: defaultLocation?.organizationId }, id)
      .then((response) => {
        setData(response?.data);
        store.userStore.setLoader(false);
        setEnableRequestAddressUpdate(false);
        setRequestUpdate(false);
      })
      .catch(() => store.userStore.setLoader(false));
  };

  return (
    <div className="px-[32px] py-[24px] w-full pb-32">
      <button
        className="flex items-center cursor-pointer mb-[24px]"
        onClick={() => {
          navigate("/delivery-info");
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} style={{ width: 14, height: 14 }} />
        <span className="text-md text-left font-inter-medium text-secondary-light ml-2">
          Back
        </span>
      </button>

      <NotificationBanner
        message={
          provider.isUnderReview
            ? `The new delivery address ${provider.completeAddress} is under review, this message will disappear once changes have taken effect.`
            : "The address has been successfully updated."
        }
        messageLink={provider.isUnderReview && "Contact us"}
        messageType={provider.isUnderReview ? "warning" : "success"}
        textColor={!provider.isUnderReview && "success"}
        visible={updateAddressAlert}
        setVisible={setUpdateAddressAlert}
        handleLinkClick={() => {
          store.userStore.setFreshChatOpen(true);
        }}
      />

      <NotificationBanner
        message="Point of contact for address successfully updated"
        messageType="success"
        textColor="success"
        visible={pointOfContactNotification}
        setVisible={showPointOfContactNotification}
      />

      <NotificationBanner
        message="The address could not be saved because it is currently outside our service area. Please try another address or"
        messageType="danger"
        underlineText
        abilityToDismiss={false}
        hideClose={true}
        visible={showAddressOutsideError}
        messageLink="contact us with any questions"
        handleLinkClick={() => {
          store.userStore.setFreshChatOpen(true);
        }}
      />

      <div className="d-col">
        <h1 className="text-dark-gray mt-[16px] text-[24px] font-inter-semibold">
          Delivery details
        </h1>
        <small className="font-inter-regular text-dark-gray text-sm">
          Destination code: {provider.destinationCode}
        </small>
      </div>

      <div className="grid lg:grid-cols-2 md:grid-cols-1 w-full mt-[32px] gap-[21px]">
        <div className="w-full">
          <form onSubmit={(e) => updateAddress(e, true)}>
            <div className="d-row justify-between">
              <div className="d-col">
                <h2 className="text-dark-gray text-[20px] font-inter-semibold">
                  Delivery address
                </h2>

                <small className="font-inter-regular text-dark-gray text-sm">
                  This is where the food you order will arrive
                </small>
              </div>

              <button
                className={classNames({
                  "btn-outline h-[48px] rounded-xl lg:mt-0 md:mt-0 mt-3": true,
                  "opacity-25 btn-disabled-mode": requestUpdate,
                })}
                disabled={requestUpdate || enablePhoneName}
                onClick={requestUpdateClick}
              >
                Update
              </button>
            </div>

            {requestUpdate && (
              <div className="bg-zinc-100 rounded-lg px-4 py-2 mt-2 flex inline-flex items-center">
                <img
                  className="object-contain mr-[10px]"
                  width={20}
                  height={20}
                  alt="info-button"
                  src={GrayInfoButton}
                />
                <small className="text-dark-gray font-inter-normal text-xs">
                  If you need to delete this address or set it as your primary
                  address, please reach out to your account manager for
                  assistance.
                </small>
              </div>
            )}

            <div className="bg-light-gray p-[24px] rounded-xl mt-[24px]">
              <small className="text-dark-gray font-inter-regular text-xs">
                Address Nickname (give this address something easy for you to
                refer to)
              </small>

              <input
                className="relative mt-[4px] capitalize rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                placeholder="eg. Downtown office or Ana’s birthday"
                type={"text"}
                disabled={!requestUpdate}
                onChange={(e) => {
                  dispatch({
                    field: "nickname",
                    value: e.target?.value,
                  });
                  setEnableRequestAddressUpdate(true);
                  dispatch({
                    field: "nicknameError",
                    value: false,
                  });
                }}
                value={provider?.nickname}
              />

              <div className="mb-[24px]">
                {provider.nicknameError && (
                  <InputError error={"This is required field*"} />
                )}
              </div>

              <div className="d-row mt-[24px]">
                <div className="flex flex-col items-start w-1/2 mr-[24px]">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Street Number
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    placeholder="123"
                    type={"number"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "streetNumber",
                        value: e.target?.value,
                      });
                      setEnableRequestAddressUpdate(true);
                      dispatch({
                        field: "streetNumberError",
                        value: false,
                      });
                    }}
                    value={provider?.streetNumber}
                  />

                  {provider.streetNumberError && (
                    <InputError error={"This is required field*"} />
                  )}
                </div>

                <div className="flex flex-col items-start w-1/2">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Unit/suite (optional)
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "unit",
                        value: e.target?.value,
                      });
                      setEnableRequestAddressUpdate(true);
                    }}
                    value={provider?.unit}
                  />
                </div>
              </div>

              <div className="d-row mt-[24px]">
                <div className="flex flex-col items-start w-1/2 mr-[24px]">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Street name
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    placeholder="John Street"
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      const value = e.target.value;
                      dispatch({
                        field: "streetName",
                        value: value,
                      });
                      setEnableRequestAddressUpdate(true);
                      dispatch({ field: "streetNameError", value: false });
                    }}
                    value={provider?.streetName}
                  />

                  {provider.streetNameError && (
                    <InputError error={"This is required field*"} />
                  )}
                </div>

                <div className="flex flex-col items-start w-1/2">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    City
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    placeholder="Toronto"
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      const value = e.target.value;
                      dispatch({
                        field: "city",
                        value: value,
                      });
                      setEnableRequestAddressUpdate(true);
                      dispatch({ field: "cityError", value: false });
                    }}
                    value={provider?.city}
                  />

                  {provider.cityError && (
                    <InputError error={"This is required field*"} />
                  )}
                </div>
              </div>

              <div className="d-row mt-[24px]">
                <div className="flex flex-col items-start w-1/2 mr-[24px]">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Province
                  </small>

                  <Select
                    styles={customStylesSubsidy({
                      bgWhite: true,
                      isDisabled: !requestUpdate,
                    })}
                    isDisabled={!requestUpdate}
                    aria-labelledby="sort-by"
                    aria-label="sort-by"
                    placeholder="Select Province"
                    closeMenuOnSelect
                    value={provider.province}
                    onChange={(option) => {
                      dispatch({
                        field: "province",
                        value: option,
                      });
                      setEnableRequestAddressUpdate(true);
                      dispatch({ field: "provinceError", value: false });
                    }}
                    options={provider.provinceOptions}
                    className="w-full text-[#566985] font-inter-medium mt-[4px]"
                  />

                  {provider.provinceError && (
                    <InputError error={"This is required field*"} />
                  )}
                </div>

                <div className="flex flex-col items-start w-1/2">
                  <small className="text-dark-gray font-inter-regular text-xs">
                    Postal Code
                  </small>

                  <input
                    className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                    placeholder="M6K O90"
                    type={"text"}
                    disabled={!requestUpdate}
                    onChange={(e) => {
                      dispatch({
                        field: "postalCode",
                        value: e.target?.value,
                      });
                      setEnableRequestAddressUpdate(true);
                      dispatch({
                        field: "postalCodeError",
                        value: false,
                      });
                    }}
                    value={provider?.postalCode}
                  />

                  {provider.postalCodeError && (
                    <InputError error={"Invalid postal code"} />
                  )}
                </div>
              </div>

              <div className="d-col mt-[24px]">
                <small className="font-inter-regular text-dark-gray text-sm">
                  Additional delivery instuctions (optional)
                </small>
              </div>

              <div className="mt-[4px]">
                <textarea
                  type="text"
                  name="name"
                  id="name"
                  value={provider?.deliveryInstructions}
                  className={classNames({
                    "flex w-full text-secondary focus:outline-none rounded-l-md text-md py-[9px] p-[19px]": true,
                  })}
                  placeholder="eg. Park in the back entrance, leave on kitchen table, call us when you arrive "
                  onChange={(e) => {
                    dispatch({
                      field: "deliveryInstructions",
                      value: e?.target?.value,
                    });
                    setEnableRequestAddressUpdate(true);
                  }}
                  disabled={!requestUpdate}
                />
              </div>
            </div>

            {requestUpdate && (
              <div className="d-row justify-end items-center mt-[24px]">
                <button
                  type="button"
                  className="btn-outline rounded-xl mr-[16px] h-[48px]"
                  onClick={() => {
                    cancelUpdates();
                  }}
                >
                  Cancel
                </button>

                <button
                  className={classNames({
                    "btn-blue-accent rounded-xl": true,
                    "btn-disabled": !enableRequestAddressUpdate,
                  })}
                  type="submit"
                  disabled={!enableRequestAddressUpdate}
                >
                  Request address update
                </button>
              </div>
            )}
          </form>
        </div>

        <div className="w-full">
          <div className="d-col">
            <h2 className="text-dark-gray text-[20px] font-inter-semibold">
              Point of contact for address
            </h2>

            {!enablePhoneName && (
              <small className="font-inter-regular text-dark-gray text-sm">
                By default, the point of contact will be attached to all
                deliveries
              </small>
            )}
          </div>

          {enablePhoneName && (
            <div className="d-row items-start bg-background mt-[24px] py-[12px] px-[16px] rounded-lg">
              <img
                layout="fixed"
                className="object-contain mt-1 mr-[10px]"
                width={16}
                height={16}
                alt="info-button-checkou"
                src={InfoButton}
              />

              <span className="font-inter-regular text-dark-gray text-[16px]">
                The primary point of contact for this account will exclusively
                receive SMS tracking updates. The delivery contact for this
                address will be contacted in regards to deliveries to this
                address if needed.
              </span>
            </div>
          )}

          <div className="bg-background d-col p-[24px] mt-[24px] rounded-lg">
            <button
              onClick={() => {
                setEnablePhoneName(true);
                nameInputRef?.current?.focus();
              }}
              disabled={enablePhoneName || requestUpdate}
              className={classNames({
                "d-row items-center text-catering self-end": true,
                "opacity-25": enablePhoneName || requestUpdate,
              })}
            >
              <FontAwesomeIcon
                icon={faPenToSquare}
                size="1x"
                className="small-icon hover:text-gray-600 mr-2"
              />
              Edit
            </button>

            <div className="mt-[24px]">
              <label
                htmlFor="name"
                className="block text-sm font-inter-regular text-dark-gray"
              >
                Name*
              </label>

              <input
                type="text"
                disabled={!enablePhoneName}
                name="name"
                ref={nameInputRef}
                onChange={(e) => {
                  dispatch({
                    field: "deliveryContactName",
                    value: e?.target?.value,
                  });
                  setEnableSaveChanges(false);
                }}
                id="name"
                value={provider?.deliveryContactName}
                className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
              />
            </div>

            <div className="mt-[24px]">
              <label
                htmlFor="phone"
                className="block text-sm  font-inter-regular text-dark-gray leading-6"
              >
                Phone number*
              </label>

              <div className="mt-[4px] bg-background d-row rounded-md">
                <PhoneInput
                  required
                  id="phone"
                  defaultCountry="CA"
                  disabled={!enablePhoneName}
                  value={provider?.deliveryContactPhone}
                  ref={phoneInputRef}
                  className="relative mt-[4px] rounded-md py-[9px] px-[19px] mt-[-1.00px] h-[48px] font-inter-medium focus:outline-none text-secondary text-[16px] tracking-[0] leading-[28px] whitespace-nowrap w-full"
                  placeholder="Phone Number"
                  onChange={(phone) => {
                    setEnableSaveChanges(false);
                    if (typeof phone === "string" && phone.trim() !== "") {
                      if (isValidPhoneNumber(phone)) {
                        dispatch({
                          field: "deliveryContactPhone",
                          value: phone,
                        });
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {enablePhoneName && (
            <div className="d-row justify-end items-center mt-[24px]">
              <button
                className="btn-outline rounded-xl mr-[16px] h-[48px]"
                onClick={() => {
                  setEnableSaveChanges(true);
                  setEnablePhoneName(false);
                }}
              >
                Cancel
              </button>

              <button
                className={classNames({
                  "btn-blue-accent rounded-xl": true,
                  "btn-disabled": enableSaveChanges,
                })}
                onClick={(e) => {
                  updateAddress(e, false);
                  setEnablePhoneName(false);
                  showPointOfContactNotification(true);
                }}
                disabled={enableSaveChanges}
              >
                Save Changes
              </button>
            </div>
          )}

          <h2 className="text-dark-gray text-[20px] mb-[24px] mt-[32px] font-inter-semibold">
            Delivery verification
          </h2>

          <div className="py-[13px] px-[24px] bg-light-gray rounded-lg">
            <div className="d-row items-start">
              <img
                layout="fixed"
                className="object-contain mt-1 mr-[10px]"
                width={16}
                height={16}
                alt="info-button-checkou"
                src={InfoButton}
              />

              <span className="font-inter-regular text-dark-gray text-[16px]">
                <strong className="font-inter-semibold text-dark-gray text-[16px]">
                  Delivery is verified with photos submitted by drivers or with
                  the driver asking for your signature{" "}
                </strong>
                {<br />}
                To switch verification method, please contact hungerhub
              </span>
            </div>

            <div className="bg-light-gray px-[26px] d-row rounded-lg my-[8px]">
              <div key="photo" className="flex items-center">
                <input
                  name="notification-method"
                  type="radio"
                  checked={provider?.deliveryVerification === "photo"}
                  disabled
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />

                <label
                  htmlFor="photo"
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  Photo
                </label>
              </div>

              <div key="signature" className="flex items-center pl-[24px]">
                <input
                  name="notification-method"
                  type="radio"
                  checked={provider?.deliveryVerification === "signature"}
                  disabled
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <label
                  htmlFor="signature"
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                >
                  Signature
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DeliveryInfoDetail);
