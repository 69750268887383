import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ModalBackground from "components/shared/ModalBackground";
import classNames from "classnames";
import { successIcon, WarningImg } from "assets/img";
import { isPresent } from "utils/helpers/array";

function CsvResponseModal({
  visible,
  title,
  body,
  errors,
  isSuccess,
  dismissAlert,
  handleUploadAnotherFile,
}) {
  const errorCount = errors?.length;

  return (
    <div
      className={classNames({
        "fixed pin inset-x-0 px-4 pb-2 inset-0 flex items-center justify-center z-50":
          visible,
        hidden: !visible,
      })}
    >
      <ModalBackground />

      <div
        className="rounded-3xl overflow-y-auto shadow-md transform transition-all sm:w-full max-h-[640px]"
        role="dialog"
        aria-modal="true"
        style={{ width: "700px" }}
        aria-labelledby="modal-headline"
      >
        <div className="flex bg-white p-10 flex-col gap-2">
          <button
            onClick={() => {
              dismissAlert();
            }}
          >
            <FontAwesomeIcon
              icon={faTimes}
              color={"#ADB4C3"}
              size="xl"
              className="absolute top-6 right-6"
            />
          </button>

          <div className="flex justify-center">
            <img
              src={isSuccess ? successIcon : WarningImg}
              alt="warning-icon"
              className="w-[48px] h-[48px]"
            />
          </div>

          <div className="text-center text-[#2d3958] text-2xl font-inter-semibold leading-loose">
            {title}
          </div>

          <p className="font-inter-normal whitespace-pre-line text-base text-[#2d3958]">
            {body ?? ""}
          </p>

          {isPresent(errors) && (
            <div className="p-3 bg-[#f7f9ff] rounded-xl mt-2">
              <p className="text-[#d56c76] text-sm font-inter-normal mb-2 px-3">
                {errorCount} errors found
              </p>

              <div
                className={classNames(
                  "flex-col justify-center items-start inline-flex mt-2 grid grid-cols-1",
                  errors?.length >= 5
                    ? "max-h-[220px] overflow-y-auto scrollbar-custom"
                    : "max-h-[unset] overflow-visible"
                )}
              >
                {errors?.map((error, index) => (
                  <div
                    key={index}
                    className="text-[#d56c76] text-sm font-inter-medium py-0.5 px-3"
                  >
                    {error}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="w-full flex justify-center gap-4 mt-4">
            <button
              className="h-12 text-[#232777] text-sm font-inter-medium capitalize rounded-md border border-[#232777] px-2 w-1/2"
              onClick={() => {
                handleUploadAnotherFile();
              }}
            >
              Upload another file
            </button>

            <button
              className="btn-purple px-10 py-2 text-sm h-12 w-1/2"
              onClick={() => {
                dismissAlert();
              }}
            >
              {isSuccess ? "Done" : "Close"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CsvResponseModal;
