import React, { Fragment } from "react";
// import Screen from "utils/helpers/ScreenSize";
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import {
  cuisineFiltersForModal,
  dietaryFilters,
  featuredFilters,
  packagingFilters,
} from "utils/constants/filters";
import { getUpdatedFilterValue } from "utils/helpers/string";

function FiltersModal({
  visible,
  setModal,
  cuisineFilter,
  featuredFilter,
  dietaryFilter,
  packagingFilter,
  updateChosenRestaurantFilters,
  getMenus,
  clearAll,
}) {
  let cuisine = (
    <>
      <span className="text-lg md:text-xl text-primary-black font-inter-medium">
        Cuisine
      </span>

      <div className="flex flex-col sm:grid md:grid sm:grid-cols-2 md:grid-cols-2 md:gap-y-1 md:gap-x-5 mt-4">
        {cuisineFiltersForModal?.map((cuisine, index) => (
          <div key={cuisine + index}>
            <label className="inline-flex items-start py-1.5 text-sm select-none">
              <input
                autoComplete="off"
                type="checkbox"
                className="form-checkbox mt-[1px]"
                onClick={(e) => {
                  updateChosenRestaurantFilters(
                    "cuisine",
                    e.target.value,
                    true
                  );
                }}
                value={cuisine}
                checked={cuisineFilter?.includes(cuisine)}
              />
              <span className="ml-1 mt-[1.5px] font-inter-regular leading-4 text-sm">
                {cuisine}
              </span>
            </label>
          </div>
        ))}
      </div>
    </>
  );

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full md:w-[73%] transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between">
                  <Dialog.Title
                    as="h3"
                    className="font-inter-semibold leading-6 text-black-light"
                  >
                    Filters
                  </Dialog.Title>

                  <button
                    type="button"
                    onClick={() => setModal(false)}
                    className="btn-style"
                  >
                    <FontAwesomeIcon
                      style={{ width: 16, height: 26 }}
                      icon={faXmark}
                      size="2x"
                      color="#2D3958"
                    />
                  </button>
                </div>

                <div className="flex flex-col sm:flex-row md:flex-row flex-wrap mt-6">
                  <div className="flex flex-col flex-wrap w-full sm:w-6/12 md:w-3/12 py-3">
                    <span className="text-lg md:text-xl text-primary-black font-inter-medium">
                      Featured
                    </span>

                    <div className="flex flex-col sm:grid md:grid sm:grid-cols-1 md:grid-cols-1 mt-4">
                      {featuredFilters?.map((featured, index) => (
                        <div key={featured + index}>
                          <label className="inline-flex items-start py-1.5 text-sm select-none">
                            <input
                              autoComplete="off"
                              type="checkbox"
                              className="form-checkbox mt-[1px]"
                              onClick={(e) => {
                                updateChosenRestaurantFilters(
                                  "featured",
                                  e.target.value,
                                  true
                                );
                              }}
                              value={featured}
                              checked={featuredFilter?.includes(
                                getUpdatedFilterValue(featured)
                              )}
                            />
                            <span className="ml-1 font-inter-regular text-sm">
                              {featured}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex sm:hidden md:flex flex-col flex-wrap w-full sm:w-6/12 md:w-4/12 py-3">
                    {cuisine}
                  </div>

                  <div className="flex flex-col flex-wrap w-full sm:w-6/12 md:w-2/12 py-3">
                    <span className="text-lg md:text-xl text-primary-black font-inter-medium">
                      Dietary
                    </span>

                    <div className="flex flex-col sm:grid md:grid sm:grid-cols-1 md:grid-cols-1 mt-4">
                      {dietaryFilters?.map((dietary, index) => (
                        <div key={dietary + index}>
                          <label className="inline-flex items-start py-1.5 text-sm select-none">
                            <input
                              autoComplete="off"
                              type="checkbox"
                              className="form-checkbox mt-[1px]"
                              onClick={(e) =>
                                updateChosenRestaurantFilters(
                                  "dietary",
                                  e.target.value,
                                  true
                                )
                              }
                              value={dietary}
                              checked={dietaryFilter?.includes(
                                getUpdatedFilterValue(dietary)
                              )}
                            />
                            <span className="ml-1 font-inter-regular text-sm">
                              {dietary}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="hidden sm:flex md:hidden flex-col flex-wrap w-full sm:w-6/12 md:w-5/12 py-3">
                    {cuisine}
                  </div>

                  <div className="flex flex-col flex-wrap w-full sm:w-6/12 md:w-3/12 py-3">
                    <span className="text-lg md:text-xl text-primary-black font-inter-medium">
                      Packaging
                    </span>

                    <div className="flex flex-col sm:grid md:grid sm:grid-cols-1 md:grid-cols-1 mt-4 mb-16 sm:mb-0">
                      {packagingFilters?.map((packaging, index) => (
                        <div key={packaging + index + 1}>
                          <label className="inline-flex items-start py-1.5 text-sm select-none">
                            <input
                              autoComplete="off"
                              type="checkbox"
                              className="form-checkbox mt-[1px]"
                              onClick={(e) =>
                                updateChosenRestaurantFilters(
                                  "packaging",
                                  e.target.value,
                                  true
                                )
                              }
                              value={packaging}
                              checked={packagingFilter?.includes(
                                getUpdatedFilterValue(packaging)
                              )}
                            />
                            <span className="ml-1 font-inter-regular text-sm">
                              {packaging}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="hidden sm:flex justify-center items-center mt-16">
                  <div className="flex">
                    <button
                      className="btn-light py-3 px-5"
                      onClick={() => {
                        clearAll();
                        setModal(false);
                      }}
                    >
                      Clear all
                    </button>

                    <button
                      className="btn-purple py-3 px-6 ml-3"
                      onClick={() => setModal(false)}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        <Transition.Child
          as={"div"}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="sm:hidden">
            <div className="fixed bg-white w-full py-5 bottom-0 z-[90] justify-center items-center mt-16 border-t-2 border-gray-200">
              <div className="flex justify-center">
                <button
                  className="btn-light py-3 px-5"
                  onClick={() => {
                    clearAll();
                    setModal(false);
                  }}
                >
                  Clear all
                </button>

                <button
                  className="btn-purple py-3 px-6 ml-3"
                  onClick={() => setModal(false)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
}

export default observer(FiltersModal);
