import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useContext, useReducer } from "react";
import { CommentIcon, takeawayIcon } from "assets/img";
import dateTime from "utils/helpers/dateTime";
import EmbedHTML from "components/shared/embedHTML";
import { priceFormatter } from "utils/helpers/validation";
import { MobXProviderContext } from "mobx-react";
import { toJS } from "mobx";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

function ViewBag({ visible, setModal, bagId }) {
  const store = useContext(MobXProviderContext);
  const userStore = toJS(store?.userStore);
  const { locationID } = userStore;

  const initialState = {
    bag: null,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "reset":
        return initialState;
    }
    return {
      ...state,
      [action.field]: action.value,
    };
  }

  const [provider, dispatch] = useReducer(reducer, initialState);

  const { bag } = provider;

  const getTotalQuantity = (items) => {
    let quantity = 0;
    items?.forEach((item) => {
      quantity += item?.attributes?.quantity;
    });

    return quantity;
  };

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["shipmentDetail", locationID, bagId],
    refetchOnWindowFocus: false,
    queryFn: () => {
      return store.homeStore.fetchUncateringOrderDetail(bagId, locationID);
    },
  });

  useEffect(() => {
    dispatch({ field: "bag", value: data });
  }, [data]);

  const deleteOrder = () => {
    store.homeStore.deleteUncateringOrder(bag?.id, locationID).then(() => {
      setModal(false);
    });
  };

  const currentDate = dayjs();

  return (
    <Transition appear show={visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[90]"
        onClose={() => setModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-[696px] rounded-lg md:w-6/12 transform overflow-hidden sm:rounded-2xl md:rounded-2xl bg-white pb-8 pt-[24px] text-left align-middle shadow-xl transition-all">
                <div className="px-8">
                  <div className="d-row justify-between items-center">
                    <h2 className="text-2xl font-bold">
                      {bag?.attributes?.user_name}
                    </h2>
                    <button
                      onClick={() => setModal(false)}
                      className="text-dark-gray hover:text-yellow-600"
                      aria-label="Close"
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>

                  <div className="flex justify-between items-center my-4">
                    <h3 className="font-bold text-lg">
                      {bag?.attributes?.restaurant_name}
                    </h3>
                    <span className="d-row text-blue-800 text-xs px-2.5 py-0.5 rounded">
                      <img src={takeawayIcon} className="w-[20px] h-[20px]" />{" "}
                      <span className="font-normal text-slate-700 ml-1 text-base">
                        Bag #
                      </span>
                      <span className="font-semibold text-slate-700 ml-1 text-base">
                        {bag?.attributes?.bag_number}
                      </span>
                    </span>
                  </div>

                  <div className="grid grid-cols-4 gap-2 text-sm mb-4 bg-light-gray p-[12px]">
                    <div className="d-col border-r justify-center">
                      <small className="font-inter-regular text-[14px]">
                        Date
                      </small>
                      <small className="font-inter-medium text-[16px]">
                        {" "}
                        {dateTime.formatDate(
                          bag?.attributes?.order_date,
                          "ddd, MMMM Do, yyyy"
                        )}{" "}
                        {dateTime.checkAndGetTimezone(bag?.attributes?.tzinfo)}
                      </small>
                    </div>
                    <div className="d-col border-r justify-center">
                      <small className="font-inter-regular text-[14px]">
                        Meal{" "}
                      </small>
                      <small className="font-inter-medium text-[16px]">
                        {bag?.attributes?.meal_name}
                      </small>
                    </div>
                    <div className="d-col border-r justify-center">
                      <small className="font-inter-regular text-[14px]">
                        Cutlery{" "}
                      </small>
                      <small className="font-inter-medium text-[16px]">
                        {bag?.attributes?.cutlery ? "Yes" : "No"}
                      </small>
                    </div>
                    <div className="d-col justify-center">
                      <small className="font-inter-regular text-[14px]">
                        Status{" "}
                      </small>
                      <small className="font-inter-medium text-[16px]">
                        {bag?.attributes?.status == "pending"
                          ? "Placed"
                          : bag?.attributes?.status}
                      </small>
                    </div>
                  </div>

                  <div className="pt-4">
                    <div className="d-row justify-between bg-light-gray px-[24px] py-[16px]">
                      <div className="w-1/2">
                        <h4 className="font-bold">Item</h4>
                      </div>
                      <div className="w-1/2">
                        <h4 className="font-bold">Food total</h4>
                      </div>
                    </div>

                    {bag?.attributes?.order_items?.map(
                      ({ id, attributes }, index, arr) => (
                        <div className="d-row justify-between px-[24px]">
                          <div className="w-1/2 d-col">
                            <EmbedHTML
                              text={
                                attributes.menu_item?.display_name_with_html
                              }
                              className="font-inter-semibold text-[14px]"
                            />

                            {attributes?.order_item_options?.map(
                              ({ attributes }) => (
                                <div className="d-row mt-2">
                                  <EmbedHTML
                                    text={
                                      attributes?.option_group_display_name_with_html +
                                      ":"
                                    }
                                    className="font-inter-regular text-[14px] text-dark-gray"
                                  />

                                  <EmbedHTML
                                    text={
                                      attributes?.option_display_name_with_html
                                    }
                                    className="font-inter-regular ml-2 text-[14px] text-dark-gray"
                                  />
                                </div>
                              )
                            )}

                            {attributes?.special_instructions && (
                              <div className="d-row">
                                <img src={CommentIcon} className="h-4 w-4" />
                                <small>
                                  {attributes?.special_instructions}
                                </small>
                              </div>
                            )}

                            {index != arr?.length - 1 && (
                              <div className="bg-[#F7F9FF] w-full h-[0.5px] my-[12px]" />
                            )}
                          </div>

                          <div className="w-1/2 d-col">
                            <small className="font-inter-semibold text-[14px]">
                              {priceFormatter(attributes?.total_price)}
                            </small>

                            {attributes?.order_item_options?.map(
                              ({ attributes }) => (
                                <div className="d-col justify-center mt-2">
                                  <td>
                                    <small className="font-inter-regular text-sm flex text-dark-gray flex-col">
                                      +{priceFormatter(attributes?.price)}
                                    </small>
                                  </td>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>

                  <div className="rounded-3xl border border-slate-50 px-4 py-6 mb-4 mt-[16px]">
                    <div className="flex justify-between items-center pt-4 mt-4">
                      <div>Total items</div>
                      <div>
                        {getTotalQuantity(bag?.attributes?.order_items)}
                      </div>
                    </div>

                    <div className="flex justify-between items-center mt-2">
                      <div className="flex items-center bg-light-gray w-1/2 justify-between p-[12px] rounded-lg">
                        <div className="mr-2">Paid by user:</div>
                        <div className="font-bold">
                          {priceFormatter(
                            bag?.attributes?.amount_paid_by_employee
                          )}
                        </div>
                      </div>
                      <div className="flex items-center bg-light-gray w-1/2 justify-between ml-[32px] p-[12px] rounded-lg">
                        <div className="mr-2">Paid by Office:</div>
                        <div className="font-bold">
                          {priceFormatter(
                            bag?.attributes?.amount_paid_by_employer
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between items-center pt-4 mt-4 py-[24px]">
                      <div>
                        <div>Food Total</div>
                        <div>Tax</div>
                        <div className="font-bold">Total</div>
                      </div>
                      <div className="text-right">
                        <div>{priceFormatter(bag?.attributes?.subtotal)}</div>
                        <div>{priceFormatter(bag?.attributes?.hst)}</div>
                        <div className="font-bold">
                          {priceFormatter(bag?.attributes?.grand_total)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {bag?.attributes?.status == "pending" && (
                  <div className="px-8">
                    <button
                      className="btn-outline w-full bg-light-gray border-none"
                      onClick={() => {
                        deleteOrder();
                      }}
                    >
                      Delete Order
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default ViewBag;
